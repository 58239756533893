import React, { Component } from 'react';

import { Result } from 'antd';
import SecureComponent from '../app/components/SecureComponent';

class MaintenanceScreen extends Component {
  render() {
    return (
      <Result
        status='403'
        title='Down for Maintenance'
        subTitle='Sorry, HMS/NAV is down for maintenance.'
      />
    );
  }
}

export default SecureComponent(MaintenanceScreen);
