import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';

import AdminHoldTypes from './pages/AdminHoldTypes';
import AdminLocations from './pages/AdminLocations';
import AdminRoles from './pages/AdminRoles';
import AdminUsers from './pages/AdminUsers';
import AutoHoldRules from './pages/AutoHoldRules';
import Dashboard from './pages/Dashboard';
import ErrorPage from './pages/Error';
import ExpiringLotsReport from './pages/ExpiringLotsReport';
import FinList from './pages/FinList';
import FooterComponent from './app/components/Footer';
import HeaderComponent from './app/components/Header';
import InboundContainers from './pages/InboundContainers';
import InventoryDetail from './pages/InventoryDetail';
import InventoryHoldsRecon from './pages/InventoryHoldsRecon';
import InventoryList from './pages/InventoryList';
import InventoryOnHold from './pages/InventoryOnHold';
import InventoryQuantityMismatchRecon from './pages/InventoryQuantityMismatchRecon';
import { Layout } from 'antd';
import LoginScreen from './pages/Login';
import MaintenanceScreen from './pages/Maintenance';
import POActiveList from './pages/POActiveList';
import POHoldList from './pages/POHoldList';
import PoDetail from './pages/PoDetail';
import RedirectScreen from './pages/RedirectScreen';
import RouteConstants from './constants/RouteConstants';
import Search from './pages/Search';
import TOActiveList from './pages/TOActiveList';
import TempReleaseAccess from './pages/TempReleaseAccess';
import UsersPerHoldType from './pages/UsersPerHoldType';
import WatchlistInventory from './pages/WatchlistInventory';
import WatchlistPO from './pages/WatchlistPo';
import AutoHoldRulesForAll from './pages/AutoHoldRulesForAll';

const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTourOpen: true,
    };
  }

  render() {
    return (
      <Layout className='full-height'>
        <BrowserRouter>
          <HeaderComponent />
          <Content className='content'>
            <Switch>
              {/* Login */}
              <Route exact path={RouteConstants.LOGIN}>
                <LoginScreen />
              </Route>

              {/* Redirection to retrieve user details and navigate to requested page */}
              <Route exact path={RouteConstants.REDIRECT}>
                <RedirectScreen />
              </Route>

              {/* Watchlist Inventory and PO */}
              <Route exact path={RouteConstants.WATCHLIST_INVENTORY}>
                <WatchlistInventory />
              </Route>
              <Route exact path={RouteConstants.WATCHLIST_PO}>
                <WatchlistPO />
              </Route>

              {/* Inventory Active and Onhold */}
              <Route exact path={RouteConstants.ACTIVE_INVENTORY}>
                <InventoryList />
              </Route>
              <Route exact path={RouteConstants.ON_HOLD_INVENTORY}>
                <InventoryOnHold />
              </Route>

              {/* Purchase Orders Active and Onhold */}
              <Route exact path={RouteConstants.ACTIVE_PO}>
                <POActiveList />
              </Route>
              <Route exact path={RouteConstants.ONHOLD_PO}>
                <POHoldList />
              </Route>
              <Route exact path={RouteConstants.ACTIVE_TO}>
                <TOActiveList />
              </Route>

              {/* Search */}
              <Route exact path={RouteConstants.SEARCH_INVENTORY}>
                <Search />
              </Route>
              
              {/* Auto Holds For All  */}
              <Route exact path={RouteConstants.ADMIN_AUTO_HOLDS_FOR_ALL}>
                <AutoHoldRulesForAll />
              </Route>

              {/* Auto Holds  */}
              <Route exact path={RouteConstants.ADMIN_AUTO_HOLDS}>
                <AutoHoldRules />
              </Route>

              {/* Super Admin Accessible Tabs */}
              <Route exact path={RouteConstants.ADMIN_LOCATIONS}>
                <AdminLocations />
              </Route>
              <Route exact path={RouteConstants.ADMIN_HOLD_TYPES}>
                <AdminHoldTypes />
              </Route>
              <Route exact path={RouteConstants.ADMIN_ROLES}>
                <AdminRoles />
              </Route>
              <Route exact path={RouteConstants.ADMIN_USERS}>
                <AdminUsers />
              </Route>

              {/* FIN Management */}
              <Route exact path={RouteConstants.FIN_LIST}>
                <FinList />
              </Route>

              {/* Reports */}
              <Route exact path={RouteConstants.DASHBOARD}>
                <Dashboard />
              </Route>
              <Route exact path={RouteConstants.INBOUND_CONTAINERS}>
                <InboundContainers />
              </Route>
              <Route exact path={RouteConstants.EXPIRING_LOTS}>
                <ExpiringLotsReport />
              </Route>
              <Route exact path={RouteConstants.HOLD_MISMATCH_LOTS}>
                <InventoryHoldsRecon />
              </Route>
              <Route exact path={RouteConstants.QUANTITY_MISMATCH_LOTS}>
                <InventoryQuantityMismatchRecon />
              </Route>

              {/* Teams */}
              <Route exact path={RouteConstants.USERS_PER_HOLD}>
                <UsersPerHoldType />
              </Route>

              {/* Inventory Details */}
              <Route exact path={RouteConstants.INVENTORY_DETAILS}>
                <InventoryDetail />
              </Route>

              {/* PO Details */}
              <Route exact path={RouteConstants.PO_DETAILS}>
                <PoDetail />
              </Route>

              {/* Maintenance Screen */}
              <Route exact path={RouteConstants.MAINTENANCE_PAGE}>
                <MaintenanceScreen />
              </Route>

              {/* Approve/Reject Action for Temp Request */}
              <Route exact path={RouteConstants.TEMP_RELEASE_REQUEST}>
                <TempReleaseAccess />
              </Route>

              {/* Errors */}
              <Route path='*'>
                <ErrorPage />
              </Route>
            </Switch>
          </Content>
          <FooterComponent />
        </BrowserRouter>
      </Layout>
    );
  }
}

export default App;
