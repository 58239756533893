import {
  Button,
  Col,
  Descriptions,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  CheckCircleTwoTone,
  EditOutlined,
  EyeOutlined,
  MessageOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import React, { Component } from 'react';
import { findIndex, isEmpty, orderBy, upperCase } from 'lodash';
import { inject, observer } from 'mobx-react';

import FinAuditHistory from './finList/FinAuditHistory';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import RoleConstants from '../constants/RoleConstants';
import SecureComponent from '../app/components/SecureComponent';
import UpdateFinModal from './finList/UpdateFinModal';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import moment from 'moment';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class FinList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalLoading: false,
      modalVisible: false,
      auditModalVisible: false,
      fins: [],
      visibleFins: [],
      selectedFin: {},
      isSuperUser: false,
    };
  }

  componentDidMount() {
    this.fetchFinDetails();
    const roles = this.props.rootStore.userStore.roles;
    if (findIndex(roles, { name: RoleConstants.SUPER_USER }) > -1)
      this.setState({ isSuperUser: true });
    else this.setState({ isSuperUser: false });
  }

  fetchFinDetails = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_FIN_DETAILS.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            fins: response.data,
            visibleFins: response.data,
            loading: false,
          });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ fins: [], loading: false });
      });
  };

  refreshFINs = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.REFRESH_FINS.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            this.fetchFinDetails();
            message.success('FINs Refereshed');
          }, 4500);
        } else throw response;
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.warn('Cannot refresh FIN at the moment');
      });
  };

  getColumnsForTable = () => {
    const columns = [
      {
        key: 'fin',
        dataIndex: 'fin',
        title: 'FIN',
        sorter: (a, b) => a.fin.localeCompare(b.fin),
        defaultSortOrder: 'ascend',
        width: 140,
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
        ellipsis: {
          showTitle: false,
        },
        responsive: ['md'],
        render: (description) => (
          <Tooltip placement='topLeft' title={description}>
            {description}
          </Tooltip>
        ),
      },
      {
        key: 'blocked',
        dataIndex: 'blocked',
        title: 'Overall',
        render: (blocked) => {
          let color = 'green',
            status = 'active';
          if (blocked) {
            color = 'red';
            status = 'blocked';
          }
          return <Tag color={color}>{upperCase(status)}</Tag>;
        },
        width: 120,
        sorter: (a, b) =>
          a.blocked.toString().localeCompare(b.blocked.toString()),
      },
      {
        key: 'purchase_blocked',
        dataIndex: 'purchase_blocked',
        title: 'Purchase',
        render: (purchase_blocked) => {
          let color = 'green',
            status = 'active';
          if (purchase_blocked) {
            color = 'red';
            status = 'blocked';
          }
          return <Tag color={color}>{upperCase(status)}</Tag>;
        },
        width: 120,
        sorter: (a, b) =>
          a.purchase_blocked
            .toString()
            .localeCompare(b.purchase_blocked.toString()),
      },
      {
        key: 'sales_blocked',
        dataIndex: 'sales_blocked',
        title: 'Sales',
        render: (sales_blocked) => {
          let color = 'green',
            status = 'active';
          if (sales_blocked) {
            color = 'red';
            status = 'blocked';
          }
          return <Tag color={color}>{upperCase(status)}</Tag>;
        },
        width: 120,
        sorter: (a, b) =>
          a.sales_blocked.toString().localeCompare(b.sales_blocked.toString()),
      },
      {
        key: 'fin_audits',
        dataIndex: 'fin_audits',
        title: 'Last Updated By',
        render: (fin_audits) => {
          if (fin_audits.length > 0) {
            fin_audits = orderBy(fin_audits, ['createdAt'], 'desc');
            return (
              <Space>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={
                    'https://teams.microsoft.com/l/chat/0/0?users=' +
                    fin_audits[0].user.email
                  }
                >
                  {fin_audits[0].user.name}
                </a>
                {fin_audits[0].comment && (
                  <Tooltip title={fin_audits[0].comment}>
                    <MessageOutlined />
                  </Tooltip>
                )}
              </Space>
            );
          }
        },
        width: 180,
      },
      {
        key: 'fin_audits',
        dataIndex: 'fin_audits',
        title: 'Updated At',
        render: (fin_audits) => {
          if (fin_audits.length > 0) {
            fin_audits = orderBy(fin_audits, ['createdAt'], 'desc');
            return moment(fin_audits[0].createdAt).format('lll');
          }
        },
        width: 200,
      },
      {
        key: 'store_on_peco_pallet',
        dataIndex: 'store_on_peco_pallet',
        title: 'PECO Pallet',
        render: (store_on_peco_pallet) =>
          store_on_peco_pallet ? (
            <Tooltip placement='topLeft' title='Store on PECO Pallet'>
              <CheckCircleTwoTone
                twoToneColor='#52c41a'
                style={{ fontSize: 20, marginLeft: 15 }}
              />
            </Tooltip>
          ) : (
            ''
          ),
        width: 150,
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({
                    modalVisible: true,
                    selectedFin: data,
                  })
                }
              >
                <EditOutlined /> Update FIN
              </Button>
              {this.state.isSuperUser && (
                <Button
                  onClick={() => {
                    this.setState({
                      auditModalVisible: true,
                      selectedFin: data,
                    });
                  }}
                  disabled={data.fin_audits.length === 0}
                  title={
                    data.fin_audits.length === 0 ? 'No Audits' : 'View Audit'
                  }
                >
                  <EyeOutlined /> View Audit
                </Button>
              )}
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'fin',
        dataIndex: 'fin',
        title: 'FIN',
        sorter: (a, b) => a.fin.localeCompare(b.fin),
        defaultSortOrder: 'ascend',
        width: 140,
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({
                    modalVisible: true,
                    selectedFin: data,
                  })
                }
              >
                <EditOutlined /> Update
              </Button>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    let overall_color = 'green',
      overall_status = 'active',
      sales_color = 'green',
      sales_status = 'active',
      purchase_color = 'green',
      purchase_status = 'active';

    if (record.blocked) {
      overall_color = 'red';
      overall_status = 'blocked';
    }
    if (record.purchase_blocked) {
      purchase_color = 'red';
      purchase_status = 'blocked';
    }
    if (record.sales_blocked) {
      sales_color = 'red';
      sales_status = 'blocked';
    }

    return (
      <Descriptions bordered>
        <Descriptions.Item label='Description'>
          {record.description}
        </Descriptions.Item>
        <Descriptions.Item label='Overall'>
          <Tag color={overall_color}>{upperCase(overall_status)}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label='Purchase'>
          <Tag color={purchase_color}>{upperCase(purchase_status)}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label='Sales'>
          <Tag color={sales_color}>{upperCase(sales_status)}</Tag>
        </Descriptions.Item>
        {this.state.isSuperUser && (
          <Descriptions.Item>
            <Button
              onClick={() => {
                this.setState({
                  auditModalVisible: true,
                  selectedFin: record,
                });
              }}
              disabled={record.fin_audits.length === 0}
              title={
                record.fin_audits.length === 0 ? 'No Audits' : 'View Audit'
              }
            >
              <EyeOutlined /> View Audit
            </Button>
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };

  handleCancel = () => {
    this.setState({
      modalLoading: false,
      modalVisible: false,
      auditModalVisible: false,
      selectedFin: {},
    });
  };

  handleSubmit = (data) => {
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.UPDATE_FIN.USECASE,
      HttpConstants.PATCH_METHOD,
      data
    )
      .then((response) => {
        if (response.status === 200) {
          message.success('FIN Block updated successfully');
          this.setState({
            modalLoading: false,
            modalVisible: false,
            selectedFin: {},
          });
          this.fetchFinDetails();
        } else throw response;
      })
      .catch((err) => {
        let errorMessage = "Couldn't update FIN;";
        if (err.data && err.data.message) errorMessage = err.data.message;
        message.error(errorMessage);
        this.setState({ modalLoading: false });
      });
  };

  searchFins = (event) => {
    const value = event.target.value;
    if (isEmpty(value)) this.setState({ visibleFins: this.state.fins });
    else if (value.length <= 2) return;
    else {
      const { fins } = this.state;
      const visibleFins = [];
      fins.forEach((finObj) => {
        if (
          finObj.fin.search(value) > -1 ||
          finObj.description.search(value) > -1
        ) {
          visibleFins.push(finObj);
        }
      });
      this.setState({ visibleFins });
    }
  };

  render() {
    const {
      loading,
      visibleFins,
      modalVisible,
      auditModalVisible,
      modalLoading,
      selectedFin,
      isSuperUser,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - FIN List</title>
          <meta
            name='description'
            content='Manage FINs and their status in NAV'
          />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              All FINs
            </Typography.Text>
          </Col>
          {isSuperUser && (
            <Col
              xs={24}
              style={{ textAlign: 'right' }}
              className='space-top-10'
            >
              <Button onClick={this.refreshFINs} disabled={loading}>
                <SyncOutlined spin={loading} />
                Refresh FINs
              </Button>
            </Col>
          )}
          <Col xs={0} md={24} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={visibleFins}
              loading={loading}
              columns={this.getColumnsForTable()}
              locale={{
                emptyText: 'No FIN Available',
              }}
              rowKey={(record) => record.id}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} fins`,
              }}
              title={() => (
                <Space>
                  <Typography.Text>Search :</Typography.Text>
                  <Input
                    placeholder='FIN / Description'
                    allowClear={true}
                    onChange={this.searchFins}
                    addonAfter={<SearchOutlined />}
                    style={{ width: 250 }}
                  />
                </Space>
              )}
            />
          </Col>
          <Col xs={24} md={0} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={visibleFins}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No FIN Available',
              }}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} fins`,
              }}
            />
          </Col>
          <UpdateFinModal
            visible={modalVisible}
            loading={modalLoading}
            selectedFin={selectedFin}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
          <FinAuditHistory
            visible={auditModalVisible}
            selectedFin={selectedFin}
            onCancel={this.handleCancel}
          />
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(FinList), []);
