import StringConstants from '../constants/StringConstants';
import cookie from 'react-cookies';
import jwt from 'jsonwebtoken';

const isAuthenticatedUser = () => {
  const token = cookie.load(StringConstants.COOKIES.AUTH_TOKEN);
  if (typeof token !== typeof undefined) {
    const decoded = jwt.decode(token);
    var currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) return true;
  }
  return false;
};

const getUserDataFromCookie = () => {
  const token = cookie.load(StringConstants.COOKIES.AUTH_TOKEN);
  if (token) {
    const decoded = jwt.decode(token);
    return decoded;
  }
  return {};
};

export { isAuthenticatedUser, getUserDataFromCookie };
