import {
  Button,
  Col,
  Descriptions,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import React, { Component } from 'react';
import { filter, forEach, isEmpty, pick, uniq } from 'lodash';

import { DownloadOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import LotFilterCommponent from '../app/components/filter/LotFilter';
import NumberFormat from 'react-number-format';
import RouteConstants from '../constants/RouteConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { downloadExcelForQuantityMismatch } from '../utils/excelUtils';
import { getUiUrlWithPathParams } from '../api/UrlGenerator';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import moment from 'moment';
import { withRouter } from 'react-router';

class InventoryQuantityMismatchRecon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inventoryList: [],
      visibleInventoryList: [],
      date: moment(),
      inventoryLocations: [],
      itemNumbers: [],
      lotCodes: [],
      descriptions: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.QUANTITY_MISMATCH.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.setState({
              loading: false,
              inventoryList: response.data.data,
              visibleInventoryList: response.data.data,
              date: moment(response.data.report_date, 'YYYY-MM-DD'),
            });
            this.processDataForFilters(response.data.data);
          } else {
            this.setState({ loading: false });
          }
        } else throw response;
      })
      .catch(() => {
        message.error('Unable to fetch data at the moment');
        this.setState({
          loading: false,
          date: moment(),
          inventoryList: [],
          visibleInventoryList: [],
        });
      });
  }

  getColumnsForTable() {
    return [
      {
        key: 'po_number',
        dataIndex: 'po_number',
        title: 'PO #',
        sorter: (a, b) => a.po_number.localeCompare(b.po_number),
        fixed: 'left',
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: 'Location',
        sorter: (a, b) => a.location.localeCompare(b.location),
        fixed: 'left',
      },
      {
        key: 'fin',
        dataIndex: 'fin',
        title: 'FIN',
        sorter: (a, b) => a.fin.localeCompare(b.fin),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
        ellipsis: {
          showTitle: false,
        },
        responsive: ['md'],
        render: (description) => (
          <Tooltip placement='topLeft' title={description}>
            {description}
          </Tooltip>
        ),
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        key: 'lot_code',
        dataIndex: 'lot_code',
        title: 'Lot Code',
        sorter: (a, b) => a.lot_code.localeCompare(b.lot_code),
      },
      {
        key: 'hms_blocked',
        dataIndex: 'hms_blocked',
        title: 'HMS Status',
        sorter: (a, b) => a.hms_blocked.localeCompare(b.hms_blocked),
        render: (hms_blocked) =>
          hms_blocked ? (
            <Tag color='red'>BLOCKED</Tag>
          ) : (
            <Tag color='green'>ACTIVE</Tag>
          ),
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: 'HMS Quantity',
        sorter: (a, b) => a.quantity - b.quantity,
        render: (quantity) => (
          <NumberFormat
            value={quantity}
            displayType={'text'}
            thousandSeparator={true}
          />
        ),
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='dashed'
                onClick={() =>
                  this.navigateToInventoryDetail(data.inventory_id)
                }
              >
                View Details
              </Button>
            </Space>
          );
        },
      },
    ];
  }

  navigateToInventoryDetail = (id) => {
    const url = getUiUrlWithPathParams(RouteConstants.INVENTORY_DETAILS, {
      lotno: id,
    });
    this.props.history.push(url);
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'location',
        dataIndex: 'location',
        title: 'Location',
        sorter: (a, b) => a.location.localeCompare(b.location),
        fixed: 'left',
      },
      {
        key: 'fin',
        dataIndex: 'fin',
        title: 'FIN',
        sorter: (a, b) => a.fin.localeCompare(b.fin),
        defaultSortOrder: 'ascend',
        fixed: 'left',
      },
      {
        key: 'hms_blocked',
        dataIndex: 'hms_blocked',
        title: 'HMS Status',
        sorter: (a, b) => a.hms_blocked.localeCompare(b.hms_blocked),
        render: (hms_blocked) =>
          hms_blocked ? (
            <Tag color='red'>BLOCKED</Tag>
          ) : (
            <Tag color='green'>ACTIVE</Tag>
          ),
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: 'HMS Quantity',
        sorter: (a, b) => a.quantity - b.quantity,
        render: (quantity) => (
          <NumberFormat
            value={quantity}
            displayType={'text'}
            thousandSeparator={true}
          />
        ),
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <div className='text-center'>
        <Descriptions bordered>
          <Descriptions.Item label='PO #'>{record.po_number}</Descriptions.Item>
          <Descriptions.Item label='Location'>
            {record.location}
          </Descriptions.Item>
          <Descriptions.Item label='FIN'>{record.fins}</Descriptions.Item>
          <Descriptions.Item label='Description'>
            {record.description}
          </Descriptions.Item>
          <Descriptions.Item label='Lot Code'>
            {record.lot_code}
          </Descriptions.Item>
          <Descriptions.Item label='HMS Status'>
            {record.hms_blocked ? (
              <Tag color='red'>BLOCKED</Tag>
            ) : (
              <Tag color='green'>ACTIVE</Tag>
            )}
          </Descriptions.Item>
          <Descriptions.Item label='Quantity'>
            <NumberFormat
              value={record.quantity}
              displayType={'text'}
              thousandSeparator={true}
            />
          </Descriptions.Item>
        </Descriptions>
        <Button
          type='dashed'
          onClick={() => this.navigateToInventoryDetail(record.inventory_id)}
          style={{ marginTop: 5 }}
        >
          View Details
        </Button>
      </div>
    );
  };

  processDataForFilters = (inventoryList) => {
    let locations = inventoryList.map((i) => i.location);
    locations = uniq(locations);

    const itemNumbers = {};
    inventoryList.map((i) => {
      if (!itemNumbers[i.fin]) {
        itemNumbers[i.fin] = [];
      }
      return uniq(itemNumbers[i.fin].push(i.location));
    });

    let lotCodes = [],
      descriptions = {};
    inventoryList.forEach((i) => {
      lotCodes.push({ lotCode: i.lot_code, fin: i.fin, location: i.location });

      if (!descriptions[i.description]) descriptions[i.description] = [];
      uniq(
        descriptions[i.description].push({
          lotCode: i.lot_code,
          fin: i.fin,
          location: i.location,
        })
      );
    });

    lotCodes = uniq(lotCodes);

    this.setState({
      inventoryLocations: locations,
      itemNumbers,
      lotCodes,
      descriptions,
    });
  };

  applyFilter = (data) => {
    const { location, fin, lot_code, description } = data;
    const { inventoryList } = this.state;
    this.setState({ loading: true });
    let filter_ = {};
    if (!isEmpty(location)) filter_.location = location;
    if (!isEmpty(fin)) filter_.fin = fin;
    if (!isEmpty(lot_code)) filter_.lot_code = lot_code;
    if (!isEmpty(description)) filter_.description = description;
    const list = filter(inventoryList, filter_);

    this.processDataForFilters(list);
    this.setState({ visibleInventoryList: list, loading: false });
  };

  resetFilter = () => {
    const { inventoryList } = this.state;
    this.processDataForFilters(inventoryList);
    this.setState({ visibleInventoryList: inventoryList });
  };

  downloadData = () => {
    const { visibleInventoryList } = this.state;
    let data = [];
    forEach(visibleInventoryList, function (i) {
      data.push(
        pick(i, [
          'po_number',
          'location',
          'fin',
          'description',
          'lot_code',
          'hms_blocked',
          'quantity',
        ])
      );
    });

    downloadExcelForQuantityMismatch(data);
  };

  render() {
    const {
      loading,
      visibleInventoryList,
      inventoryLocations,
      itemNumbers,
      lotCodes,
      descriptions,
      date,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>Inventory Quantity Mismatch - HMS vs NAV</title>
          <meta name='description' content='List of active lots' />
        </Helmet>
        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Inventory Quantity Mismatch{' '}
              {date && `- ${moment(date).format('ll')}`}
            </Typography.Text>
          </Col>

          <Col xs={0} md={24} className='space-top'>
            <Row className='space-top' gutter={[8, 8]}>
              <Col xs={24} md={23}>
                <LotFilterCommponent
                  locations={inventoryLocations}
                  itemNumbers={itemNumbers}
                  lotCodes={lotCodes}
                  descriptions={descriptions}
                  onSubmit={this.applyFilter}
                  onReset={this.resetFilter}
                />
              </Col>
              <Col xs={0} sm={24} md={1} style={{ textAlign: 'end' }}>
                <Tooltip title='Export as Excel'>
                  <Button
                    shape='circle'
                    onClick={this.downloadData}
                    disabled={loading || isEmpty(visibleInventoryList)}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <Row className='space-top'>
              <Col xs={24}>
                <Table
                  scroll={{ x: 'fit-content' }}
                  dataSource={visibleInventoryList}
                  loading={loading}
                  columns={this.getColumnsForTable()}
                  locale={{
                    emptyText: 'No Mismatch Found',
                  }}
                  rowKey={(record) => record.inventory_id}
                  pagination={{
                    defaultPageSize: 50,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={0}>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={visibleInventoryList}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Mismatch Found',
              }}
              rowKey={(record) => record.inventory_id}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(InventoryQuantityMismatchRecon));
