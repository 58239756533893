import { observable } from 'mobx';

class HoldTypesUsersStore {

  @observable holdTypesUsers = [];

  setHoldTypesUsers(holdTypeUsers) {
    this.holdTypesUsers = holdTypeUsers;
  }
}

export default HoldTypesUsersStore;
