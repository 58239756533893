import React, { Component } from 'react';
/* eslint-disable array-callback-return */
import { isEmpty, sortBy } from 'lodash';

import { HorizontalBar } from 'react-chartjs-2';

class HoldsGraph extends Component {
  getGraphDataForHolds = (data) => {
    const labels = [],
      inventoryData = [],
      poData = [];
    sortBy(data, 'name').map((hold) => {
      labels.push(hold.name);
      if (isEmpty(hold.inventory)) inventoryData.push(0);
      else inventoryData.push(hold.inventory.count);
      if (isEmpty(hold.po)) poData.push(0);
      else poData.push(hold.po.count);
    });
    return {
      labels: labels,
      datasets: [
        {
          label: 'Number of lots',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: inventoryData,
        },
        {
          label: 'Number of PO',
          backgroundColor: 'rgba(25,99,132,0.2)',
          borderColor: 'rgba(25,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(25,99,132,0.4)',
          hoverBorderColor: 'rgba(25,99,132,1)',
          data: poData,
        },
      ],
    };
  };

  getGraphDataForLocation = (data) => {
    const labels = [],
      inventoryData = [];
    sortBy(data, 'hold_type').map((hold) => {
      labels.push(hold.hold_type);
      if (hold.count) inventoryData.push(hold.count);
      else inventoryData.push(0);
    });
    return {
      labels: labels,
      datasets: [
        {
          label: 'Number of lots',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: inventoryData,
        },
      ],
    };
  };

  getDisplayDataForGraph = (data) => {
    const { location = false } = this.props;

    if (location) return this.getGraphDataForLocation(data);
    return this.getGraphDataForHolds(data);
  };

  render() {
    const { data } = this.props;

    const displayData = this.getDisplayDataForGraph(data);
    return (
      <HorizontalBar
        data={displayData}
        options={{
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                  showLabelBackdrop: true,
                },
              },
            ],
          },
        }}
      />
    );
  }
}

export default HoldsGraph;
