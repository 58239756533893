import {
  Button,
  Col,
  Descriptions,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import React, { Component } from 'react';
import { forEach, sortBy, upperCase } from 'lodash';
import {
  getInventoryStatusColor,
  hashCode,
  intToRGB,
} from '../../utils/colorUtils';

import HoldUnholdModal from '../shared/HoldUnholdModal';
import HttpConstants from '../../constants/HttpConstants';
import NumberFormat from 'react-number-format';
import StringConstants from '../../constants/StringConstants';
import UrlConstants from '../../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../../api/ApiManager';
import moment from 'moment';

const expandedRowRender = ({ po_fins, status, po_holds }) => {
  const columns = [
    {
      title: 'FIN',
      dataIndex: 'fin',
      key: 'fin',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.fin.localeCompare(b.fin),
    },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity) => (
        <NumberFormat
          value={quantity}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
  ];

  return (
    <>
      {status === StringConstants.ONHOLD_STATUS && (
        <>
          <Typography.Text>Active Holds : </Typography.Text>
          {sortBy(po_holds, 'hold_type.name').map((hold_, index) => {
            if (hold_.hold_type && hold_.is_active) {
              return (
                <Tooltip title={hold_.message} key={index}>
                  <Tag
                    color={intToRGB(hashCode(hold_.hold_type.name))}
                    style={{ margin: 2 }}
                  >
                    {hold_.hold_type.name}
                  </Tag>
                </Tooltip>
              );
            } else return null;
          })}
        </>
      )}
      <Table
        columns={columns}
        dataSource={po_fins}
        pagination={false}
        rowKey={(record) => record.line_no}
      />
    </>
  );
};

class WatchedPoTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLoading: false,
      holdManagementModalVisible: false,
      inventory: {},
    };
  }

  handleViewHoldModalClick = (data) => {
    this.setState({ inventory: data, holdManagementModalVisible: true });
  };

  handlePutOnHoldCancel = () => {
    this.setState({
      modalLoading: false,
      holdManagementModalVisible: false,
      inventory: {},
    });
  };

  //This is used while updating just watch flag from modal
  handleUpdateWatchStatus = async (poObject, flag) => {
    const payload = {
      type: StringConstants.WATCHERS.TYPE.PO,
      action: !flag
        ? StringConstants.WATCHERS.ACTION.STOP_WATCHING
        : StringConstants.WATCHERS.ACTION.START_WATCHING,
      identifier: poObject.id,
    };
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.WATCHER_ACTIVITY.USECASE,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          message.success(response.data.message);
          this.setState({
            modalLoading: false,
            holdManagementModalVisible: false,
          });
          this.props.reloadData && this.props.reloadData();
        } else throw response;
      })
      .catch((err) => {
        let errMessage = 'Error occured while performing the action';
        if (err && err.data && err.data.message) errMessage = err.data.message;
        message.error(errMessage);
        this.setState({
          holdManagementModalVisible: false,
          modalLoading: false,
        });
      });
  };

  handlePutOnHoldSubmit = async (
    inventoryObj,
    addedHolds,
    removedHolds,
    comment,
    watch
  ) => {
    this.setState({ modalLoading: true });
    const params = {
      po_number: inventoryObj.po_number,
      id: inventoryObj.id,
      action: 'HOLD',
      holds: [],
      watch,
    };
    forEach(addedHolds, function (holdType) {
      const holdObj = {
        type: holdType,
        message: comment,
      };
      params.holds.push(holdObj);
    });

    const removeHoldParams = {
      po_number: inventoryObj.po_number,
      id: inventoryObj.id,
      action: 'UNHOLD',
      holds: [],
      watch,
    };
    forEach(removedHolds, function (holdType) {
      const holdObj = {
        type: holdType,
        message: comment,
      };
      removeHoldParams.holds.push(holdObj);
    });

    try {
      if (addedHolds.length > 0) {
        const response = await makeApiCallWithAuthentication(
          UrlConstants.ACTION_ON_PO.USECASE,
          HttpConstants.POST_METHOD,
          params
        );
        if (response.status === 200) {
          await this.updateRemovedHolds(removeHoldParams);
        } else {
          message.error('Could not add hold, aborting remove operation');
          this.setState({
            modalLoading: false,
          });
        }
      } else {
        await this.updateRemovedHolds(removeHoldParams);
      }
    } catch (err) {
      console.log('*******', err);
      message.error("Couldn't perform update, please try later");
      this.setState({
        modalLoading: false,
      });
    }
  };

  updateRemovedHolds = async (removeHoldParams) => {
    if (removeHoldParams.holds.length > 0) {
      const r_response = await makeApiCallWithAuthentication(
        UrlConstants.ACTION_ON_PO.USECASE,
        HttpConstants.POST_METHOD,
        removeHoldParams
      );
      if (r_response.status === 200) {
        message.success('Successfully updated PO !');
      } else {
        message.warn(
          'New holds added, removing existing holds failed. Try again'
        );
      }
    } else {
      message.success('Successfully updated PO !');
    }
    this.setState({
      modalLoading: false,
      holdManagementModalVisible: false,
    });

    this.props.reloadData && this.props.reloadData();
  };

  getColumnsForTable() {
    return [
      {
        key: 'po_number',
        dataIndex: 'po_number',
        title: 'PO Number',
        sorter: (a, b) => a.po_number.localeCompare(b.po_number),
        defaultSortOrder: 'ascend',
        width: 130,
        fixed: 'left',
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: 'Location',
        sorter: (a, b) => a.location.localeCompare(b.location),
        width: 130,
        fixed: 'left',
      },
      {
        key: 'po_fins',
        dataIndex: 'po_fins',
        title: 'FINs',
        sorter: (a, b) => a.po_fins.length - b.po_fins.length,
        render: (po_fins) => po_fins.length,
        width: 80,
      },
      {
        key: 'container_no',
        title: 'Container',
        sorter: (a, b) => a.container_no.localeCompare(b.container_no),
        render: ({ po_number, container_no }) => (
          <a
            href={`https://oceans.fishinco.fish/shipments/intransit/${po_number}/${container_no}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {container_no}
          </a>
        ),
        width: 150,
      },
      {
        key: 'buy_from_vendor_name',
        dataIndex: 'buy_from_vendor_name',
        title: 'Vendor',
        sorter: (a, b) =>
          a.buy_from_vendor_name.localeCompare(b.buy_from_vendor_name),
        ellipsis: {
          showTitle: false,
        },
        responsive: ['md'],
      },
      {
        key: 'expected_receipt_date',
        dataIndex: 'expected_receipt_date',
        title: 'Arrival Date',
        sorter: (a, b) =>
          moment(a.expected_receipt_date).diff(
            moment(b.expected_receipt_date),
            'hours'
          ),
        render: (expected_receipt_date) =>
          expected_receipt_date && moment(expected_receipt_date).format('ll'),
        width: 140,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        width: 120,
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (status) => {
          let color = 'red';
          if (status === StringConstants.ACTIVE_STATUS) {
            color = 'green';
          }
          return <Tag color={color}>{upperCase(status)}</Tag>;
        },
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          if (data.status === StringConstants.ACTIVE_STATUS)
            return (
              <Space>
                <Button
                  type='primary'
                  danger
                  onClick={() => this.handleViewHoldModalClick(data)}
                >
                  Put on hold
                </Button>
                <Button
                  type='dashed'
                  onClick={() => this.props.handleViewDetails(data.id)}
                >
                  View Details
                </Button>
              </Space>
            );
          else
            return (
              <Space>
                <Button
                  type='primary'
                  onClick={() => this.handleViewHoldModalClick(data)}
                >
                  Action on Item
                </Button>
                <Button
                  type='dashed'
                  onClick={() => this.props.handleViewDetails(data.id)}
                >
                  View Details
                </Button>
              </Space>
            );
        },
      },
    ];
  }

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'po_number',
        dataIndex: 'po_number',
        title: 'PO Number',
        sorter: (a, b) => a.po_number.localeCompare(b.po_number),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          if (data.status === StringConstants.ACTIVE_STATUS)
            return (
              <Space>
                <Button
                  type='primary'
                  danger
                  onClick={() => this.handleViewHoldModalClick(data)}
                >
                  Put on hold
                </Button>
              </Space>
            );
          else
            return (
              <Space>
                <Button
                  type='primary'
                  onClick={() => this.handleViewHoldModalClick(data)}
                >
                  Action on Item
                </Button>
              </Space>
            );
        },
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <div className='text-center'>
        <Descriptions bordered>
          <Descriptions.Item label='Location'>
            {record.location}
          </Descriptions.Item>
          <Descriptions.Item label='FINs'>
            {record.po_fins.length}
          </Descriptions.Item>

          <Descriptions.Item label='Container'>
            <a
              href={`https://oceans.fishinco.fish/shipments/intransit/${record.po_number}/${record.container_no}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {record.container_no}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label='Vendor'>
            {record.buy_from_vendor_name}
          </Descriptions.Item>
          <Descriptions.Item label='Arrival Date'>
            {record.expected_receipt_date &&
              moment(record.expected_receipt_date).format('ll')}
          </Descriptions.Item>
          <Descriptions.Item label='Status'>
            <Tag color={getInventoryStatusColor(record.status)}>
              {upperCase(record.status)}
            </Tag>
          </Descriptions.Item>
          {record.status === StringConstants.ONHOLD_STATUS && (
            <Descriptions.Item label='Active Holds'>
              {sortBy(record.po_holds, 'hold_type.name').map((hold_, index) => {
                if (hold_.hold_type && hold_.is_active) {
                  return (
                    <Tooltip title={hold_.message} key={index}>
                      <Tag
                        color={intToRGB(hashCode(hold_.hold_type.name))}
                        style={{ margin: 2 }}
                      >
                        {hold_.hold_type.name}
                      </Tag>
                    </Tooltip>
                  );
                } else return null;
              })}
            </Descriptions.Item>
          )}
        </Descriptions>
        <Button
          type='dashed'
          onClick={() => this.props.handleViewDetails(record.id)}
          style={{ marginTop: 5 }}
        >
          View Details
        </Button>
      </div>
    );
  };

  render() {
    const { inventory, holdManagementModalVisible, modalLoading } = this.state;
    const { data, loading, permissions, holdTypes } = this.props;

    return (
      <div>
        <Col xs={0} md={24}>
          <Table
            scroll={{ x: 'fit-content' }}
            dataSource={data}
            loading={loading}
            columns={this.getColumnsForTable()}
            locale={{
              emptyText: 'You are not watching any Purchase orders',
            }}
            rowKey={(record) => record.id}
            pagination={{
              defaultPageSize: 50,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            expandable={{ expandedRowRender }}
          />
        </Col>
        <Col xs={24} md={0}>
          <Table
            scroll={{ x: 'fit-content' }}
            dataSource={data}
            loading={loading}
            columns={this.getColumnsForTableSmallScreens()}
            locale={{
              emptyText: 'You are not watching any Purchase orders',
            }}
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: this.renderRowForSmallScreens,
            }}
            pagination={{
              defaultPageSize: 50,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Col>
        <HoldUnholdModal
          inventory={inventory}
          poModal={true}
          visible={holdManagementModalVisible}
          loading={modalLoading}
          onCancel={this.handlePutOnHoldCancel}
          onSubmit={this.handlePutOnHoldSubmit}
          onWatchUpdate={this.handleUpdateWatchStatus}
          holdTypes={holdTypes}
          permissions={permissions}
        />
      </div>
    );
  }
}

export default WatchedPoTable;
