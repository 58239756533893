export default {
  COOKIES: {
    AUTH_TOKEN: 'token',
    AUTH_REFRESH_TOKEN: 'refreshToken',
    EXPIRY_REPORT_SCREEN: 'expiryReportFilters',
  },
  ACTIVE_STATUS: 'ACTIVE',
  ONHOLD_STATUS: 'ON_HOLD',
  MOVED_STATUS: 'MOVED',
  UNAVAILABLE_STATUS: 'UNAVAILABLE',
  TEMP_ACTIVE_STATUS: 'TEMP_ACTIVE',

  SEARCH_BY_LOT: 'lot',
  SEARCH_BY_PO: 'po',
  SEARCH_BY_CONTAINER: 'container',
  SEARCH_BY_PO_REFERENCE: 'reference',

  LOCATION_PO_REPORTS: 'po_reporting',
  LOCATION_HOLDS_ACTIVITY: 'holds_activity',

  AUTO_HOLDS: {
    ATTRIBUTES: {
      COUNTRY: 'COUNTRY',
      FIN: 'FIN',
      LOCATION: 'LOCATION',
    },
    MATCH_STRINGS: {
      EQ: 'is equal to',
      NEQ: 'is not equal to',
      LIKE: 'starts with',
    },
    MATCH: {
      EQ: 'eq',
      NEQ: 'neq',
      LIKE: 'like',
    },
  },

  WATCHERS: {
    TYPE: {
      LOT: 'INVENTORY',
      PO: 'PURCHASE_ORDER',
    },
    ACTION: {
      START_WATCHING: 'ADD',
      STOP_WATCHING: 'REMOVE',
    },
  },
};
