import { Card, Col, Row, Typography } from 'antd';
import React, { Component } from 'react';

import StatisticsCard from './StatisticsCard';

class HoldwiseStatistics extends Component {
  render() {
    const { title, inventory, po } = this.props;
    return (
      <Card size='small' className='space-top-5'>
        <Row justify='space-around' gutter={[8, 0]}>
          <Col xs={24} sm={24} style={{ alignSelf: 'center', paddingLeft: 10 }}>
            <Typography.Title level={3} style={{ marginTop: '0.5em' }}>
              {title}
            </Typography.Title>
          </Col>
          <Col xs={24} sm={12}>
            <StatisticsCard
              title='Inventory'
              count={inventory.count}
              quantity={inventory.quantity}
            />
          </Col>
          <Col xs={24} sm={12}>
            <StatisticsCard
              title='Purchase Orders'
              count={po.count}
              quantity={po.quantity}
              poStats={true}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

export default HoldwiseStatistics;
