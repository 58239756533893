import { Input, Tag, Typography } from 'antd';
import React, { Component } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one';

export default class EmailTagInputs extends Component {
  state = {
    inputVisible: false,
    inputValue: '',
  };

  handleClose = (removedTag) => {
    const tags = this.props.emails.filter((tag) => tag !== removedTag);
    this.props.onUpdate(tags);
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    const { emails } = this.props;
    let tags = [...emails];
    if (inputValue && emails.indexOf(inputValue) === -1) {
      tags = [...emails, inputValue];
    }
    this.setState({
      inputValue: '',
    });
    this.props.onUpdate(tags);
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
        style={{ margin: 2 }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  render() {
    const { inputValue } = this.state;
    const { emails, disabled = false } = this.props;

    const tagChild = emails.map(this.forMap);
    return (
      <>
        <Input
          ref={this.saveInputRef}
          type='email'
          value={inputValue}
          onChange={this.handleInputChange}
          onPressEnter={this.handleInputConfirm}
          style={{ marginBottom: 5 }}
          disabled={disabled}
        />
        <Typography.Text type='danger'>
          <InfoCircleOutlined style={{ marginRight: 3 }} />
          Type an email address and press enter to add
        </Typography.Text>
        {!disabled && (
          <div className='space-top-10'>
            <TweenOneGroup
              enter={{
                scale: 0.8,
                opacity: 0,
                type: 'from',
                duration: 100,
                onComplete: (e) => {
                  e.target.style = '';
                },
              }}
              leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
              appear={false}
            >
              {tagChild}
            </TweenOneGroup>
          </div>
        )}
      </>
    );
  }
}
