import { find, orderBy } from 'lodash';

import Cookie from 'js-cookie';
import jwt from 'jsonwebtoken';
import moment from 'moment';

// const clientID = process.env.REACT_APP_AUTH0_CLIENTID;
// const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const domain = process.env.REACT_APP_AZURE_AD_DOMAIN;

async function getJWK() {
  // const res = await fetch('https://' + domain + '/.well-known/jwks.json');
  const res = await fetch(domain);
  const jwk = await res.json();
  return jwk;
}

function saveToken(jwtToken, accessToken) {
  Cookie.set('token', jwtToken);
}

function deleteToken() {
  Cookie.remove('token');
}
// async function verifyToken(token) {
//   if (token) {
//     const decodedToken = jwt.decode(token, { complete: true });
//     const jwk = await getJWK();
//     let cert = jwk.keys[0].x5c[0];
//     cert = cert.match(/.{1,64}/g).join('\n');
//     cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`;
//     if (jwk.keys[0].kid === decodedToken.header.kid) {
//       try {
//         jwt.verify(token, cert);
//         return true;
//       } catch (error) {
//         console.error(error);
//         return false;
//       }
//     }
//   }
// }
function getKIDMatchingKey(jwkKeys, decodedToken) {
  return find(jwkKeys, (key) => key.kid === decodedToken.header.kid);
}

async function verifyToken(token) {
  if (token) {
    const decodedToken = jwt.decode(token, { complete: true });
    const jwk = await getJWK();
    let kidMatchingKey = getKIDMatchingKey(jwk.keys, decodedToken);
    if (kidMatchingKey) {
      let cert = kidMatchingKey.x5c[0];
      cert = cert.match(/.{1,64}/g).join('\n');
      cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`;

      try {
        jwt.verify(token, cert);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  }
}

function isInternalHMSUser(user) {
  const HMSGroupId = 'c274bf6c-5914-478e-945f-14021d41d072';
  return (
    user !== undefined && user !== null && user.groups && user.groups.length > 0
  );
}

function getActiveSignedInAccount(accounts) {
  let unexpiredAccounts = accounts.filter((account) =>
    moment.unix(account.idTokenClaims.exp).isAfter(moment())
  );
  if (unexpiredAccounts.length === 0) return undefined;
  else if (unexpiredAccounts.length === 1) return unexpiredAccounts[0];
  else
    return orderBy(
      unexpiredAccounts,
      function (o) {
        return o.idTokenClaims.iat;
      },
      'desc'
    )[0];
}

async function getTokenForBrowser() {
  const token = Cookie.getJSON('token');
  const validToken = await verifyToken(token);
  if (validToken) {
    return jwt.decode(token);
  } else {
    return undefined;
  }
}

async function getTokenForServer(req) {
  if (req.headers.cookie) {
    const jwtFromCookie = req.headers.cookie
      .split(';')
      .find((c) => c.trim().startsWith('token='));
    if (!jwtFromCookie) {
      return undefined;
    }
    const token = jwtFromCookie.split('=')[1];
    const validToken = await verifyToken(token);
    if (validToken) {
      return jwt.decode(token);
    } else {
      return undefined;
    }
  }
}

export {
  saveToken,
  deleteToken,
  getTokenForBrowser,
  getTokenForServer,
  verifyToken,
  isInternalHMSUser,
  getActiveSignedInAccount,
};
