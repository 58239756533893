import { findIndex } from 'lodash';
import { observable } from 'mobx';

class UserStore {
  @observable name = null;
  @observable email = null;
  @observable roles = [];
  @observable permissions = [];

  setUserDetails(userObj) {
    this.name = userObj.name;
    this.email = userObj.email;
    this.roles = userObj.roles;
    this.permissions = userObj.permissions;
  }

  isUserPartOfRole = (roleName) => {
    if (findIndex(this.roles, { name: roleName }) > -1) return true;
    return false;
  };
}

export default UserStore;
