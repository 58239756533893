import {
  Button,
  Col,
  Descriptions,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  CheckCircleTwoTone,
  CloseSquareTwoTone,
  DownloadOutlined,
} from '@ant-design/icons';
import React, { Component } from 'react';
import { cloneDeep, filter, forEach, isEmpty, sortBy, trim } from 'lodash';
import { hashCode, intToRGB } from '../utils/colorUtils';

import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import NumberFormat from 'react-number-format';
import StringConstants from '../constants/StringConstants';
import UrlConstants from '../api/UrlConstants';
import { downloadExcelForInboundContainers } from '../utils/excelUtils';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import moment from 'moment';

class InboundContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      selectedLocation: null,
      poDetails: [],
      visiblePoDetails: [],
      holdTypes: [],
      columns: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_LOCATIONS.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            locations: response.data,
          });
          this.fetchPoDetailsForAllLocations();
        } else {
          throw response;
        }
      })
      .catch(() => {
        this.setState({ loading: false, locations: [] });
        message.error('Could not fetch locations, try again later');
      });
  }

  fetchPoDetailsForAllLocations = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_PO.USECASE,
      HttpConstants.GET_METHOD
    ).then((response) => {
      if (response.status === 200) {
        const visiblePoDetails = this.processPoDetails(response.data.po_list);
        this.setState(
          {
            poDetails: response.data.po_list,
            visiblePoDetails,
            holdTypes: response.data.hold_type_list,
          },
          () => {
            const columns = this.getColumnsForTable();
            this.setState({ columns, loading: false });
          }
        );
      } else {
        throw response;
      }
    });
  };

  processPoDetails = (poDetails) => {
    const visiblePoDetails = [];
    poDetails.forEach((po) => {
      if (
        po.status === StringConstants.ACTIVE_STATUS ||
        po.status === StringConstants.ONHOLD_STATUS
      ) {
        po.po_fins.forEach((fin) => {
          const obj = cloneDeep(po);
          delete obj.po_fins;
          obj.fin = fin.fin;
          obj.store_on_peco_pallet = fin.store_on_peco_pallet;
          obj.quantity = fin.quantity;
          obj.description = fin.description;
          obj.net_weight = fin.net_weight;
          obj.product_group_code = fin.product_group_code;
          visiblePoDetails.push(obj);
        });
      }
    });
    return visiblePoDetails;
  };

  resetFilter = () => {
    this.setState({
      selectedLocation: undefined,
      visiblePoDetails: this.state.poDetails,
    });
  };

  applyFilter = () => {
    const { selectedLocation, poDetails } = this.state;
    let visiblePoDetails = filter(poDetails, {
      location: selectedLocation,
    });
    visiblePoDetails = this.processPoDetails(visiblePoDetails);
    this.setState({ visiblePoDetails });
  };

  getColumnsForTable() {
    let columns = [
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Receive on hold',
        render: (status) =>
          status === StringConstants.ONHOLD_STATUS && (
            <CloseSquareTwoTone
              twoToneColor='red'
              style={{ fontSize: 20, marginLeft: 15 }}
            />
          ),
        width: 120,
        fixed: 'left',
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
      {
        key: 'expected_receipt_date',
        dataIndex: 'expected_receipt_date',
        title: 'ETA',
        sorter: (a, b) => {
          if (a.expected_receipt_date && b.expected_receipt_date)
            return moment(a.expected_receipt_date).diff(
              moment(b.expected_receipt_date),
              'hours'
            );
          else if (a.expected_receipt_date) return 1;
          else if (b.expected_receipt_date) return -1;
          else return 0;
        },
        render: (expected_receipt_date) =>
          expected_receipt_date && moment(expected_receipt_date).format('ll'),
        width: 140,
        fixed: 'left',
      },
      {
        key: 'container_no',
        title: 'Container',
        sorter: (a, b) => a.container_no.localeCompare(b.container_no),
        render: ({ po_number, container_no }) => (
          <a
            href={`https://oceans.fishinco.fish/shipments/intransit/${po_number}/${container_no}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {container_no}
          </a>
        ),
        width: 150,
        fixed: 'left',
      },
      {
        key: 'po_number',
        dataIndex: 'po_number',
        title: 'PO Number',
        sorter: (a, b) => a.po_number.localeCompare(b.po_number),
        defaultSortOrder: 'ascend',
        width: 130,
        fixed: 'left',
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: 'To',
        sorter: (a, b) => a.location.localeCompare(b.location),
        width: 130,
      },
      {
        key: 'fin',
        dataIndex: 'fin',
        title: 'FIN',
        sorter: (a, b) => a.fin.localeCompare(b.fin),
        width: 120,
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Item Description',
        ellipsis: {
          showTitle: false,
        },
        responsive: ['md'],
        render: (description) => (
          <Tooltip placement='topLeft' title={description}>
            {description}
          </Tooltip>
        ),
        width: 180,
      },
      {
        key: 'buy_from_vendor_name',
        dataIndex: 'buy_from_vendor_name',
        title: 'Supplier/Plant',
        sorter: (a, b) =>
          a.buy_from_vendor_name.localeCompare(b.buy_from_vendor_name),
        ellipsis: {
          showTitle: false,
        },
        responsive: ['md'],
        render: (buy_from_vendor_name) => (
          <Tooltip placement='topLeft' title={buy_from_vendor_name}>
            {buy_from_vendor_name}
          </Tooltip>
        ),
        width: 180,
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: 'Quantity',
        width: 120,
        render: (quantity) => (
          <NumberFormat
            value={quantity}
            displayType={'text'}
            thousandSeparator={true}
          />
        ),
        sorter: (a, b) => a.quantity - b.quantity,
      },
      {
        key: 'product_group_code',
        dataIndex: 'product_group_code',
        title: 'Type',
        width: 120,
        sorter: (a, b) => a.product_group_code - b.product_group_code,
      },
      {
        key: 'buy_from_country_code',
        dataIndex: 'buy_from_country_code',
        title: 'From Country',
        width: 120,
        sorter: (a, b) => a.buy_from_country_code - b.buy_from_country_code,
      },
      {
        key: 'net_weight',
        dataIndex: 'net_weight',
        title: 'LBs to Rcv',
        width: 120,
        sorter: (a, b) => a.net_weight - b.net_weight,
      },
      {
        key: 'store_on_peco_pallet',
        dataIndex: 'store_on_peco_pallet',
        title: 'PECO Pallet',
        render: (store_on_peco_pallet) =>
          store_on_peco_pallet ? (
            <Tooltip placement='topLeft' title='Store on PECO Pallet'>
              <CheckCircleTwoTone
                twoToneColor='#52c41a'
                style={{ fontSize: 20, marginLeft: 15 }}
              />
            </Tooltip>
          ) : (
            ''
          ),
        width: 120,
      },
    ];

    columns = [].concat(columns, this.getColumnsForHoldTypes());
    return columns;
  }

  getColumnsForHoldTypes = () => {
    const { holdTypes } = this.state;
    let holdColumns = [];
    const sortedHoldTypes = holdTypes.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    forEach(sortedHoldTypes, function (hold) {
      const obj_ = {
        key: 'id',
        title: hold.name,
        ellipsis: {
          showTitle: false,
        },
        render: (data) => {
          return data.po_holds.map((hold_, index) => {
            if (
              hold_.hold_type &&
              hold_.hold_type.name === hold.name &&
              hold_.is_active
            ) {
              return (
                <Tooltip title={hold_.message} key={index}>
                  <CloseSquareTwoTone
                    twoToneColor='red'
                    style={{ fontSize: 20, marginLeft: 15 }}
                  />
                </Tooltip>
              );
            }
            return null;
          });
        },
      };
      holdColumns.push(obj_);
    });
    return holdColumns;
  };

  downloadData = () => {
    const { visiblePoDetails, holdTypes, selectedLocation } = this.state;
    if (visiblePoDetails.length > 0) {
      const holds = holdTypes.map((m) => m.name);
      downloadExcelForInboundContainers(
        visiblePoDetails,
        holds,
        selectedLocation
      );
    } else {
      message.warn('No Purchase Orders Available to download');
    }
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Receive on hold',
        render: (status) =>
          status === StringConstants.ONHOLD_STATUS && (
            <CloseSquareTwoTone
              twoToneColor='red'
              style={{ fontSize: 20, marginLeft: 15 }}
            />
          ),
        width: 120,
        fixed: 'left',
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
      {
        key: 'po_number',
        dataIndex: 'po_number',
        title: 'PO Number',
        sorter: (a, b) => a.po_number.localeCompare(b.po_number),
        defaultSortOrder: 'ascend',
        width: 140,
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <Descriptions bordered>
        <Descriptions.Item label='ETA'>
          {record.expected_receipt_date &&
            moment(record.expected_receipt_date).format('ll')}
        </Descriptions.Item>
        <Descriptions.Item label='Container'>
          <a
            href={`https://oceans.fishinco.fish/shipments/intransit/${record.po_number}/${record.container_no}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {record.container_no}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label='To'>{record.location}</Descriptions.Item>
        <Descriptions.Item label='FIN'>{record.fin}</Descriptions.Item>
        <Descriptions.Item label='Item Description'>
          {record.description}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier/Plant'>
          {record.buy_from_vendor_name}
        </Descriptions.Item>
        <Descriptions.Item label='Quantity'>
          <NumberFormat
            value={record.quantity}
            displayType={'text'}
            thousandSeparator={true}
          />
        </Descriptions.Item>
        <Descriptions.Item label='Type'>
          {record.product_group_code}
        </Descriptions.Item>
        <Descriptions.Item label='From Country'>
          {record.buy_from_country_code}
        </Descriptions.Item>
        <Descriptions.Item label='LBs to Rcv'>
          {record.net_weight}
        </Descriptions.Item>
        <Descriptions.Item label='PECO Pallet'>
          {record.store_on_peco_pallet ? <CheckCircleTwoTone /> : ''}
        </Descriptions.Item>
        {record.po_holds && (
          <Descriptions.Item label='Active Holds'>
            {sortBy(record.po_holds, 'name').map((hold, index) => {
              if (hold.is_active)
                return (
                  <Tag
                    color={intToRGB(hashCode(hold.hold_type.name))}
                    key={index}
                  >
                    {hold.hold_type.name}
                  </Tag>
                );
              return null;
            })}
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };

  render() {
    const {
      loading,
      locations,
      selectedLocation,
      visiblePoDetails,
      columns,
      holdTypes,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Inbound Containers Report</title>
          <meta
            name='description'
            content='Report of inbound containers per warehouse'
          />
        </Helmet>

        <Row gutter={[16, 24]}>
          <Col xs={24} className='text-center space-top-10'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Warehouse Inbound Containers
            </Typography.Text>
          </Col>
          <Col xs={24}>
            <Row>
              <Col xs={0} sm={24} md={22}>
                <Space>
                  <Select
                    disabled={loading}
                    showSearch
                    value={selectedLocation}
                    style={{ width: 200 }}
                    placeholder='Select a Location'
                    onChange={(value) =>
                      this.setState({ selectedLocation: value })
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(trim(input.toLowerCase())) >= 0
                    }
                  >
                    {sortBy(locations, 'code').map((location, index) => {
                      return (
                        <Select.Option value={location.code} key={index}>
                          {location.code}
                        </Select.Option>
                      );
                    })}
                  </Select>

                  <Button
                    type='primary'
                    disabled={loading || isEmpty(selectedLocation)}
                    onClick={this.applyFilter}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    type='link'
                    danger
                    disabled={loading || isEmpty(selectedLocation)}
                    onClick={this.resetFilter}
                  >
                    Reset
                  </Button>
                </Space>
              </Col>
              <Col xs={0} sm={24} md={2} style={{ textAlign: 'end' }}>
                <Tooltip title='Export as Excel'>
                  <Button
                    shape='circle'
                    onClick={this.downloadData}
                    disabled={loading || isEmpty(visiblePoDetails)}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={24}>
            <Table
              scroll={{ x: 1640 + holdTypes.length * 120 }}
              dataSource={visiblePoDetails}
              loading={loading}
              columns={columns}
              locale={{
                emptyText: 'No Purcase Orders Available',
              }}
              rowKey={(record) => record.id + record.fin}
              pagination={{
                defaultPageSize: 20,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Col>
          <Col xs={24} md={0}>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={visiblePoDetails}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Purcase Orders Available',
              }}
              rowKey={(record) => record.id + record.fin}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InboundContainers;
