import { Button, Divider, Form, Select, Tooltip } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { Component } from 'react';

import StringConstants from '../../../constants/StringConstants';
import { trim } from 'lodash';

class DynamicRuleInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryInputVisible: false,
    };
  }

  resetSecondaryInputs = () => {
    this.setState({ isSecondaryInputVisible: false });
    this.props.autoHoldForm.resetFields([
      'secondary_attribute',
      'secondary_match',
      'secondary_value',
    ]);
    this.props.refresh();
  };

  render() {
    const { isSecondaryInputVisible } = this.state;
    const props = this.props;
    return (
      <div>
        <Divider />
        {isSecondaryInputVisible && (
          <Tooltip title='Remove this condition'>
            <Button
              type='danger'
              shape='circle'
              className='dynamic-delete-button'
              onClick={this.resetSecondaryInputs}
              icon={<MinusCircleOutlined />}
            ></Button>
          </Tooltip>
        )}
        {isSecondaryInputVisible && (
          <div>
            <Form.Item
              name='secondary_attribute'
              rules={[
                {
                  required: true,
                  message: 'Please choose a field',
                },
              ]}
            >
              <Select
                placeholder='Attribute'
                onChange={() => {
                  props.autoHoldForm.resetFields([
                    'secondary_match',
                    'secondary_value',
                  ]);
                  props.refresh();
                }}
              >
                <Select.Option
                  value={StringConstants.AUTO_HOLDS.ATTRIBUTES.FIN}
                  disabled={
                    props.autoHoldForm.getFieldValue('attribute') ===
                    StringConstants.AUTO_HOLDS.ATTRIBUTES.FIN
                  }
                >
                  FIN
                </Select.Option>
                <Select.Option
                  value={StringConstants.AUTO_HOLDS.ATTRIBUTES.COUNTRY}
                  disabled={
                    props.autoHoldForm.getFieldValue('attribute') ===
                    StringConstants.AUTO_HOLDS.ATTRIBUTES.COUNTRY
                  }
                >
                  Country
                </Select.Option>
                <Select.Option
                  value={StringConstants.AUTO_HOLDS.ATTRIBUTES.LOCATION}
                  disabled={
                    props.autoHoldForm.getFieldValue('attribute') ===
                    StringConstants.AUTO_HOLDS.ATTRIBUTES.LOCATION
                  }
                >
                  Location
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='secondary_match'
              rules={[
                {
                  required: true,
                  message: 'Please select a match type',
                },
              ]}
            >
              <Select
                onChange={() => {
                  props.autoHoldForm.resetFields(['secondary_value']);
                  props.refresh();
                }}
                placeholder='Match Rule'
              >
                <Select.Option value={StringConstants.AUTO_HOLDS.MATCH.EQ}>
                  is equal to
                </Select.Option>

                {props.autoHoldForm &&
                props.autoHoldForm.getFieldValue('secondary_attribute') !==
                  StringConstants.AUTO_HOLDS.ATTRIBUTES.FIN ? (
                  <Select.Option value={StringConstants.AUTO_HOLDS.MATCH.NEQ}>
                    not equal to
                  </Select.Option>
                ) : (
                  <Select.Option value={StringConstants.AUTO_HOLDS.MATCH.LIKE}>
                    starts with
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name='secondary_value'
              rules={[
                {
                  required: true,
                  message: 'Please select a value',
                },
              ]}
            >
              <Select
                onChange={() => props.refresh()}
                placeholder='Value'
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(trim(input.toLowerCase())) >= 0
                }
              >
                {props.renderSecondaryMatchValueDropdown(true)}
              </Select>
            </Form.Item>
            <Divider />
          </div>
        )}
        {!isSecondaryInputVisible && (
          <Form.Item className='justify-center text-center'>
            <Button
              type='dashed'
              onClick={() => {
                props.refresh();
                this.setState({ isSecondaryInputVisible: true });
              }}
            >
              <PlusOutlined /> Add additional condition
            </Button>
          </Form.Item>
        )}
      </div>
    );
  }
}

export default DynamicRuleInput;
