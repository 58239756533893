import { Col, Row, Typography, message } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import LoadingComponent from '../app/components/Loading';
import RouteConstants from '../constants/RouteConstants';
import SecureComponent from '../app/components/SecureComponent';
import StringConstants from '../constants/StringConstants';
import UrlConstants from '../api/UrlConstants';
import WatchedInventoryTable from './watchlistInventory/WatchedInventoryTable';
import { getUiUrlWithPathParams } from '../api/UrlGenerator';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { reject } from 'lodash';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class WatchlistInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inventoryList: [],
      holdTypeList: [],
    };
  }

  componentDidMount = () => {
    this.fetchInventoryWatchlist();
  };

  fetchInventoryWatchlist = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_WATCHED_INVENTORY.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            inventoryList: reject(response.data.inventory_list, {
              status: StringConstants.MOVED_STATUS,
            }),
            holdTypeList: response.data.hold_type_list,
          });
        } else throw response;
      })
      .catch((err) => {
        console.log('*****', err);
        this.setState({ loading: false });
        message.error("Couldn't get inventory, please try later");
      });
  };

  navigateToInventoryDetail = (id) => {
    const url = getUiUrlWithPathParams(RouteConstants.INVENTORY_DETAILS, {
      lotno: id,
    });
    this.props.history.push(url);
  };

  render() {
    const { loading, inventoryList, holdTypeList } = this.state;

    if (loading) return <LoadingComponent tip='Fetching watched inventory' />;
    return (
      <div>
        <Helmet>
          <title>HMS - Watched Inventory</title>
          <meta name='description' content='List of lots being watched' />
        </Helmet>
        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Inventory Watchlist
            </Typography.Text>
          </Col>
          <Col xs={24}>
            <Row className='space-top'>
              <Col xs={24}>
                <WatchedInventoryTable
                  data={inventoryList}
                  handleViewDetails={this.navigateToInventoryDetail}
                  loading={loading}
                  permissions={this.props.rootStore.userStore.permissions}
                  reloadData={this.fetchInventoryWatchlist}
                  holdTypes={holdTypeList}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(WatchlistInventory), []);
