import { DownOutlined, SettingOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import RoleConstants from '../../../constants/RoleConstants';
import RouteConstants from '../../../constants/RouteConstants';
import SubMenu from 'antd/lib/menu/SubMenu';
import { findIndex } from 'lodash';

@inject('rootStore')
@observer
class LeftMenu extends Component {
  render() {
    const { mobileMenu = false, current } = this.props;
    const roles = this.props.rootStore.userStore.roles;
    let isAdmin = false;
    if (findIndex(roles, { name: RoleConstants.SUPER_USER }) !== -1)
      isAdmin = true;

    return (
      <Menu
        mode={mobileMenu ? 'inline' : 'horizontal'}
        selectedKeys={[current]}
      >
        <SubMenu
          title={
            <>
              Watchlist{' '}
              <DownOutlined
                style={{ marginRight: 0, marginLeft: 3, fontSize: 10 }}
              />
            </>
          }
          key='watchlist'
        >
          <Menu.Item key='watchlist-inventory'>
            <Link to={RouteConstants.WATCHLIST_INVENTORY}>Inventory</Link>
          </Menu.Item>
          <Menu.Item key='watchlist-po'>
            <Link to={RouteConstants.WATCHLIST_PO}>Purchase Orders</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          title={
            <>
              Inventory
              <DownOutlined
                style={{ marginRight: 0, marginLeft: 3, fontSize: 10 }}
              />
            </>
          }
          key='inv'
        >
          <Menu.Item key='inventory'>
            <Link to={RouteConstants.ACTIVE_INVENTORY}>Active Lots</Link>
          </Menu.Item>

          <Menu.Item key='holds'>
            <Link to={RouteConstants.ON_HOLD_INVENTORY}>Onhold Lots</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          title={
            <>
              Purchase Orders{' '}
              <DownOutlined
                style={{ marginRight: 0, marginLeft: 3, fontSize: 10 }}
              />
            </>
          }
          key='purchase-orders'
        >
          <Menu.Item key='purchase-orders-active'>
            <Link to={RouteConstants.ACTIVE_PO}>Active POs</Link>
          </Menu.Item>
          <Menu.Item key='purchase-orders-holds'>
            <Link to={RouteConstants.ONHOLD_PO}>Onhold POs</Link>
          </Menu.Item>
          <Menu.Item key='transfer-orders-active'>
            <Link to={RouteConstants.ACTIVE_TO}>Active Transfers</Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key='search'>
          <Link to={RouteConstants.SEARCH_INVENTORY}>Search</Link>
        </Menu.Item>
        
        <Menu.Item key='auto-holds'>
          <Link to={RouteConstants.ADMIN_AUTO_HOLDS_FOR_ALL}>Auto Holds</Link>
        </Menu.Item>

        {isAdmin && (
          <SubMenu icon={<SettingOutlined />} title='Admin' key='admin'>
            <Menu.Item key='admin-auto-holds'>
              <Link to={RouteConstants.ADMIN_AUTO_HOLDS}>Auto Holds</Link>
            </Menu.Item>
            <Menu.Item key='admin-holdtypes'>
              <Link to={RouteConstants.ADMIN_HOLD_TYPES}>Hold Types</Link>
            </Menu.Item>
            <Menu.Item key='admin-locations'>
              <Link to={RouteConstants.ADMIN_LOCATIONS}>Locations</Link>
            </Menu.Item>
            <Menu.Item key='admin-roles'>
              <Link to={RouteConstants.ADMIN_ROLES}>Roles</Link>
            </Menu.Item>
            <Menu.Item key='admin-users'>
              <Link to={RouteConstants.ADMIN_USERS}>Users</Link>
            </Menu.Item>
          </SubMenu>
        )}

        {!isAdmin &&
          findIndex(roles, { name: RoleConstants.AUTO_HOLDS_MANAGER }) > -1 && (
            <Menu.Item key='admin-auto-holds'>
              <Link to={RouteConstants.ADMIN_AUTO_HOLDS}>Auto Holds</Link>
            </Menu.Item>
          )}
      </Menu>
    );
  }
}

export default LeftMenu;
