import { Button, Col, Row, Tooltip, Typography, message } from 'antd';
import React, { Component } from 'react';
import {
  filter,
  findIndex,
  forEach,
  isEmpty,
  isUndefined,
  pick,
  uniq,
} from 'lodash';
import { inject, observer } from 'mobx-react';

import { DownloadOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import POFilterCommponent from '../app/components/filter/POFilter';
import POTable from './shared/poScreens/POTable';
import RouteConstants from '../constants/RouteConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { downloadExcelForPO } from '../utils/excelUtils';
import { getUiUrlWithPathParams } from '../api/UrlGenerator';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class POHoldList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      inventoryList: [],
      visibleInventorylist: [],
      holdTypeList: [],
      locations: [],
      pos: {},
      fins: {},
      vendors: {},
      containers: {},
    };
  }

  componentDidMount = () => {
    this.fetchOnholdPOs();
  };

  fetchOnholdPOs = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_PO_ONHOLD.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            inventoryList: response.data.po_list,
            visibleInventorylist: response.data.po_list,
            holdTypeList: response.data.hold_type_list,
          });
          this.processDataForFilters(response.data.po_list);
        } else throw response;
      })
      .catch((err) => {
        console.log('*****', err);
        this.setState({ loading: false });
        message.error("Couldn't get purchase orders, please try later");
      });
  };

  processDataForFilters = (inventoryList) => {
    let locations = inventoryList.map((i) => i.location);
    locations = uniq(locations);

    const pos = {},
      fins = {},
      vendors = {},
      containers = {};

    inventoryList.forEach((i) => {
      if (!pos[i.po_number]) {
        pos[i.po_number] = [];
      }
      uniq(pos[i.po_number].push(i.location));

      i.po_fins.forEach((f) => {
        //Fin Filter
        if (!fins[f.fin]) fins[f.fin] = [];
        uniq(
          fins[f.fin].push({
            location: i.location,
            po_number: i.po_number,
          })
        );

        //Vendor Filter
        if (!vendors[i.buy_from_vendor_name])
          vendors[i.buy_from_vendor_name] = [];
        uniq(
          vendors[i.buy_from_vendor_name].push({
            location: i.location,
            po_number: i.po_number,
            fin: f.fin,
          })
        );

        //Container Filter
        if (!containers[i.container_no]) containers[i.container_no] = [];
        uniq(
          containers[i.container_no].push({
            location: i.location,
            po_number: i.po_number,
            fin: f.fin,
            vendor: i.buy_from_vendor_name,
          })
        );
      });
    });

    this.setState({
      locations,
      pos,
      fins,
      vendors,
      containers,
    });
  };

  applyFilter = (data) => {
    const { location, po, fin, vendor, container, holdType } = data;
    const { inventoryList } = this.state;

    this.setState({ loading: true });
    let filter_ = {};
    if (!isEmpty(location)) filter_.location = location;
    if (!isEmpty(po)) filter_.po_number = po;
    if (!isEmpty(vendor)) filter_.buy_from_vendor_name = vendor;
    if (!isEmpty(container)) filter_.container_no = container;

    let list = filter(inventoryList, filter_);

    if (!isEmpty(fin)) {
      const templist = [];
      forEach(list, function (l) {
        findIndex(l.po_fins, {
          fin: fin,
        }) > -1 && templist.push(l);
      });
      list = templist;
    }

    if (!isUndefined(holdType)) {
      const templist = [];
      forEach(list, function (l) {
        findIndex(l.po_holds, {
          hold_type_id: holdType,
          is_active: true,
        }) > -1 && templist.push(l);
      });
      list = templist;
    }

    this.processDataForFilters(list);
    this.setState({ visibleInventorylist: list, loading: false });
  };

  resetFilter = () => {
    const { inventoryList } = this.state;
    this.processDataForFilters(inventoryList);
    this.setState({ visibleInventorylist: inventoryList });
  };

  downloadData = () => {
    const { visibleInventorylist, holdTypeList } = this.state;
    let data = [];
    forEach(visibleInventorylist, function (i) {
      const obj = pick(i, [
        'po_number',
        'po_fins',
        'location',
        'container_no',
        'buy_from_vendor_name',
        'buy_from_country_code',
        'expected_receipt_date',
        'po_holds',
      ]);
      data.push(obj);
    });
    const holds = holdTypeList.map((m) => m.name);
    downloadExcelForPO(data, false, holds);
  };

  navigateToPurchaseOrderDetail = (id) => {
    const url = getUiUrlWithPathParams(RouteConstants.PO_DETAILS, {
      id: id,
    });
    this.props.history.push(url);
  };

  render() {
    const {
      loading,
      visibleInventorylist,
      holdTypeList,
      locations,
      pos,
      fins,
      vendors,
      containers,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Purchase Orders Hold List</title>
          <meta name='description' content='List of onhold purchase orders' />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Onhold Purchase Orders
            </Typography.Text>
          </Col>
          <Col xs={24}>
            <Row className='space-top' gutter={[8, 8]}>
              <Col xs={24} md={23}>
                <POFilterCommponent
                  locations={locations}
                  pos={pos}
                  fins={fins}
                  vendors={vendors}
                  containers={containers}
                  holdTypes={holdTypeList}
                  onSubmit={this.applyFilter}
                  onReset={this.resetFilter}
                />
              </Col>
              <Col xs={0} sm={24} md={1} style={{ textAlign: 'end' }}>
                <Tooltip title='Export as Excel'>
                  <Button
                    shape='circle'
                    onClick={this.downloadData}
                    disabled={loading || isEmpty(visibleInventorylist)}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <Row className='space-top'>
              <Col xs={24}>
                <POTable
                  data={visibleInventorylist}
                  handleViewDetails={this.navigateToPurchaseOrderDetail}
                  loading={loading}
                  permissions={this.props.rootStore.userStore.permissions}
                  reloadData={this.fetchOnholdPOs}
                  displayHolds={true}
                  holdTypes={holdTypeList}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(POHoldList));
