import { Button, Modal, Table, Tag } from 'antd';
import React, { Component } from 'react';
import { orderBy, upperCase } from 'lodash';

import moment from 'moment';

class FinAuditHistory extends Component {
  render() {
    const { visible, selectedFin } = this.props;

    return (
      <Modal
        visible={visible}
        title='FIN Audit History'
        width={990}
        onCancel={this.props.onCancel}
        closeIcon={false}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key='close' onClick={this.props.onCancel}>
            Close
          </Button>,
        ]}
      >
        <Table
          scroll={{ x: 'fit-content' }}
          dataSource={orderBy(selectedFin.fin_audits, ['createdAt'], 'desc')}
          columns={[
            {
              key: 'blocked',
              dataIndex: 'blocked',
              title: 'Overall',
              render: (blocked) => {
                let color = 'green',
                  status = 'active';
                if (blocked) {
                  color = 'red';
                  status = 'blocked';
                }
                return <Tag color={color}>{upperCase(status)}</Tag>;
              },
              width: 160,
            },
            {
              key: 'purchase_blocked',
              dataIndex: 'purchase_blocked',
              title: 'Purchase',
              render: (purchase_blocked) => {
                let color = 'green',
                  status = 'active';
                if (purchase_blocked) {
                  color = 'red';
                  status = 'blocked';
                }
                return <Tag color={color}>{upperCase(status)}</Tag>;
              },
              width: 160,
            },
            {
              key: 'sales_blocked',
              dataIndex: 'sales_blocked',
              title: 'Sales',
              render: (sales_blocked) => {
                let color = 'green',
                  status = 'active';
                if (sales_blocked) {
                  color = 'red';
                  status = 'blocked';
                }
                return <Tag color={color}>{upperCase(status)}</Tag>;
              },
              width: 160,
            },
            {
              key: 'comment',
              dataIndex: 'comment',
              title: 'Comment',
              width: 140,
            },
            {
              key: 'user',
              dataIndex: 'user',
              title: 'Last Updated By',
              render: (user) => {
                return (
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={
                      'https://teams.microsoft.com/l/chat/0/0?users=' +
                      user.email
                    }
                  >
                    {user.name}
                  </a>
                );
              },
              width: 180,
              sorter: (a, b) =>
                a.user.name.toString().localeCompare(b.user.name.toString()),
            },
            {
              key: 'createdAt',
              dataIndex: 'createdAt',
              title: 'Updated At',
              render: (createdAt) => {
                return moment(createdAt).format('lll');
              },
              width: 200,
              sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
            },
          ]}
          locale={{
            emptyText: 'No Audit History',
          }}
          rowKey={(record) => record.createdAt}
        />
      </Modal>
    );
  }
}

export default FinAuditHistory;
