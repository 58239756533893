import { Button, Col, Row, Tooltip, Typography, message } from 'antd';
import React, { Component } from 'react';
import { filter, forEach, isEmpty, pick, uniq, sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FieldTimeOutlined,
  KeyOutlined,
} from '@ant-design/icons';

import { DownloadOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import InventoryTable from './shared/inventoryScreens/InventoryTable';
import LotFilterCommponent from '../app/components/filter/LotFilter';
import RouteConstants from '../constants/RouteConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { downloadExcel } from '../utils/excelUtils';
import { getUiUrlWithPathParams } from '../api/UrlGenerator';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import moment from 'moment';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class InventoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inventoryList: [],
      visibleInventorylist: [],
      holdTypes: [],
      inventoryLocations: [],
      itemNumbers: [],
      lotCodes: [],
      descriptions: [],
    };
  }

  componentDidMount = () => {
    this.fetchInventory();
  };

  fetchInventory = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_INVENTORY_ACTIVE.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            inventoryList: response.data.inventory_list,
            visibleInventorylist: response.data.inventory_list,
            holdTypes: response.data.hold_type_list,
          });
          this.processDataForFilters(response.data.inventory_list);
        } else throw response;
      })
      .catch((err) => {
        console.log('*****', err);
        this.setState({ loading: false });
        message.error("Couldn't get inventory, please try later");
      });
  };

  processDataForFilters = (inventoryList) => {
    let locations = inventoryList.map((i) => i.location);
    locations = uniq(locations);

    const itemNumbers = {};
    inventoryList.map((i) => {
      if (!itemNumbers[i.fin]) {
        itemNumbers[i.fin] = [];
      }
      return uniq(itemNumbers[i.fin].push(i.location));
    });

    let lotCodes = [],
      descriptions = {};
    inventoryList.forEach((i) => {
      lotCodes.push({ lotCode: i.lot_code, fin: i.fin, location: i.location });

      if (!descriptions[i.description]) descriptions[i.description] = [];
      uniq(
        descriptions[i.description].push({
          lotCode: i.lot_code,
          fin: i.fin,
          location: i.location,
        })
      );
    });

    lotCodes = uniq(lotCodes);

    this.setState({
      inventoryLocations: locations,
      itemNumbers,
      lotCodes,
      descriptions,
    });
  };

  navigateToInventoryDetail = (id) => {
    const url = getUiUrlWithPathParams(RouteConstants.INVENTORY_DETAILS, {
      lotno: id,
    });
    this.props.history.push(url);
  };

  applyFilter = (data) => {
    const { location, fin, lot_code, description } = data;
    const { inventoryList } = this.state;
    this.setState({ loading: true });
    let filter_ = {};
    if (!isEmpty(location)) filter_.location = location;
    if (!isEmpty(fin)) filter_.fin = fin;
    if (!isEmpty(lot_code)) filter_.lot_code = lot_code;
    if (!isEmpty(description)) filter_.description = description;
    const list = filter(inventoryList, filter_);

    this.processDataForFilters(list);
    this.setState({ visibleInventorylist: list, loading: false });
  };

  resetFilter = () => {
    const { inventoryList } = this.state;
    this.processDataForFilters(inventoryList);
    this.setState({ visibleInventorylist: inventoryList });
  };

   downloadData = () => {
    const { visibleInventorylist } = this.state;
    let data = [];

    forEach(visibleInventorylist, function (i) {
        const record = pick(i, [
            'po_number',
            'location',
            'fin',
            'lot_code',
            'quantity',
            'description',
            'expiration_date',
            'audits',
        ]);

        const audits_info = sortBy(
            record.audits, 'id'
        ).reverse();

        record.auditcreatedat = audits_info[0] ?
            moment(audits_info[0].createdAt).format('YYYY-MM-DD') :
            '';
        record.auditcreatedby = audits_info[0] ?
            audits_info[0].actioned_by_username :
            '';
        record.auditaction = audits_info[0] ?
            audits_info[0].action :
            '';

        if (audits_info[0]) {
            let msg = getMessageForLatestAudit(audits_info[0]);
            msg = extractTextFromProps(msg);
            record.auditcomment = audits_info[0] ?
                msg :
                '';
        }

        data.push(record);
    });

    downloadExcel(data, true);
};

  
  

  render() {
    const {
      loading,
      visibleInventorylist,
      holdTypes,
      inventoryLocations,
      itemNumbers,
      lotCodes,
      descriptions,
    } = this.state;
    // if (loading) return <LoadingComponent tip='Inventory List will load' />;
    return (
      <div>
        <Helmet>
          <title>HMS - Inventory Active List</title>
          <meta name='description' content='List of active lots' />
        </Helmet>
        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Active Inventory
            </Typography.Text>
          </Col>
          <Col xs={24}>
            <Row className='space-top' gutter={[8, 8]}>
              <Col xs={24} md={23}>
                <LotFilterCommponent
                  locations={inventoryLocations}
                  itemNumbers={itemNumbers}
                  lotCodes={lotCodes}
                  descriptions={descriptions}
                  onSubmit={this.applyFilter}
                  onReset={this.resetFilter}
                />
              </Col>
              <Col xs={0} sm={24} md={1} style={{ textAlign: 'end' }}>
                <Tooltip title='Export as Excel'>
                  <Button
                    shape='circle'
                    onClick={this.downloadData}
                    disabled={loading || isEmpty(visibleInventorylist)}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <Row className='space-top'>
              <Col xs={24}>
                <InventoryTable
                  data={visibleInventorylist}
                  emptyText='No Lots Available'
                  loading={loading}
                  holdTypes={holdTypes}
                  handleViewDetails={this.navigateToInventoryDetail}
                  permissions={this.props.rootStore.userStore.permissions}
                  refreshData={this.fetchInventory}
                  displayHolds={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

function extractTextFromProps(obj) {
    if (obj.props && obj.props.children) {
      if (Array.isArray(obj.props.children)) {
        return obj.props.children
          .map((child) => {
            if (typeof child === "string") {
              return child;
            } else {
              return extractTextFromProps(child);
            }
          })
          .join("");
      } else if (typeof obj.props.children === "string") {
        return obj.props.children;
      }
    }
  return "";
}

function getMessageForLatestAudit(audit) {
  let color = "blue";
  let isPoAudit = false;
  let customIcon = "";
  let message = "";
  switch (audit.action) {
    case "JSC_HOLD":
    case "HOLD":
      color = "red";
      message = <span>placed the {isPoAudit ? "PO" : "item"} on hold</span>;
      break;
    case "JSC_UNHOLD":
    case "UNHOLD":
      color = "green";
      message = <span>removed the {isPoAudit ? "PO" : "item"} from hold</span>;
      break;
    case "PO_HOLD":
      color = "red";
      message = (
        <ul>
          <li>
            <span>
              placed the <strong>PO</strong> on hold
            </span>
          </li>
          <li>
            Holds Management System placed the lot on hold on{" "}
            {moment(audit.updatedAt).format("llll")}
          </li>
        </ul>
      );
      break;
    case "AUTO_HOLD":
      color = "red";
      message = (
        <span>
          <a
            href={`mailto:${audit.actioned_by_user_email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {audit.actioned_by_username}
          </a>{" "}
          placed the <strong>PO</strong> on hold
        </span>
      );
      break;
    case "WAREHOUSE_EDI":
      if (audit.sub_type === "SUCCESS") {
        color = "blue";
        message = <span>EDI update sent to warehouse successfully.</span>;
      } else {
        color = "orange";
        message = <span>EDI update to warehouse failed.</span>;
      }
      break;
    case "EMAIL":
      if (audit.sub_type === "SUCCESS") {
        color = "blue";
        message = (
          <span>
            Sent notification to warehouse @{" "}
            <a href={"mailto:" + audit.message}>{audit.message}</a>
          </span>
        );
      } else {
        color = "orange";
        message = (
          <span>
            Warehouse email address isn’t updated. Contact administrator to
            ensure warehouses are notified.
          </span>
        );
      }
      break;
    case "NAV_UPDATE":
      if (audit.sub_type === "SUCCESS") {
        color = "blue";
        message = <span>NAV updated successfully.</span>;
      } else {
        color = "orange";
        message = (
          <span>
            Update into NAV failed. Contact administrator for additional
            information.
          </span>
        );
      }
      break;
    case "NAV_TEMP_RELEASE_REQUEST":
      message = <span></span>;
      if (audit.sub_type === "NEW") {
        customIcon = <KeyOutlined />;
        color = "blue";
        message = <span>requested for temporary unhold</span>;
      } else if (audit.sub_type === "APPROVED") {
        customIcon = <CheckCircleOutlined />;
        message = <span>approved the request for temporary unhold</span>;
        color = "green";
      } else if (audit.sub_type === "REJECTED") {
        customIcon = <CloseCircleOutlined />;
        message = <span>rejected the request for temporary unhold</span>;
        color = "red";
      } else if (audit.sub_type === "EXPIRED") {
        customIcon = <FieldTimeOutlined />;
        color = "orange";
        message = "Request expired";
      } else if (audit.sub_type === "PROCESSED") {
        color = "red";
        message = <span>revoked temporary Access for the Lot</span>;
      }
      break;
    default:
      color = "blue";
      break;
  }
  return message;
}

export default SecureComponent(withRouter(InventoryList));
