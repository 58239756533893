/* eslint-disable no-useless-constructor */
import { Column, Row } from 'simple-flexbox';
import React, { Component, useEffect } from 'react';

import { EventType } from '@azure/msal-browser';
import LoadingComponent from '../app/components/Loading';
import Paths from '../constants/RouteConstants';
import { getActiveSignedInAccount } from '../utils/auth/auth';
import { loginRequest } from '../utils/auth/auth0config';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';
import { withRouter } from 'react-router';

const LoginScreen = (props) => {
  const { instance } = useMsal();

  useEffect(() => {
    //Signin Function
    (async () => {
      const accounts = instance.getAllAccounts();
      let activeSignedInAccount = getActiveSignedInAccount(accounts);
      if (activeSignedInAccount)
        instance.setActiveAccount(activeSignedInAccount);

      console.log('Existing Active Account');
      console.log(activeSignedInAccount);

      instance.addEventCallback(
        (event) => {
          // set active account after redirect
          if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            event.payload.account
          ) {
            const account = event.payload.account;
            console.log('New Login');
            console.log(account);
            instance.setActiveAccount(account);
          }
        },
        (error) => {
          console.log('error', error);
        }
      );

      // handle auth redirect/do all initial setup for msal
      instance
        .handleRedirectPromise()
        .then((authResult) => {
          // Check if user signed in
          const account = instance.getActiveAccount();
          let tokenExpiryTime = null;
          if (account) tokenExpiryTime = moment.unix(account.idTokenClaims.exp);
          console.log('Redirecting ');
          console.log(account);
          if (!account || tokenExpiryTime.isBefore(moment())) {
            // redirect anonymous user to login page
            instance.loginRedirect(loginRequest);
          } else props.history.push(Paths.REDIRECT_PAGE);
        })
        .catch((err) => {
          // TODO: Handle errors
          console.log(err);
        });
    })();
  }, [instance, props.history]);

  let otherProps = {};

  if ('authorized' in props) otherProps.authorized = props.authorized;
  return (
    <Row vertical='center' horizontal='center' className='full-height'>
      <Column>
        <LoadingComponent tip='Preparing login...' />
      </Column>
    </Row>
  );
};

export default withRouter(LoginScreen);
