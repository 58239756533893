import './dashboard/dashboard.css';

import { Card, Col, Empty, Row, Select } from 'antd';
import React, { Component } from 'react';
import { findIndex, isEmpty, sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';

import { Helmet } from 'react-helmet';
import HoldsGraph from './dashboard/HoldsGraph';
import HoldwiseStatistics from './dashboard/HoldwiseStatistics';
import HttpConstants from '../constants/HttpConstants';
import LoadingComponent from '../app/components/Loading';
import RoleConstants from '../constants/RoleConstants';
import SecureComponent from '../app/components/SecureComponent';
import StatisticsCard from './dashboard/StatisticsCard';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class DashboardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analyticsData: {},
      loading: false,
      isAdmin: false,
      selectedLocation: null,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const roles = this.props.rootStore.userStore.roles;
    let isAdmin = false;
    if (findIndex(roles, { name: RoleConstants.SUPER_USER }) !== -1)
      isAdmin = true;
    makeApiCallWithAuthentication(
      UrlConstants.ANALYTICS.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const location = Object.keys(
            response.data.location_statistics
          ).sort()[0];
          this.setState({
            loading: false,
            isAdmin,
            analyticsData: response.data,
            selectedLocation: location,
          });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ analyticsData: {}, loading: false });
      });
  }

  render() {
    const { loading, analyticsData, selectedLocation, isAdmin } = this.state;
    if (loading) return <LoadingComponent tip='Analysing Data...' />;
    if (isEmpty(analyticsData))
      return <Empty description='No data to analyse' />;

    const {
      overall_statistics,
      hold_statistics,
      location_statistics,
    } = analyticsData;

    return (
      <div>
        <Helmet>
          <title>HMS - Overall report of active holds</title>
          <meta
            name='description'
            content='Dashboard view of lots based on hold types'
          />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} xxl={{ span: 18, offset: 3 }}>
            {/* Inventory / PO overall count */}
            <Row justify='space-between' gutter={[8, 8]}>
              <Col xs={12} sm={5}>
                <StatisticsCard
                  title='Active Inventory'
                  count={overall_statistics.active_inventory.count}
                  quantity={overall_statistics.active_inventory.quantity}
                />
              </Col>
              <Col xs={12} sm={5}>
                <StatisticsCard
                  title='Onhold Inventory'
                  count={overall_statistics.on_hold_inventory.count}
                  quantity={overall_statistics.on_hold_inventory.quantity}
                />
              </Col>
              <Col xs={12} sm={5}>
                <StatisticsCard
                  title='Active PO'
                  count={overall_statistics.active_po.count}
                  quantity={overall_statistics.active_po.quantity}
                  poStats={true}
                />
              </Col>
              <Col xs={12} sm={5}>
                <StatisticsCard
                  title='Onhold PO'
                  count={overall_statistics.on_hold_po.count}
                  quantity={overall_statistics.on_hold_po.quantity}
                  poStats={true}
                />
              </Col>
            </Row>

            {/* Holds Wise Inventory / PO count */}
            <Row className='space-top-5' gutter={[10, 5]}>
              {sortBy(hold_statistics, 'name').map((hold, index) => {
                return (
                  <Col xs={24} sm={12} key={index}>
                    <HoldwiseStatistics
                      title={hold.name}
                      inventory={hold.inventory}
                      po={hold.po}
                    />
                  </Col>
                );
              })}
            </Row>

            {/* All holds by count permitted for a user - Horizontal Bar graph */}
            {hold_statistics && (
              <Row className='space-top-10'>
                <Col xs={0} sm={24}>
                  <Card>
                    <Row justify='center'>
                      <Col xs={24} sm={12}>
                        <HoldsGraph data={hold_statistics} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}

            {/* Location wise data for admins */}
            {isAdmin && (
              <Row className='space-top-10'>
                <Col xs={0} sm={24}>
                  <Card>
                    <Card.Meta
                      title='Holds Stats for a Location'
                      className='text-center'
                    />
                    <Row justify='center' className='space-top'>
                      <Col xs={24} sm={12} className='text-center'>
                        Select a Location :{' '}
                        <Select
                          showSearch
                          value={selectedLocation}
                          style={{ width: 200 }}
                          placeholder='Location'
                          optionFilterProp='children'
                          onChange={(selectedValue) =>
                            this.setState({ selectedLocation: selectedValue })
                          }
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {Object.keys(location_statistics)
                            .sort()
                            .map((location, index) => {
                              return (
                                <Select.Option value={location} key={index}>
                                  {location}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Col>
                      <Col xs={24} sm={14} className='space-top-10'>
                        <HoldsGraph
                          data={location_statistics[selectedLocation]}
                          location={true}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(DashboardView));
