import {
  Button,
  Col,
  Descriptions,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import { EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { hashCode, intToRGB } from '../utils/colorUtils';
import { isEmpty, sortBy } from 'lodash';

import AddorEditRoles from './adminRoles/AddorEditRoles';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import RoleConstants from '../constants/RoleConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

class AdminRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalLoading: false,
      modalVisible: false,
      roles: [],
      selectedRoleType: {},
    };
  }

  componentDidMount() {
    this.fetchRoles();
  }

  fetchRoles = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.ADMIN_GET_ALL_ROLES.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const role_ = response.data;
          role_.map((r) => (r.holdIds = r.permissions.map((p) => p.id)));
          this.setState({ roles: role_, loading: false });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ roles: [], loading: false });
      });
  };

  getColumnsForTable = () => {
    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
      },
      {
        key: 'permissions',
        dataIndex: 'permissions',
        title: 'Permissions',
        render: (permissions) => {
          return sortBy(permissions, 'name').map((permission, index) => {
            return (
              <Tag color={intToRGB(hashCode(permission.name))} key={index}>
                {permission.name}
              </Tag>
            );
          });
        },
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({
                    modalVisible: true,
                    selectedRoleType: data,
                  })
                }
              >
                <EditOutlined /> Update Role
              </Button>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({
                    modalVisible: true,
                    selectedRoleType: data,
                  })
                }
              >
                <EditOutlined /> Update
              </Button>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <Descriptions bordered>
        <Descriptions.Item label='Description'>
          {record.description}
        </Descriptions.Item>
        <Descriptions.Item label='Permissions'>
          {sortBy(record.permissions, 'name').map((permission, index) => {
            return (
              <Tag color={intToRGB(hashCode(permission.name))} key={index}>
                {permission.name}
              </Tag>
            );
          })}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  handleCancel = () => {
    this.setState({
      modalLoading: false,
      modalVisible: false,
      selectedRoleType: {},
    });
  };

  handleSubmit = (data) => {
    const { selectedRoleType } = this.state;
    const params = {
      name: data.name,
      description: data.description,
      permissions: data.holdIds,
    };
    this.setState({ modalLoading: true });
    let urlUsecase = UrlConstants.ADMIN_ADD_ROLE.USECASE;
    let httpCallType = HttpConstants.POST_METHOD;
    if (!isEmpty(selectedRoleType)) {
      params.id = selectedRoleType.id;
      urlUsecase = UrlConstants.ADMIN_UPDATE_ROLE.USECASE;
      httpCallType = HttpConstants.PUT_METHOD;
    }
    makeApiCallWithAuthentication(urlUsecase, httpCallType, params)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            modalLoading: false,
            modalVisible: false,
            selectedRoleType: {},
          });
          this.fetchRoles();
        } else throw response;
      })
      .catch((err) => {
        let errorMessage = 'Request failed, please try later';
        if (err.data && err.data.message) errorMessage = err.data.message;
        message.error(errorMessage);
        this.setState({ modalLoading: false });
      });
  };

  render() {
    const {
      loading,
      roles,
      modalLoading,
      modalVisible,
      selectedRoleType,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Roles</title>
          <meta name='description' content='Manage User Roles' />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              All Roles
            </Typography.Text>
          </Col>{' '}
          <Col xs={24} style={{ textAlign: 'end' }} className='space-top'>
            <Button
              type='primary'
              onClick={() => {
                this.setState({ modalVisible: true });
              }}
              disabled={modalVisible}
            >
              <PlusSquareOutlined />
              Add new role
            </Button>
          </Col>
          <Col xs={0} md={24} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={roles}
              loading={loading}
              columns={this.getColumnsForTable()}
              locale={{
                emptyText: 'No Roles Available',
              }}
              rowKey={(record) => record.id}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} roles`,
              }}
            />
          </Col>
          <Col xs={24} md={0} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={roles}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Roles Available',
              }}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} roles`,
              }}
            />
          </Col>
          <AddorEditRoles
            visible={modalVisible}
            loading={modalLoading}
            selectedData={selectedRoleType}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(AdminRoles), [
  RoleConstants.SUPER_USER,
]);
