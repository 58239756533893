import { Button, Col, Form, Row, Select } from 'antd';
import React, { Component } from 'react';
import { sortBy, trim, uniq } from 'lodash';

import DynamicRuleInput from './addNewAHRuleModal/DynamicRuleInput';
import Modal from 'antd/lib/modal/Modal';
import StringConstants from '../../constants/StringConstants';
import generateRuleStringForAutoHold from '../../utils/autoHoldRuleUtils';

class AddNewAutoHoldRule extends Component {
  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.autoHoldForm
      .validateFields()
      .then((data) => {
        this.props.onSubmit(data);
      })
      .catch((err) => {
        console.log('*****', err);
      });
  };

  getRuleString = () => {
    const data = this.autoHoldForm.getFieldsValue();
    return (
      <Row>
        <Col xs={16} offset={4}>
          {generateRuleStringForAutoHold(data)}
        </Col>
      </Row>
    );
  };

  renderMatchValueDropdown = (secondary) => {
    if (!this.autoHoldForm) return;

    const { allFins = [], allCountries = [], allLocations = [] } = this.props;
    const finStartsWith = uniq(allFins.map((f) => f.fin.split('-')[0]));

    const attr_name = secondary ? 'secondary_attribute' : 'attribute';
    const match_name = secondary ? 'secondary_match' : 'match';

    const attribute = this.autoHoldForm.getFieldValue(attr_name);
    const match = this.autoHoldForm.getFieldValue(match_name);
    if (attribute === StringConstants.AUTO_HOLDS.ATTRIBUTES.FIN) {
      return match === StringConstants.AUTO_HOLDS.MATCH.EQ
        ? allFins.map((f, index) => (
            <Select.Option value={f.fin} title={f.description} key={index}>
              {f.fin}
            </Select.Option>
          ))
        : finStartsWith.sort().map((f, index) => (
            <Select.Option value={f} key={index}>
              {f}
            </Select.Option>
          ));
    } else if (attribute === StringConstants.AUTO_HOLDS.ATTRIBUTES.COUNTRY) {
      return allCountries.map((c, index) => (
        <Select.Option value={c} key={index}>
          {c}
        </Select.Option>
      ));
    } else if (attribute === StringConstants.AUTO_HOLDS.ATTRIBUTES.LOCATION) {
      return allLocations.sort().map((l, index) => (
        <Select.Option value={l} key={index}>
          {l}
        </Select.Option>
      ));
    }
  };

  render() {
    const { visible = true, loading, allHolds = [] } = this.props;

    const layout = {
      wrapperCol: { offset: 4, span: 16 },
    };
    return (
      <Modal
        visible={visible}
        title={'Add New Auto Hold Rule'}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        closeIcon={false}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={this.handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={this.handleOk}
          >
            Add Rule
          </Button>,
        ]}
      >
        <Form
          {...layout}
          ref={(ref) => (this.autoHoldForm = ref)}
          initialValues={{
            attribute: StringConstants.AUTO_HOLDS.ATTRIBUTES.FIN,
          }}
        >
          <Form.Item
            name='attribute'
            rules={[
              {
                required: true,
                message: 'Please choose a field',
              },
            ]}
          >
            <Select
              onChange={() => {
                this.autoHoldForm.resetFields([
                  'match',
                  'value',
                  'secondary_attribute',
                  'secondary_match',
                  'secondary_value',
                ]);
                this.setState({ refresh: true });
              }}
            >
              <Select.Option value={StringConstants.AUTO_HOLDS.ATTRIBUTES.FIN}>
                FIN
              </Select.Option>
              <Select.Option
                value={StringConstants.AUTO_HOLDS.ATTRIBUTES.COUNTRY}
              >
                Country
              </Select.Option>
              <Select.Option
                value={StringConstants.AUTO_HOLDS.ATTRIBUTES.LOCATION}
              >
                Location
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='match'
            rules={[
              {
                required: true,
                message: 'Please select a match type',
              },
            ]}
          >
            <Select
              onChange={() => {
                this.autoHoldForm.resetFields(['value']);
                this.setState({ refresh: true });
              }}
              placeholder='Match Rule'
            >
              <Select.Option value={StringConstants.AUTO_HOLDS.MATCH.EQ}>
                is equal to
              </Select.Option>

              {this.autoHoldForm &&
              this.autoHoldForm.getFieldValue('attribute') !==
                StringConstants.AUTO_HOLDS.ATTRIBUTES.FIN ? (
                <Select.Option value={StringConstants.AUTO_HOLDS.MATCH.NEQ}>
                  not equal to
                </Select.Option>
              ) : (
                <Select.Option value={StringConstants.AUTO_HOLDS.MATCH.LIKE}>
                  starts with
                </Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name='value'
            rules={[
              {
                required: true,
                message: 'Please select a value',
              },
            ]}
          >
            <Select
              onChange={() => this.setState({ refresh: true })}
              placeholder='Value'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {this.renderMatchValueDropdown()}
            </Select>
          </Form.Item>

          <DynamicRuleInput
            renderSecondaryMatchValueDropdown={(params) => {
              return this.renderMatchValueDropdown(params);
            }}
            refresh={() => this.setState({ refresh: true })}
            autoHoldForm={this.autoHoldForm}
          />

          <Form.Item
            name='holds'
            rules={[
              {
                required: true,
                message: 'Please choose atleast one hold type',
              },
            ]}
          >
            <Select
              mode='multiple'
              onChange={() => this.setState({ refresh: true })}
              placeholder='Holds'
            >
              {sortBy(allHolds, 'name').map((permission, index) => {
                return (
                  <Select.Option value={permission.name} key={index}>
                    {permission.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>

        <Row className='space-top'>
          <Col xs={24}>{this.autoHoldForm && this.getRuleString()}</Col>
        </Row>
      </Modal>
    );
  }
}

export default AddNewAutoHoldRule;
