/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Menu } from 'antd';
import React, { Component } from 'react';

import { AreaChartOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import RouteConstants from '../../../constants/RouteConstants';
import SubMenu from 'antd/lib/menu/SubMenu';
import { deleteToken } from '../../../utils/auth/auth';
import { getUserDataFromCookie } from '../../../utils/AuthUtils';
import { isEmpty } from 'lodash';
import { logout } from '../../../utils/auth/auth0';
import { useMsal } from '@azure/msal-react';

const RightMenu = (props) => {
  const { instance } = useMsal();

  const processSignout = () => {
    deleteToken();
    let homeId = instance.getActiveAccount().homeAccountId;
    instance
      .logoutRedirect({
        account: instance.getAccountByHomeId(homeId),
        postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_RETURN_URL,
      })
      .catch((e) => {
        console.error(e);
      });
    logout(process.env.REACT_APP_LOGOUT_RETURN_URL);
  };

  const { mobileMenu = false, current } = props;
  const user = getUserDataFromCookie();
  const username_ = !isEmpty(user) ? user.name : 'U';
  return (
    <Menu mode={mobileMenu ? 'inline' : 'horizontal'} selectedKeys={[current]}>
      <Menu.Item key='fins'>
        <Link to={RouteConstants.FIN_LIST}>FINs</Link>
      </Menu.Item>

      <Menu.SubMenu
        title={
          <>
            <AreaChartOutlined />
            Reports
          </>
        }
      >
        <Menu.Item key='dashboard'>
          <Link to={RouteConstants.DASHBOARD}>Dashboard</Link>
        </Menu.Item>

        <Menu.Item key='inbound-containers'>
          <Link to={RouteConstants.INBOUND_CONTAINERS}>Inbound Containers</Link>
        </Menu.Item>
        <Menu.Item key='expiration-report'>
          <Link to={RouteConstants.EXPIRING_LOTS}>Expiring Lots</Link>
        </Menu.Item>
        <Menu.Item key='holds-mismatch'>
          <Link to={RouteConstants.HOLD_MISMATCH_LOTS}>Holds Mismatch</Link>
        </Menu.Item>
        <Menu.Item key='quantity-mismatch'>
          <Link to={RouteConstants.QUANTITY_MISMATCH_LOTS}>
            Quantity Mismatch
          </Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.Item key='teams'>
        <Link to={RouteConstants.USERS_PER_HOLD}>Teams</Link>
      </Menu.Item>

      {!mobileMenu && (
        <SubMenu
          title={
            <Avatar
              style={{
                color: '#f56a00',
                backgroundColor: '#fde3cf',
              }}
            >
              {username_.charAt(0)}
            </Avatar>
          }
        >
          <Menu.Item key='faq'>
            <a
              href='https://fishinco.atlassian.net/wiki/spaces/HHMS/pages/38633500/FAQs'
              target='_blank'
              rel='noopener noreferrer'
            >
              FAQs
            </a>
          </Menu.Item>
          <Menu.Item key='signout'>
            <a onClick={processSignout}>Signout</a>
          </Menu.Item>
        </SubMenu>
      )}
      {mobileMenu && (
        <Menu.Item>
          <a
            href='https://fishinco.atlassian.net/wiki/spaces/HHMS/pages/38633500/FAQs'
            target='_blank'
            rel='noopener noreferrer'
          >
            FAQs
          </a>
        </Menu.Item>
      )}
      {mobileMenu && (
        <Menu.Item key='signout'>
          <a onClick={processSignout}>Signout</a>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default RightMenu;
