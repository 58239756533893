import {
  Button,
  Col,
  Descriptions,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd';
import { CheckCircleTwoTone, CloseSquareTwoTone } from '@ant-design/icons';
import { EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { hashCode, intToRGB } from '../utils/colorUtils';

import AddorEditHoldTypes from './adminHoldTypes/AddorEditHoldTypes';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import RoleConstants from '../constants/RoleConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { isEmpty } from 'lodash';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

class AdminLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalLoading: false,
      modalVisible: false,
      holdTypes: [],
      selectedHoldType: {},
    };
  }

  componentDidMount() {
    this.fetchHoldTypes();
  }

  fetchHoldTypes = () => {
    this.setState({ loading: true });

    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_HOLD_TYPES.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ holdTypes: response.data, loading: false });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ holdTypes: [], loading: false });
      });
  };

  getColumnsForTable = () => {
    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Hold Type',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (name) => <Tag color={intToRGB(hashCode(name))}>{name}</Tag>,
        defaultSortOrder: 'ascend',
      },
      {
        key: 'warehouse_holdtype',
        dataIndex: 'warehouse_holdtype',
        title: 'Warehouse Hold Type',
        sorter: (a, b) =>
          a.warehouse_holdtype.localeCompare(b.warehouse_holdtype),
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
      },
      {
        key: 'not_planned',
        dataIndex: 'not_planned',
        title: 'Not Planned For',
        render: (not_planned) => {
          if (not_planned)
            return (
              <CheckCircleTwoTone style={{ fontSize: 20, marginLeft: 15 }} />
            );
          else
            return (
              <CloseSquareTwoTone
                twoToneColor='red'
                style={{ fontSize: 20, marginLeft: 15 }}
              />
            );
        },
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({ modalVisible: true, selectedHoldType: data })
                }
              >
                <EditOutlined /> Update Hold Type
              </Button>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({ modalVisible: true, selectedHoldType: data })
                }
              >
                <EditOutlined /> Update
              </Button>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <Descriptions bordered>
        <Descriptions.Item label='Warehouse Hold Type'>
          {record.warehouse_holdtype}
        </Descriptions.Item>
        <Descriptions.Item label='Description'>
          {record.description}
        </Descriptions.Item>
        <Descriptions.Item label='Not Planned For'>
          {record.not_planned ? (
            <CloseSquareTwoTone
              twoToneColor='red'
              style={{ fontSize: 20, marginLeft: 15 }}
            />
          ) : (
            <CheckCircleTwoTone style={{ fontSize: 20, marginLeft: 15 }} />
          )}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  handleCancel = () => {
    this.setState({
      modalLoading: false,
      modalVisible: false,
      selectedHoldType: {},
    });
  };

  handleSubmit = (data) => {
    const { selectedHoldType } = this.state;
    const params = {
      ...data,
    };
    this.setState({ modalLoading: true });
    let urlUsecase = UrlConstants.ADMIN_ADD_HOLD_TYPE.USECASE;
    let httpCallType = HttpConstants.POST_METHOD;
    if (!isEmpty(selectedHoldType)) {
      params.id = selectedHoldType.id;
      urlUsecase = UrlConstants.ADMIN_UPDATE_HOLD_TYPE.USECASE;
      httpCallType = HttpConstants.PUT_METHOD;
    }
    makeApiCallWithAuthentication(urlUsecase, httpCallType, params)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            modalLoading: false,
            modalVisible: false,
            selectedHoldType: {},
          });
          this.fetchHoldTypes();
        } else throw response;
      })
      .catch((err) => {
        message.error('Request failed, please try later');
        this.setState({ modalLoading: false });
      });
  };

  render() {
    const { loading, holdTypes, modalLoading, modalVisible, selectedHoldType } =
      this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Hold Types</title>
          <meta name='description' content='Manage Hold Types' />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              All Hold Types
            </Typography.Text>
          </Col>
          <Col xs={24} style={{ textAlign: 'end' }} className='space-top'>
            <Button
              type='primary'
              onClick={() => {
                this.setState({ modalVisible: true });
              }}
              disabled={modalVisible}
            >
              <PlusSquareOutlined />
              Add new hold type
            </Button>
          </Col>
          <Col xs={0} md={24} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={holdTypes}
              loading={loading}
              columns={this.getColumnsForTable()}
              locale={{
                emptyText: 'No Hold Types Available',
              }}
              rowKey={(record) => record.id}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} hold types`,
              }}
            />
          </Col>
          <Col xs={24} md={0} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={holdTypes}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Hold Types Available',
              }}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} hold types`,
              }}
            />
          </Col>
          <AddorEditHoldTypes
            visible={modalVisible}
            loading={modalLoading}
            selectedData={selectedHoldType}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(AdminLocations), [
  RoleConstants.SUPER_USER,
]);
