import numeral from 'numeral';

numeral.register('locale', 'holds', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
});
numeral.locale('holds');

export default function formatNumber(number) {
  if (number >= 100000) return numeral(number).format('0a+');
  else return numeral(number).format('0,0');
}
