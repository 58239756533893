import { Button, Col, Row, Tooltip, Typography, message } from 'antd';
import React, { Component } from 'react';
import { filter, findIndex, forEach, isEmpty, pick, uniq } from 'lodash';
import { inject, observer } from 'mobx-react';

import { DownloadOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import SecureComponent from '../app/components/SecureComponent';
import TOFilterComponent from '../app/components/filter/TOFilter';
import TOTable from './shared/transferOrderScreens/TOTable';
import UrlConstants from '../api/UrlConstants';
import { downloadExcelForTO } from '../utils/excelUtils';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class TOActiveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      inventoryList: [],
      visibleInventorylist: [],
      holdTypeList: [],
      locations: [],
      tos: {},
      fins: {},
      vendors: {},
    };
  }

  componentDidMount = () => {
    this.fetchActivePOs();
  };

  fetchActivePOs = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_TO.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            inventoryList: response.data,
            visibleInventorylist: response.data,
            holdTypeList: [],
          });
          this.processDataForFilters(response.data);
        } else throw response;
      })
      .catch((err) => {
        console.log('*****', err);
        this.setState({ loading: false });
        message.error("Couldn't get transfer orders, please try later");
      });
  };

  processDataForFilters = (inventoryList) => {
    let locations = inventoryList.map((i) => i.to_warehouse);
    locations = uniq(locations);

    const tos = {},
      fins = {},
      vendors = {};

    inventoryList.forEach((i) => {
      if (!tos[i.to_number]) {
        tos[i.to_number] = [];
      }
      uniq(tos[i.to_number].push(i.location));

      i.transfer_order_line_items.forEach((f) => {
        //Fin Filter
        if (!fins[f.fin]) fins[f.fin] = [];
        uniq(
          fins[f.fin].push({
            location: i.location,
            to_number: i.to_number,
          })
        );

        //Vendor Filter
        if (!vendors[i.supplier]) vendors[i.supplier] = [];
        uniq(
          vendors[i.supplier].push({
            location: i.location,
            to_number: i.to_number,
            fin: f.fin,
          })
        );
      });
    });

    this.setState({
      locations,
      tos,
      fins,
      vendors,
    });
  };

  applyFilter = (data) => {
    const { location, to, fin } = data;
    const { inventoryList } = this.state;

    this.setState({ loading: true });
    let filter_ = {};
    if (!isEmpty(location)) filter_.to_warehouse = location;
    if (!isEmpty(to)) filter_.to_number = to;
    // if (!isEmpty(vendor)) filter_.supplier = vendor;

    let list = filter(inventoryList, filter_);

    if (!isEmpty(fin)) {
      const templist = [];
      forEach(list, function (l) {
        findIndex(l.transfer_order_line_items, {
          fin: fin,
        }) > -1 && templist.push(l);
      });
      list = templist;
    }

    this.processDataForFilters(list);
    this.setState({ visibleInventorylist: list, loading: false });
  };

  resetFilter = () => {
    const { inventoryList } = this.state;
    this.processDataForFilters(inventoryList);
    this.setState({ visibleInventorylist: inventoryList });
  };

  downloadData = () => {
    const { visibleInventorylist } = this.state;
    let data = [];
    forEach(visibleInventorylist, function (i) {
      const obj = pick(i, [
        'receipt_date',
        'to_number',
        'to_warehouse',
        'supplier',
        'transfer_from_country_code',
        'transfer_order_line_items',
      ]);
      data.push(obj);
    });

    downloadExcelForTO(data, true);
  };

  render() {
    const {
      loading,
      visibleInventorylist,
      holdTypeList,
      locations,
      tos,
      fins,
      vendors,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Transfer Orders Active List</title>
          <meta name='description' content='List of active transfer orders' />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Active Transfer Orders
            </Typography.Text>
          </Col>
          <Col xs={24}>
            <Row className='space-top' gutter={[8, 8]}>
              <Col xs={24} md={23}>
                <TOFilterComponent
                  locations={locations}
                  tos={tos}
                  fins={fins}
                  vendors={vendors}
                  onSubmit={this.applyFilter}
                  onReset={this.resetFilter}
                />
              </Col>
              <Col xs={0} sm={24} md={1} style={{ textAlign: 'end' }}>
                <Tooltip title='Export as Excel'>
                  <Button
                    shape='circle'
                    onClick={this.downloadData}
                    disabled={loading || isEmpty(visibleInventorylist)}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <Row className='space-top'>
              <Col xs={24}>
                <TOTable
                  data={visibleInventorylist}
                  loading={loading}
                  permissions={this.props.rootStore.userStore.permissions}
                  reloadData={this.fetchActivePOs}
                  holdTypes={holdTypeList}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(TOActiveList));
