import { Button, Descriptions, Form, Modal, Switch } from 'antd';
import React, { Component } from 'react';

import EmailTagInputs from './updateLocationEmailModal/EmailTagInputs';
import StringConstants from '../../constants/StringConstants';
import { isEmpty } from 'lodash';

class UpdateLocationEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailLoading: false,
      isEmailInputDisabled: true,
    };
  }

  componentWillReceiveProps() {
    this.setState({ isEmailInputDisabled: true });
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    const data = this.contactFormRef.getFieldValue('isContactEmailForReports');
    if (this.props.usecase === StringConstants.LOCATION_PO_REPORTS)
      this.props.onSubmit(data);
    else this.props.onSubmit();
  };

  updateEmails = (emails) => {
    this.props.updateEmails(emails);
  };

  isInputDisabled = () => {
    const { isContactEmailForReports, usecase } = this.props;
    const { isEmailInputDisabled } = this.state;

    let inputDisabled =
      isContactEmailForReports &&
      usecase === StringConstants.LOCATION_PO_REPORTS;
    if (!this.contactFormRef) {
      return inputDisabled && isEmailInputDisabled;
    } else {
      return this.contactFormRef.getFieldValue('isContactEmailForReports');
    }
  };

  isFormSubmitAllowed = (emails_) => {
    const { isContactEmailForReports, usecase } = this.props;
    const { isEmailInputDisabled } = this.state;
    if (usecase === StringConstants.LOCATION_PO_REPORTS) {
      if (!this.contactFormRef) {
        if (isContactEmailForReports && isEmailInputDisabled) return true;
      } else if (this.contactFormRef.getFieldValue('isContactEmailForReports'))
        return true;
    }
    return emails_.length !== 0;
  };

  render() {
    const {
      loading,
      visible,
      selectedLocation,
      usecase,
      isContactEmailForReports,
    } = this.props;

    let emails_ = this.props.selectedEmails;
    if (!isEmpty(emails_)) {
      if (typeof emails_ === 'string') emails_ = emails_.split(',');
    } else emails_ = [];

    const layout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
    };

    return (
      <Modal
        visible={visible}
        title={
          usecase === StringConstants.LOCATION_PO_REPORTS
            ? 'Update e-mail for reports'
            : 'Update contact e-mail'
        }
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={this.handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={this.handleOk}
            disabled={!this.isFormSubmitAllowed(emails_)}
          >
            Update e-mail
          </Button>,
        ]}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label='Code'>
            {selectedLocation.code}
          </Descriptions.Item>
          <Descriptions.Item label='Name'>
            {selectedLocation.name}
          </Descriptions.Item>
        </Descriptions>
        <div className='space-top'>
          <Form
            {...layout}
            ref={(ref) => (this.contactFormRef = ref)}
            initialValues={
              usecase === StringConstants.LOCATION_PO_REPORTS
                ? {
                    isContactEmailForReports,
                    locations_email: selectedLocation.container_report_email,
                  }
                : { locations_email: selectedLocation.warehouse_contact_email }
            }
          >
            {usecase === StringConstants.LOCATION_PO_REPORTS && (
              <Form.Item
                name='isContactEmailForReports'
                label={<span>Use Contact emails</span>}
                valuePropName='checked'
              >
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  onChange={(value) => {
                    this.setState({ isEmailInputDisabled: value });
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              name='locations_email'
              label={<span>Contact email</span>}
            >
              <EmailTagInputs
                emails={emails_}
                onUpdate={this.updateEmails}
                disabled={this.isInputDisabled()}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default UpdateLocationEmailModal;
