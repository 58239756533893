// Inventory Holds Table to display Holds as a list of columns
import {
  Button,
  Col,
  Descriptions,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import React, { Component } from 'react';
import { concat, filter, flatten, forEach, sortBy, upperCase } from 'lodash';
import {
  getInventoryStatusColor,
  hashCode,
  intToRGB,
} from '../../../utils/colorUtils';

import { CodepenOutlined } from '@ant-design/icons';
import HoldUnholdModal from '../HoldUnholdModal';
import HttpConstants from '../../../constants/HttpConstants';
import NumberFormat from 'react-number-format';
import StringConstants from '../../../constants/StringConstants';
import UrlConstants from '../../../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../../../api/ApiManager';
import moment from 'moment';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FieldTimeOutlined,
  KeyOutlined,
} from '@ant-design/icons';

class InventoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      inventory: {},
      modalLoading: false,
      holdManagementModalVisible: false,
    };
  }

  componentDidMount = () => {
    this.getColumnsForTable();
  };

  handleViewHoldModalClick = (data) => {
    this.setState({ inventory: data, holdManagementModalVisible: true });
  };

  handlePutOnHoldCancel = () => {
    this.setState({
      modalLoading: false,
      holdManagementModalVisible: false,
      inventory: {},
    });
  };

  //This is used while updating just watch flag from modal
  handleUpdateWatchStatus = async (inventoryObj, flag) => {
    const payload = {
      type: StringConstants.WATCHERS.TYPE.LOT,
      action: !flag
        ? StringConstants.WATCHERS.ACTION.STOP_WATCHING
        : StringConstants.WATCHERS.ACTION.START_WATCHING,
      identifier: inventoryObj.id,
    };
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.WATCHER_ACTIVITY.USECASE,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          message.success(response.data.message);
          this.setState({
            holdManagementModalVisible: false,
            modalLoading: false,
          });
          this.props.refreshData && this.props.refreshData();
        } else throw response;
      })
      .catch((err) => {
        let errMessage = 'Error occured while performing the action';
        if (err && err.data && err.data.message) errMessage = err.data.message;
        message.error(errMessage);
        this.setState({
          holdManagementModalVisible: false,
          modalLoading: false,
        });
      });
  };

  handlePutOnHoldSubmit = async (
    inventoryObj,
    addedHolds,
    removedHolds,
    comment,
    watch
  ) => {
    this.setState({ modalLoading: true });
    const params = {
      lot_code: inventoryObj.lot_code,
      po: inventoryObj.po_number,
      location: inventoryObj.location,
      fin: inventoryObj.fin,
      id: inventoryObj.id,
      action: 'HOLD',
      holds: [],
      watch,
    };
    forEach(addedHolds, function (holdType) {
      const holdObj = {
        type: holdType,
        message: comment,
      };
      params.holds.push(holdObj);
    });

    const removeHoldParams = {
      lot_code: inventoryObj.lot_code,
      po: inventoryObj.po_number,
      location: inventoryObj.location,
      fin: inventoryObj.fin,
      id: inventoryObj.id,
      action: 'UNHOLD',
      holds: [],
      watch,
    };
    forEach(removedHolds, function (holdType) {
      const holdObj = {
        type: holdType,
        message: comment,
      };
      removeHoldParams.holds.push(holdObj);
    });

    try {
      if (addedHolds.length > 0) {
        const response = await makeApiCallWithAuthentication(
          UrlConstants.ACTION_ON_INVENTORY.USECASE,
          HttpConstants.POST_METHOD,
          params
        );
        if (response.status === 200) {
          await this.updateRemovedHolds(removeHoldParams);
        } else {
          message.error('Could not add hold, aborting remove operation');
          this.setState({
            modalLoading: false,
          });
        }
      } else {
        await this.updateRemovedHolds(removeHoldParams);
      }
    } catch (err) {
      console.log('*******', err);
      message.error("Couldn't perform update, please try later");
      this.setState({
        modalLoading: false,
      });
    }
  };

  updateRemovedHolds = async (removeHoldParams) => {
    if (removeHoldParams.holds.length > 0) {
      const r_response = await makeApiCallWithAuthentication(
        UrlConstants.ACTION_ON_INVENTORY.USECASE,
        HttpConstants.POST_METHOD,
        removeHoldParams
      );
      if (r_response.status === 200) {
        message.success('Successfully updated Lot !');
      } else {
        message.warn(
          'New holds added, removing existing holds failed. Try again'
        );
      }
    } else {
      message.success('Successfully updated Lot !');
    }
    this.setState({
      modalLoading: false,
      holdManagementModalVisible: false,
    });

    this.props.refreshData && this.props.refreshData();
  };

  getColumnsForTable() {
    const columns = [
  {
    key: "location",
    dataIndex: "location",
    title: "Location",
    width: 120,
    sorter: (a, b) => a.location.localeCompare(b.location),
    fixed: "left",
  },
  {
    title: "FIN",
    width: 100,
    sorter: (a, b) => a.fin.localeCompare(b.fin),
    defaultSortOrder: "ascend",
    fixed: "left",
    render: ({ fin, store_on_peco_pallet }) => {
      return (
        <>
          <span>{fin}</span>
          {store_on_peco_pallet && (
            <Tooltip placement="topLeft" title="Store on PECO Pallet">
              <CodepenOutlined style={{ marginLeft: 10 }} />
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    key: "description",
    dataIndex: "description",
    title: "Description",
    ellipsis: {
      showTitle: false,
    },
    width: 130,
    responsive: ["md"],
    render: (description) => (
      <Tooltip placement="topLeft" title={description}>
        {description}
      </Tooltip>
    ),
    sorter: (a, b) => a.description.localeCompare(b.description),
  },
  {
    key: "lot_code",
    dataIndex: "lot_code",
    title: "Lot Number",
    width: 120,
    sorter: (a, b) => a.lot_code.localeCompare(b.lot_code),
  },
  {
    key: "quantity",
    dataIndex: "quantity",
    title: "Quantity",
    width: 100,
    render: (quantity) => (
      <NumberFormat
        value={quantity}
        displayType={"text"}
        thousandSeparator={true}
      />
    ),
    sorter: (a, b) => a.quantity - b.quantity,
  },
  {
    key: "expiration_date",
    dataIndex: "expiration_date",
    title: "Expiry Date",
    width: 100,
     render: (expiration_date) => {
      return (
        <Typography.Text>
          {expiration_date ? moment(expiration_date).format("ll"): ''}
        </Typography.Text>
      );
    },
    sorter: (a, b) => {
	    return moment(a.expiration_date).unix() - moment(b.expiration_date).unix();
    },
  },
  {
    key: "auditcreatedat",
    dataIndex: "audits",
    title: "Audit CreatedAt",
    width: 120,
    render: (audits) => {
      const data = sortBy(audits, "id").reverse();
      return (
        <Typography.Text>
          {data[0] && moment(data[0].createdAt).format("ll")}
        </Typography.Text>
      );
    },
    sorter: (a, b) => {
		const xsort = sortBy(a.audits, "id").reverse();
		const ysort = sortBy(b.audits, "id").reverse();
	    return moment(xsort[0].createdAt).unix() - moment(ysort[0].createdAt).unix();
    },
  },
  {
    key: "auditcreatedby",
    dataIndex: "audits",
    title: "Audit CreatedBy",
    width: 120,
    render: (audits) => {
      const data = sortBy(audits, "id").reverse();
      return (
        <Typography.Text>
          {data[0] && data[0].actioned_by_username}
        </Typography.Text>
      );
    },
    sorter: (a, b) => {
		const xsort = sortBy(a.audits, "id").reverse();
		const ysort = sortBy(b.audits, "id").reverse();
	    return xsort[0].actioned_by_username .localeCompare(ysort[0].actioned_by_username);
    },
  },
  {
    key: "auditaction",
    dataIndex: "audits",
    title: "Audit Action",
    width: 100,
    render: (audits) => {
      const data = sortBy(audits, "id").reverse();
      return <Typography.Text>{data[0] && data[0].action}</Typography.Text>;
    },
    sorter: (a, b) => {
		const xsort = sortBy(a.audits, "id").reverse();
		const ysort = sortBy(b.audits, "id").reverse();
	    return xsort[0].action .localeCompare(ysort[0].action)
    },
  },
  {
    key: "auditcomment",
    dataIndex: "audits",
    title: "Audit Comment",
    width: 140,
	ellipsis: {
	  showTitle: false,
	},
    responsive: ["md"],
    render: (audits) => {
      const data = sortBy(audits, "id").reverse();
      return (
	    <Tooltip placement="topLeft" title={data[0] && getMessageForLatestAudit(data[0])}>
	   		{data[0] && getMessageForLatestAudit(data[0])}
	  	</Tooltip>
      );
    },
  },
];

    
function getMessageForLatestAudit(audit) {
  let color = "blue";
  let isPoAudit = false;
  let customIcon = "";
  let message = "";
  switch (audit.action) {
    case "JSC_HOLD":
    case "HOLD":
      color = "red";
      message = <span>placed the {isPoAudit ? "PO" : "item"} on hold</span>;
      break;
    case "JSC_UNHOLD":
    case "UNHOLD":
      color = "green";
      message = <span>removed the {isPoAudit ? "PO" : "item"} from hold</span>;
      break;
    case "PO_HOLD":
      color = "red";
      message = (
        <ul>
          <li>
            <span>
              placed the <strong>PO</strong> on hold
            </span>
          </li>
          <li>
            Holds Management System placed the lot on hold on{" "}
            {moment(audit.updatedAt).format("llll")}
          </li>
        </ul>
      );
      break;
    case "AUTO_HOLD":
      color = "red";
      message = (
        <span>
          <a
            href={`mailto:${audit.actioned_by_user_email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {audit.actioned_by_username}
          </a>{" "}
          placed the <strong>PO</strong> on hold
        </span>
      );
      break;
    case "WAREHOUSE_EDI":
      if (audit.sub_type === "SUCCESS") {
        color = "blue";
        message = <span>EDI update sent to warehouse successfully.</span>;
      } else {
        color = "orange";
        message = <span>EDI update to warehouse failed.</span>;
      }
      break;
    case "EMAIL":
      if (audit.sub_type === "SUCCESS") {
        color = "blue";
        message = (
          <span>
            Sent notification to warehouse @{" "}
            <a href={"mailto:" + audit.message}>{audit.message}</a>
          </span>
        );
      } else {
        color = "orange";
        message = (
          <span>
            Warehouse email address isn’t updated. Contact administrator to
            ensure warehouses are notified.
          </span>
        );
      }
      break;
    case "NAV_UPDATE":
      if (audit.sub_type === "SUCCESS") {
        color = "blue";
        message = <span>NAV updated successfully.</span>;
      } else {
        color = "orange";
        message = (
          <span>
            Update into NAV failed. Contact administrator for additional
            information.
          </span>
        );
      }
      break;
    case "NAV_TEMP_RELEASE_REQUEST":
      message = <span></span>;
      if (audit.sub_type === "NEW") {
        customIcon = <KeyOutlined />;
        color = "blue";
        message = <span>requested for temporary unhold</span>;
      } else if (audit.sub_type === "APPROVED") {
        customIcon = <CheckCircleOutlined />;
        message = <span>approved the request for temporary unhold</span>;
        color = "green";
      } else if (audit.sub_type === "REJECTED") {
        customIcon = <CloseCircleOutlined />;
        message = <span>rejected the request for temporary unhold</span>;
        color = "red";
      } else if (audit.sub_type === "EXPIRED") {
        customIcon = <FieldTimeOutlined />;
        color = "orange";
        message = "Request expired";
      } else if (audit.sub_type === "PROCESSED") {
        color = "red";
        message = <span>revoked temporary Access for the Lot</span>;
      }
      break;
    default:
      color = "blue";
      break;
  }
  return message;
}

    const actions_columns = [
      {
        key: 'id',
        title: this.props.displayHolds ? 'Holds' : 'Status',
        width: this.props.displayHolds ? 100 : 140,
        render: ({ status, inventory_holds }) => {
          if (this.props.displayHolds) {
            return (
              <span>
                {sortBy(inventory_holds, 'hold_type.name').map(
                  (hold_, index) => {
                    if (hold_.hold_type && hold_.is_active) {
                      return (
                        <Tooltip title={hold_.message} key={index}>
                          <Tag
                            color={intToRGB(hashCode(hold_.hold_type.name))}
                            style={{ margin: 2 }}
                          >
                            {hold_.hold_type.name}
                          </Tag>
                        </Tooltip>
                      );
                    } else return null;
                  }
                )}
              </span>
            );
          } else {
            return (
              <Tag color={getInventoryStatusColor(status)}>
                {upperCase(status)}
              </Tag>
            );
          }
        },
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          if (data.status === StringConstants.ACTIVE_STATUS)
            return (
              <Space>
                <Button
                  type='primary'
                  danger
                  onClick={() => this.handleViewHoldModalClick(data)}
                >
                  Put on hold
                </Button>
                <Button
                  type='dashed'
                  onClick={() => this.props.handleViewDetails(data.id)}
                >
                  View Details
                </Button>
              </Space>
            );
          else
            return (
				<>
                <Button
                  type='primary'
                  onClick={() => this.handleViewHoldModalClick(data)}
                >
                  Action on Item
                </Button>
                <br/>
                <br/>
                <Button
                  type='dashed'
                  onClick={() => this.props.handleViewDetails(data.id)}
                >
                  View Details
                </Button>
                </>
            );
        },
      },
    ];

    if (this.props.displayHolds) {
      const earliestHoldColumn = {
        key: 'id',
        dataIndex: 'inventory_holds',
        title: 'Onhold since',
        width: 100,
        render: (inventory_holds) => {
          const data = sortBy(
            filter(inventory_holds, 'is_active'),
            'updatedAt'
          );
          return (
            <Typography.Text>
              {data[0] && moment(data[0].updatedAt).format('ll')}
            </Typography.Text>
          );
        },
        sorter: (a, b) => {
          const a_data = sortBy(
            filter(a.inventory_holds, 'is_active'),
            'updatedAt'
          );
          const b_data = sortBy(
            filter(b.inventory_holds, 'is_active'),
            'updatedAt'
          );
          if (a_data[0] && b_data[0]) {
            return moment(a_data[0].updatedAt).diff(
              moment(b_data[0].updatedAt),
              'seconds'
            );
          } else if (b_data[0]) return 1;
          else return -1;
        },
      };
      columns.push(earliestHoldColumn);
    }

    let c = flatten(concat(columns, actions_columns));
    this.setState({ columns: c });
  }

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'lot_code',
        dataIndex: 'lot_code',
        title: 'Lot Number',
        sorter: (a, b) => a.lot_code.localeCompare(b.lot_code),
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          if (data.status === StringConstants.ACTIVE_STATUS)
            return (
              <Space>
                <Button
                  type='primary'
                  danger
                  onClick={() => this.handleViewHoldModalClick(data)}
                >
                  Put on hold
                </Button>
              </Space>
            );
          else
            return (
              <Space>
                <Button
                  type='primary'
                  onClick={() => this.handleViewHoldModalClick(data)}
                >
                  Action on Item
                </Button>
              </Space>
            );
        },
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    const holds_info = sortBy(
      filter(record.inventory_holds, 'is_active'),
      'updatedAt'
    );
    return (
      <div className='text-center'>
        <Descriptions bordered>
          <Descriptions.Item label='Location'>
            {record.location}
          </Descriptions.Item>
          <Descriptions.Item label='FIN'>{record.fin}</Descriptions.Item>

          <Descriptions.Item label='Description'>
            {record.description}
          </Descriptions.Item>
          <Descriptions.Item label='Quantity'>
            <NumberFormat
              value={record.quantity}
              displayType={'text'}
              thousandSeparator={true}
            />
          </Descriptions.Item>
          <Descriptions.Item label='Status'>
            <Tag color={getInventoryStatusColor(record.status)}>
              {upperCase(record.status)}
            </Tag>
          </Descriptions.Item>
          {record.status === StringConstants.ONHOLD_STATUS && (
            <Descriptions.Item label='Active Holds'>
              {sortBy(record.inventory_holds, 'hold_type.name').map(
                (hold_, index) => {
                  if (hold_.hold_type && hold_.is_active) {
                    return (
                      <Tooltip title={hold_.message} key={index}>
                        <Tag
                          color={intToRGB(hashCode(hold_.hold_type.name))}
                          style={{ margin: 2 }}
                        >
                          {hold_.hold_type.name}
                        </Tag>
                      </Tooltip>
                    );
                  } else return null;
                }
              )}
            </Descriptions.Item>
          )}

          <Descriptions.Item label='Onhold since'>
            {this.props.displayHolds && (
              <Typography.Text>
                {holds_info[0] && moment(holds_info[0].updatedAt).format('ll')}
              </Typography.Text>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Button
          type='dashed'
          onClick={() => this.props.handleViewDetails(record.id)}
          style={{ marginTop: 5 }}
        >
          View Details
        </Button>
      </div>
    );
  };

  render() {
    const { columns, inventory, modalLoading, holdManagementModalVisible } =
      this.state;
    const { data, emptyText, holdTypes, permissions, loading } = this.props;

    return (
      <div>
        <Col xs={0} md={24}>
          <Table
            scroll={{ x: 'fit-content' }}
            dataSource={data}
            loading={loading}
            columns={columns}
            locale={{
              emptyText: emptyText ? emptyText : 'No Inventory Available',
            }}
            rowKey={(record) => record.id}
            pagination={{
              defaultPageSize: 50,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Col>
        <Col xs={24} md={0}>
          <Table
            scroll={{ x: 'fit-content' }}
            dataSource={data}
            loading={loading}
            columns={this.getColumnsForTableSmallScreens()}
            locale={{
              emptyText: emptyText ? emptyText : 'No Inventory Available',
            }}
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: this.renderRowForSmallScreens,
            }}
            pagination={{
              defaultPageSize: 50,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Col>
        <HoldUnholdModal
          inventory={inventory}
          visible={holdManagementModalVisible}
          loading={modalLoading}
          onCancel={this.handlePutOnHoldCancel}
          onSubmit={this.handlePutOnHoldSubmit}
          onWatchUpdate={this.handleUpdateWatchStatus}
          holdTypes={holdTypes}
          permissions={permissions}
        />
      </div>
    );
  }
}

export default InventoryTable;
