import { Badge, Col, Empty, Row, Tabs, Typography, message } from 'antd';
import React, { Component } from 'react';

import ExpiringLotsTable from './expiringLotsReports/ExpiringLotsTable';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import LoadingComponent from '../app/components/Loading';
import RouteConstants from '../constants/RouteConstants';
import SecureComponent from '../app/components/SecureComponent';
import StringConstants from '../constants/StringConstants';
import UrlConstants from '../api/UrlConstants';
import cookie from 'react-cookies';
import { getUiUrlWithPathParams } from '../api/UrlGenerator';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

class ExpiringLotsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      activeTab: 0,
    };
  }

  componentDidMount = () => {
    this.fetchInventory();
  };

  fetchInventory = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_EXPIRY_REPORT.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const expiryFilters = cookie.load(
            StringConstants.COOKIES.EXPIRY_REPORT_SCREEN
          );
          this.setState({
            activeTab: expiryFilters ? expiryFilters.tab.toString() : '0',
            loading: false,
            data: response.data,
          });
        } else throw response;
      })
      .catch((err) => {
        console.log('*****', err);
        this.setState({ loading: false });
        message.error("Couldn't get Expiring product list, please try later");
      });
  };

  navigateToInventoryDetail = (id) => {
    const url = getUiUrlWithPathParams(RouteConstants.INVENTORY_DETAILS, {
      lotno: id,
    });
    this.props.history.push(url);
  };

  handleTabChange = (activeTabIndex) => {
    this.setState({ activeTab: activeTabIndex });
    cookie.save(StringConstants.COOKIES.EXPIRY_REPORT_SCREEN, {
      tab: activeTabIndex,
    });
  };

  render() {
    const { loading, data, activeTab } = this.state;
    if (loading) return <LoadingComponent tip='Fetching all expiring lots' />;

    return (
      <div>
        <Helmet>
          <title>HMS - Expiring Lots Report</title>
          <meta name='description' content='List of lots grouped by expiry' />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Expiring Inventory Report
            </Typography.Text>
          </Col>
          <Col xs={24} className='space-top-10'>
            {data.length > 0 ? (
              <Tabs
                activeKey={activeTab}
                animated={true}
                destroyInactiveTabPane={true}
                onChange={this.handleTabChange}
              >
                {data.map((expirationRange, index) => {
                  return (
                    <Tabs.TabPane
                      tab={
                        <Badge
                          offset={[10, -5]}
                          style={{ fontSize: 11 }}
                          count={expirationRange.inventory_list.length}
                          overflowCount={99}
                        >
                          {expirationRange.display_text}
                        </Badge>
                      }
                      key={index}
                    >
                      <Typography.Text type='secondary'>
                        {expirationRange.description}
                      </Typography.Text>
                      <ExpiringLotsTable
                        expirationText={expirationRange.display_text}
                        data={expirationRange.inventory_list}
                        activeTabIndex={index}
                        emptyText='No Lots Expiring'
                        handleViewDetails={this.navigateToInventoryDetail}
                      />
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            ) : (
              <Empty description='No Data Available' />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(ExpiringLotsReport));
