import './header/header.css';

import { Badge, Button, Drawer } from 'antd';
import React, { Component } from 'react';

import LeftMenu from './header/LeftMenu';
import { Link } from 'react-router-dom';
import RightMenu from './header/RightMenu';
import RouteConstants from '../../constants/RouteConstants';
import { isAuthenticatedUser } from '../../utils/AuthUtils';
import { withRouter } from 'react-router';

const locationsNotToHaveHeaders = [
  RouteConstants.HOME,
  RouteConstants.REDIRECT,
  RouteConstants.MAINTENANCE_PAGE,
];

class HeaderComponent extends Component {
  state = {
    current: '',
    visible: false,
    showHeader: false,
    env: '',
  };

  componentDidMount() {
    const { location } = this.props;
    this.updateHeaderVisibility(location);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location)
      this.updateHeaderVisibility(this.props.location);
  }

  updateHeaderVisibility = (location) => {
    if (isAuthenticatedUser()) {
      if (!locationsNotToHaveHeaders.includes(location.pathname))
        !this.state.showHeader && this.setState({ showHeader: true });

      let paths = location.pathname.split(/\//);
      let current = paths[0];
      if (paths.length > 2) {
        current = paths[1] + '-' + paths[2];
      } else if (paths.length > 1) {
        current = paths[1];
      }
      current !== this.state.current && this.setState({ current });
      const env =
        process.env.REACT_APP_ENV !== 'production'
          ? process.env.REACT_APP_ENV
          : '';
      this.setState({ env });
    } else {
      this.state.showHeader && this.setState({ showHeader: false });
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { showHeader, visible, current, env } = this.state;
    if (!showHeader) return null;
    return (
      <nav className='menuBar'>
        <div className='logo'>
          <Badge
            count={env}
            style={{
              top: 15,
              right: 40,
            }}
          >
            <Link to={RouteConstants.WATCHLIST_INVENTORY}>
              Holds Management
            </Link>
          </Badge>
        </div>
        <div className='menuCon'>
          <div className='leftMenu'>
            <LeftMenu current={current} />
          </div>
          <div className='rightMenu'>
            <RightMenu current={current} />
          </div>
          <Button className='barsMenu' type='primary' onClick={this.showDrawer}>
            <span className='barsBtn'></span>
          </Button>
          <Drawer
            title='Menu'
            placement='right'
            closable={true}
            onClose={this.onClose}
            visible={visible}
            width={300}
          >
            <LeftMenu mobileMenu={true} current={current} />
            <RightMenu mobileMenu={true} current={current} />
          </Drawer>
        </div>
      </nav>
    );
  }
}

export default withRouter(HeaderComponent);
