import { Col, Row, Typography, message } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import LoadingComponent from '../app/components/Loading';
import RouteConstants from '../constants/RouteConstants';
import SecureComponent from '../app/components/SecureComponent';
import StringConstants from '../constants/StringConstants';
import UrlConstants from '../api/UrlConstants';
import WatchedPoTable from './watchlistPo/WatchedPoTable';
import { getUiUrlWithPathParams } from '../api/UrlGenerator';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { reject } from 'lodash';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class WatchlistPO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inventoryList: [],
      holdTypeList: [],
    };
  }

  componentDidMount = () => {
    this.fetchPoWatchlist();
  };

  fetchPoWatchlist = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_WATCHED_PO.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            inventoryList: reject(response.data.po_list, {
              status: StringConstants.MOVED_STATUS,
            }),
            holdTypeList: response.data.hold_type_list,
          });
        } else throw response;
      })
      .catch((err) => {
        console.log('*****', err);
        this.setState({ loading: false });
        message.error("Couldn't get purchase orders, please try later");
      });
  };

  navigateToPurchaseOrderDetail = (id) => {
    const url = getUiUrlWithPathParams(RouteConstants.PO_DETAILS, {
      id: id,
    });
    this.props.history.push(url);
  };

  render() {
    const { loading, inventoryList, holdTypeList } = this.state;

    if (loading)
      return <LoadingComponent tip='Fetching watched purchase orders' />;
    return (
      <div>
        <Helmet>
          <title>HMS - Watched P.O.</title>
          <meta
            name='description'
            content='List of purchase orders being watched'
          />
        </Helmet>
        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Purchase Orders Watchlist
            </Typography.Text>
          </Col>
          <Col xs={24}>
            <Row className='space-top'>
              <Col xs={24}>
                <WatchedPoTable
                  data={inventoryList}
                  handleViewDetails={this.navigateToPurchaseOrderDetail}
                  loading={loading}
                  permissions={this.props.rootStore.userStore.permissions}
                  reloadData={this.fetchPoWatchlist}
                  holdTypes={holdTypeList}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(WatchlistPO), []);
