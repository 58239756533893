/* eslint-disable array-callback-return */
import { Button, Col, Row, Select, Space } from 'antd';
import React, { Component } from 'react';
import { filter, isEmpty, isUndefined, sortBy, trim } from 'lodash';

class LotFilterCommponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: undefined,
      fin: undefined,
      lotCode: undefined,
      holdType: undefined,
      description: undefined,
    };
  }

  prefillFilters = (data) => {
    this.setState({
      location: data.location,
      fin: data.fin,
      lotCode: data.lot_code,
      description: data.description,
    });
  };

  resetFilters = () => {
    this.setState({
      location: undefined,
      fin: undefined,
      lotCode: undefined,
      holdType: undefined,
      description: undefined,
    });
    this.props.onReset();
  };

  applyFilter = () => {
    let { location, fin, lotCode, holdType, description } = this.state;
    this.props.onSubmit({
      location,
      fin,
      lot_code: lotCode,
      holdType,
      description,
    });
  };

  canFilterValueBeDisplayed = (item) => {
    const { location, fin } = this.state;
    if (!isEmpty(location) && location !== item.location) return false;
    else if (!isEmpty(fin) && fin !== item.fin) return false;
    return true;
  };

  canDescriptionBeDisplayed = (key) => {
    const { descriptions } = this.props;
    const { location, fin, lotCode } = this.state;
    const filter_ = {};
    if (!isEmpty(location)) filter_.location = location;
    if (!isEmpty(fin)) filter_.fin = fin;
    if (!isEmpty(lotCode)) filter_.lotCode = lotCode;
    return filter(descriptions[key], filter_).length > 0;
  };

  render() {
    const { locations, itemNumbers, lotCodes, holdTypes, descriptions } =
      this.props;
    const { location, fin, lotCode, holdType, description } = this.state;
    return (
      <Row>
        <Col xs={0} sm={24}>
          <Space>
            <Select
              showSearch
              value={location}
              style={{ width: 150 }}
              placeholder=' Location'
              onChange={(value) => this.setState({ location: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {locations.sort().map((location, index) => {
                return (
                  <Select.Option value={location} key={index}>
                    {location}
                  </Select.Option>
                );
              })}
            </Select>
            <Select
              showSearch
              value={fin}
              style={{ width: 150 }}
              placeholder=' FIN'
              onChange={(value) => this.setState({ fin: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {Object.keys(itemNumbers)
                .sort()
                .map((fin, index) => {
                  if (isEmpty(location) || itemNumbers[fin].includes(location))
                    return (
                      <Select.Option value={fin} key={index}>
                        {fin}
                      </Select.Option>
                    );
                })}
            </Select>
            <Select
              showSearch
              value={lotCode}
              style={{ width: 150 }}
              placeholder='Lot'
              onChange={(value) => this.setState({ lotCode: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {sortBy(lotCodes, 'lotCode').map((item, index) => {
                if (this.canFilterValueBeDisplayed(item))
                  return (
                    <Select.Option value={item.lotCode} key={index}>
                      {item.lotCode}
                    </Select.Option>
                  );
              })}
            </Select>
            {!isEmpty(descriptions) && (
              <Select
                showSearch
                value={description}
                style={{ width: 180 }}
                placeholder='Description'
                onChange={(value) => {
                  this.setState({ description: value });
                }}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(trim(input.toLowerCase())) >= 0
                }
              >
                {Object.keys(descriptions)
                  .sort()
                  .map((key, index) => {
                    if (this.canDescriptionBeDisplayed(key))
                      return (
                        <Select.Option value={key} key={index} title={key}>
                          {key}
                        </Select.Option>
                      );
                  })}
              </Select>
            )}
            {!isEmpty(holdTypes) && (
              <Select
                showSearch
                value={holdType}
                style={{ width: 150 }}
                placeholder='Hold Type'
                onChange={(value) => {
                  this.setState({ holdType: value });
                }}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(trim(input.toLowerCase())) >= 0
                }
              >
                {sortBy(holdTypes, 'name').map((holdType_, index) => {
                  return (
                    <Select.Option value={holdType_.id} key={index}>
                      {holdType_.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}

            <Button
              type='primary'
              disabled={
                isEmpty(location) &&
                isEmpty(fin) &&
                isEmpty(lotCode) &&
                isUndefined(holdType) &&
                isEmpty(description)
              }
              onClick={this.applyFilter}
            >
              Apply Filter
            </Button>
          </Space>
          <Button
            type='link'
            danger
            disabled={
              isEmpty(location) &&
              isEmpty(fin) &&
              isEmpty(lotCode) &&
              isUndefined(holdType) &&
              isEmpty(description)
            }
            onClick={this.resetFilters}
          >
            Reset
          </Button>
        </Col>
      </Row>
    );
  }
}

export default LotFilterCommponent;
