import { Column, Row } from 'simple-flexbox';
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Spin } from 'antd';

class LoadingComponent extends Component {
  static propTypes = {
    tip: PropTypes.string,
  };

  render() {
    return (
      <Row vertical='center' horizontal='center' className='full-height'>
        <Column>
          <Spin
            size='large'
            tip={this.props.tip ? this.props.tip : 'Preparing holds...'}
          ></Spin>
        </Column>
      </Row>
    );
  }
}

export default LoadingComponent;
