import {
  Button,
  Col,
  Descriptions,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import React, { Component } from 'react';
import { isEmpty, remove } from 'lodash';

import AddorEditUsers from './adminUsers/AddorEditUsers';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import RoleConstants from '../constants/RoleConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

class AdminRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalLoading: false,
      modalVisible: false,
      users: [],
      selectedUser: {},
    };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.ADMIN_GET_ALL_USERS.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const users_ = response.data;
          users_.map((r) => (r.rolesId = r.roles.map((p) => p.id)));
          remove(users_, { email: 'holds@fishinco.com' });
          this.setState({ users: users_, loading: false });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ users: [], loading: false });
      });
  };

  confirmUserDeletion = (userId, userEmail) => {
    Modal.confirm({
      title: 'Confirm User Deletion',
      icon: <WarningOutlined />,
      content: (
        <div>
          <Typography.Text type='secondary'>
            {`Are you sure you want to delete this user (${userEmail})?`}
          </Typography.Text>
        </div>
      ),
      okText: 'Yes, Delete',
      okButtonProps: { danger: true },
      cancelText: 'Cancel',
      onOk: () => this.deleteUser(userId),
    });
  };

  deleteUser = (id) => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.ADMIN_DELETE_USER.USECASE,
      HttpConstants.DELETE_METHOD,
      {
        $id: id,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          message.success('User deleted successfully');
          this.fetchUsers();
        } else throw response;
      })
      .catch((err) => {
        message.error(
          'Cannot remove user at the moment, please try again later'
        );
        this.setState({ loading: false });
      });
  };

  getColumnsForTable = () => {
    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: 'E-mail',
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        key: 'roles',
        dataIndex: 'roles',
        title: 'Roles',
        render: (roles) => {
          return roles.map((role, index) => {
            return <Tag key={index}>{role.name}</Tag>;
          });
        },
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({ modalVisible: true, selectedUser: data })
                }
              >
                <EditOutlined /> Update User
              </Button>
              <Tooltip title='Delete User'>
                <Button
                  shape='circle'
                  type='primary'
                  danger
                  onClick={() => this.confirmUserDeletion(data.id, data.email)}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  this.setState({ modalVisible: true, selectedUser: data })
                }
              >
                <EditOutlined /> Update
              </Button>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <Descriptions bordered>
        <Descriptions.Item label='E-mail'>{record.email}</Descriptions.Item>
        <Descriptions.Item label='Roles'>
          {record.roles.map((role, index) => {
            return <Tag key={index}>{role.name}</Tag>;
          })}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  handleCancel = () => {
    this.setState({
      modalLoading: false,
      modalVisible: false,
      selectedUser: {},
    });
  };

  handleSubmit = (data) => {
    const { selectedUser } = this.state;
    const params = {
      name: data.name,
      email: data.email,
      roles: data.rolesId,
    };
    this.setState({ modalLoading: true });
    let urlUsecase = UrlConstants.ADMIN_ADD_USER.USECASE;
    let httpCallType = HttpConstants.POST_METHOD;
    if (!isEmpty(selectedUser)) {
      params.id = selectedUser.id;
      urlUsecase = UrlConstants.ADMIN_UPDATE_USER.USECASE;
      httpCallType = HttpConstants.PUT_METHOD;
    }
    makeApiCallWithAuthentication(urlUsecase, httpCallType, params)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            modalLoading: false,
            modalVisible: false,
            selectedUser: {},
          });
          this.fetchUsers();
        } else throw response;
      })
      .catch((err) => {
        message.error('Request failed, please try later');
        this.setState({ modalLoading: false });
      });
  };

  render() {
    const { loading, users, modalLoading, modalVisible, selectedUser } =
      this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Users</title>
          <meta name='description' content='Manage Users of HMS' />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              All Users
            </Typography.Text>
          </Col>
          <Col xs={24} style={{ textAlign: 'end' }} className='space-top'>
            <Button
              type='primary'
              onClick={() => {
                this.setState({ modalVisible: true });
              }}
              disabled={modalVisible}
            >
              <UserAddOutlined />
              Add new user
            </Button>
          </Col>
          <Col xs={0} md={24} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={users}
              loading={loading}
              columns={this.getColumnsForTable()}
              locale={{
                emptyText: 'No Users Available',
              }}
              rowKey={(record) => record.id}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} users`,
              }}
            />
          </Col>
          <Col xs={24} md={0} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={users}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Users Available',
              }}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} users`,
              }}
            />
          </Col>
          <AddorEditUsers
            visible={modalVisible}
            loading={modalLoading}
            selectedData={selectedUser}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(AdminRoles), [
  RoleConstants.SUPER_USER,
]);
