import { Card, Col, Row, Statistic } from 'antd';
import React, { Component } from 'react';

import formatNumber from '../../utils/numberUtils';

class StatisticsCard extends Component {
  render() {
    const {
      title,
      count,
      quantity,
      poStats = false,
      formatNumbers = true,
    } = this.props;
    return (
      <Card>
        <Card.Meta title={title} />
        <Row className='space-top' gutter={[8, 16]}>
          <Col xs={24} sm={12}>
            <Statistic
              title={!poStats ? 'No. of Lots' : 'No. of POs'}
              value={count}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Statistic
              title='No. of Cases'
              value={formatNumbers ? formatNumber(quantity) : quantity}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

export default StatisticsCard;
