import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import InventoryDetailsView from './shared/InventoryDetailsView';
import LoadingComponent from '../app/components/Loading';
import { Result } from 'antd';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class InventoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalLoading: false,
      holdManagementModalVisible: false,
      inventoryId: null,
      inventoryDetails: {},
      holdTypes: [],
    };
  }

  componentDidMount = () => {
    const inventoryId = this.props.match.params.lotno;
    this.setState({ inventoryId }, () => {
      this.fetchInventoryDetails();
    });
  };

  fetchInventoryDetails = () => {
    const { inventoryId } = this.state;
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_INVENTORY_DETAILS.USECASE,
      HttpConstants.GET_METHOD,
      { $id: inventoryId }
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            inventoryDetails: response.data.inventory,
            holdTypes: response.data.hold_type_list,
          });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ loading: false, inventoryDetails: {} });
      });
  };

  toggleWatchFlag = () => {
    this.setState((prevState) => ({
      inventoryDetails: {
        ...prevState.inventoryDetails,
        is_watched: !prevState.inventoryDetails.is_watched,
      },
    }));
  };

  render() {
    const { loading, inventoryDetails, holdTypes } = this.state;
    if (loading) return <LoadingComponent tip='Loading Inventory Detail' />;
    if (inventoryDetails)
      return (
        <>
          <Helmet>
            <title>
              HMS - Lot {inventoryDetails && inventoryDetails.lot_code} Details
            </title>
            <meta
              name='description'
              content={`Lot - ${inventoryDetails.lot_code} - ${inventoryDetails.description}`}
            />
          </Helmet>
          <InventoryDetailsView
            inventoryDetails={inventoryDetails}
            holdTypes={holdTypes}
            goBack={() => window.history.back()}
            refreshData={this.fetchInventoryDetails}
            toggleWatchFlag={this.toggleWatchFlag}
          />
        </>
      );
    else
      return (
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the inventory you are looking for does not exist.'
        />
      );
  }
}

export default SecureComponent(withRouter(InventoryDetail));
