import {
  Button,
  Col,
  Descriptions,
  Dropdown,
  Menu,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { isArray, isEmpty } from 'lodash';

import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import RoleConstants from '../constants/RoleConstants';
import SecureComponent from '../app/components/SecureComponent';
import StringConstants from '../constants/StringConstants';
import UpdateLocationEmailModal from './adminLocations/UpdateLocationEmailModal';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

class AdminLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalLoading: false,
      modalVisible: false,
      locations: [],
      selectedLocation: {},
      selectedEmails: [],
      updateUsecase: null,
    };
  }

  componentDidMount() {
    this.fetchLocations();
  }

  fetchLocations = () => {
    this.setState({ loading: true });

    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_LOCATIONS.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ locations: response.data, loading: false });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ locations: [], loading: false });
      });
  };

  getColumnsForTable = () => {
    const columns = [
      {
        key: 'code',
        dataIndex: 'code',
        title: 'Location',
        sorter: (a, b) => a.code.localeCompare(b.code),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        key: 'warehouse_contact_email',
        dataIndex: 'warehouse_contact_email',
        title: (
          <Typography.Text>
            <Tooltip
              placement='topLeft'
              title='E-mail IDs to send Holds informations'
            >
              <QuestionCircleOutlined />{' '}
            </Tooltip>
            Warehouse Contact E-mail
          </Typography.Text>
        ),

        ellipsis: {
          showTitle: false,
        },
        render: (warehouse_contact_email) => (
          <Tooltip placement='topLeft' title={warehouse_contact_email}>
            {warehouse_contact_email}
          </Tooltip>
        ),
      },
      {
        key: 'use_warehouse_email_for_reports',
        dataIndex: 'use_warehouse_email_for_reports',
        title: (
          <Typography.Text>
            <Tooltip
              placement='topLeft'
              title='If Yes, send inbound containers reports to warehouse contact emails'
            >
              <QuestionCircleOutlined />{' '}
            </Tooltip>
            Send reports to contact emails
          </Typography.Text>
        ),
        render: (use_warehouse_email_for_reports) => {
          if (use_warehouse_email_for_reports) return 'Yes';
          return 'No';
        },
      },
      {
        key: 'container_report_email',
        dataIndex: 'container_report_email',
        title: (
          <Typography.Text>
            <Tooltip
              placement='topLeft'
              title='E-mail IDs to send inbound container reports'
            >
              <QuestionCircleOutlined />{' '}
            </Tooltip>
            Inbound Reports To
          </Typography.Text>
        ),

        ellipsis: {
          showTitle: false,
        },
        render: (container_report_email) =>
          container_report_email && (
            <Tooltip placement='topLeft' title={container_report_email}>
              {container_report_email}
            </Tooltip>
          ),
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          const menu = (
            <Menu
              onClick={({ key }) => {
                this.setState({
                  modalVisible: true,
                  updateUsecase: key,
                  selectedLocation: data,
                  selectedEmails:
                    key === StringConstants.LOCATION_HOLDS_ACTIVITY
                      ? data.warehouse_contact_email
                      : data.container_report_email,
                });
              }}
            >
              <Menu.Item key={StringConstants.LOCATION_HOLDS_ACTIVITY}>
                Warehouse E-mail
              </Menu.Item>
              <Menu.Item key={StringConstants.LOCATION_PO_REPORTS}>
                Reports E-mail
              </Menu.Item>
            </Menu>
          );
          return (
            <Space>
              <Dropdown overlay={menu}>
                <Button type='primary'>
                  <EditOutlined /> Update E-mail IDs
                </Button>
              </Dropdown>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'code',
        dataIndex: 'code',
        title: 'Location',
        sorter: (a, b) => a.code.localeCompare(b.code),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          const menu = (
            <Menu
              onClick={({ key }) => {
                this.setState({
                  modalVisible: true,
                  updateUsecase: key,
                  selectedLocation: data,
                  selectedEmails:
                    key === StringConstants.LOCATION_HOLDS_ACTIVITY
                      ? data.warehouse_contact_email
                      : data.container_report_email,
                });
              }}
            >
              <Menu.Item key={StringConstants.LOCATION_HOLDS_ACTIVITY}>
                Warehouse E-mail
              </Menu.Item>
              <Menu.Item key={StringConstants.LOCATION_PO_REPORTS}>
                Reports E-mail
              </Menu.Item>
            </Menu>
          );
          return (
            <Space>
              <Dropdown overlay={menu}>
                <Button type='primary'>
                  <EditOutlined /> Update
                </Button>
              </Dropdown>
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <Descriptions bordered>
        <Descriptions.Item label='Name'>{record.name}</Descriptions.Item>
        <Descriptions.Item label='Warehouse Contact'>
          {record.warehouse_contact_email}
        </Descriptions.Item>
        <Descriptions.Item label='Send reports to contact emails'>
          {record.use_warehouse_email_for_report ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label='Inbound Reports To'>
          {record.container_report_email}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  handleCancel = () => {
    this.setState({
      modalLoading: false,
      modalVisible: false,
      selectedLocation: {},
      selectedEmails: [],
      updateUsecase: null,
    });
  };

  handleUpdate = (isContactEmailForReports) => {
    const { selectedLocation, selectedEmails, updateUsecase } = this.state;
    let apiUsecase = UrlConstants.UPDATE_LOCATION_CONTACT_MAIL.USECASE;
    let params = {
      code: selectedLocation.code,
    };
    if (updateUsecase === StringConstants.LOCATION_HOLDS_ACTIVITY) {
      params.warehouse_contact_email = isArray(selectedEmails)
        ? selectedEmails.join()
        : selectedEmails;
    } else if (updateUsecase) {
      apiUsecase = UrlConstants.UPDATE_LOCATION_CONTAINER_REPORT_MAIL.USECASE;
      params.use_warehouse_email_for_reports = isContactEmailForReports;
      if (isContactEmailForReports) params.container_report_email = [];
      else
        params.container_report_email = isArray(selectedEmails)
          ? selectedEmails.join()
          : selectedEmails;
    }

    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      apiUsecase,
      HttpConstants.PATCH_METHOD,
      params
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            modalLoading: false,
            modalVisible: false,
            selectedLocation: {},
            selectedEmails: [],
            updateUsecase: null,
          });
          this.fetchLocations();
        } else throw response;
      })
      .catch((err) => {
        message.error('E-mail update failed');
        this.setState({ modalLoading: false });
      });
  };

  updateEmails = (emails) => {
    if (!isEmpty(this.state.selectedLocation)) {
      this.setState({ selectedEmails: emails });
    }
  };

  render() {
    const {
      loading,
      locations,
      modalLoading,
      modalVisible,
      selectedLocation,
      selectedEmails,
      updateUsecase,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Locations</title>
          <meta name='description' content='Manage Warehouse Locations' />
        </Helmet>

        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              All Locations
            </Typography.Text>
          </Col>
          <Col xs={0} md={24} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={locations}
              loading={loading}
              columns={this.getColumnsForTable()}
              locale={{
                emptyText: 'No Locations Available',
              }}
              rowKey={(record) => record.code}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} locations`,
              }}
            />
          </Col>
          <Col xs={24} md={0} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={locations}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Locations Available',
              }}
              rowKey={(record) => record.code}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} locations`,
              }}
            />
          </Col>
          <UpdateLocationEmailModal
            visible={modalVisible}
            loading={modalLoading}
            usecase={updateUsecase}
            selectedLocation={selectedLocation}
            selectedEmails={selectedEmails}
            isContactEmailForReports={
              selectedLocation.use_warehouse_email_for_reports
            }
            onSubmit={this.handleUpdate}
            onCancel={this.handleCancel}
            updateEmails={this.updateEmails}
          />
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(AdminLocations), [
  RoleConstants.SUPER_USER,
]);
