import React, { Component } from 'react';

import { Layout } from 'antd';

const { Footer } = Layout;
class FooterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <Footer className='footer'>The Fishin' Company © 2020</Footer>;
  }
}

export default FooterComponent;
