import { Tag, Typography } from 'antd';
import { hashCode, intToRGB } from './colorUtils';

import React from 'react';
import StringConstants from '../constants/StringConstants';
import { toUpper } from 'lodash';

export default function generateRuleStringForAutoHold(data) {
  if (typeof data.holds === 'string') {
    data.holds = data.holds.split(',');
  }

  const match =
    StringConstants.AUTO_HOLDS.MATCH_STRINGS[toUpper(data.match)] ||
    'Yet to select match';
  const secondary_match =
    StringConstants.AUTO_HOLDS.MATCH_STRINGS[toUpper(data.secondary_match)] ||
    'Yet to select match';
  return (
    <div>
      When <Typography.Text strong>{data.attribute}</Typography.Text>{' '}
      <Typography.Text underline>{match}</Typography.Text>{' '}
      {data.value ? (
        <Typography.Text strong>{data.value}</Typography.Text>
      ) : (
        <Typography.Text type='warning'>Yet to select value</Typography.Text>
      )}
      {data.secondary_attribute && (
        <>
          {' '}
          <Typography.Text>and</Typography.Text>{' '}
          <Typography.Text strong>{data.secondary_attribute}</Typography.Text>{' '}
          <Typography.Text underline>{secondary_match}</Typography.Text>{' '}
          {data.secondary_value ? (
            <Typography.Text strong>{data.secondary_value}</Typography.Text>
          ) : (
            <Typography.Text type='warning'>
              Yet to select value
            </Typography.Text>
          )}
        </>
      )}
      , automatically add{' '}
      {data.holds && data.holds.length > 0 ? (
        data.holds.sort().map((hold) => (
          <Tag color={intToRGB(hashCode(hold))} key={hold}>
            {hold}
          </Tag>
        ))
      ) : (
        <>
          <Typography.Text type='warning'> Yet to select holds</Typography.Text>{' '}
        </>
      )}
      holds for the item
    </div>
  );
}
