import React, { Component, useCallback, useEffect, useState } from 'react';
import {
  getTokenForBrowser,
  isInternalHMSUser,
  saveToken,
  verifyToken,
} from '../utils/auth/auth';
import { inject, observer } from 'mobx-react';

import HttpConstants from '../constants/HttpConstants';
import LoadingComponent from '../app/components/Loading';
import Paths from '../constants/RouteConstants';
import RouteConstants from '../constants/RouteConstants';
import UrlConstants from '../api/UrlConstants';
import { isEmpty } from 'lodash';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { message } from 'antd';
import { parseHash } from '../utils/auth/auth0';
import { useMsal } from '@azure/msal-react';
import { withRouter } from 'react-router';

const RedirectScreen = (props) => {
  const { instance } = useMsal();

  const setRoute = useCallback(
    async (path) => {
      if (path !== RouteConstants.LOGIN) {
        const response = await makeApiCallWithAuthentication(
          UrlConstants.GET_USER_DETAILS.USECASE,
          HttpConstants.GET_METHOD
        );
        if (response.status === 200 && !isEmpty(response.data)) {
          const userDetails = response.data;
          props.rootStore.userStore.setUserDetails(userDetails);
          if (
            userDetails.under_maintenance &&
            userDetails.under_maintenance === 'true'
          ) {
            props.history.push(RouteConstants.MAINTENANCE_PAGE);
            return;
          }
          // Call Hold Types Users API when not in maintenance
          else {
            const response = await makeApiCallWithAuthentication(
              UrlConstants.USERS_BY_HOLD_TYPE.USECASE,
              HttpConstants.GET_METHOD
            );
            if (response.status === 200) {
              const holdTypeUsers = response.data;
              props.rootStore.holdTypesUsersStore.setHoldTypesUsers(
                holdTypeUsers
              );
            }
          }
        } else {
          message.error('Cannot find user, please try later');
          return;
        }
      }
      if (path === RouteConstants.MAINTENANCE_PAGE)
        path = RouteConstants.WATCHLIST_INVENTORY;
      props.history.push(path);
    },
    [
      props.history,
      props.rootStore.holdTypesUsersStore,
      props.rootStore.userStore,
    ]
  );

  useEffect(() => {
    const getJWTToken = async () => {
      let tokenResponse = await instance.acquireTokenSilent({
        account: instance.getActiveAccount(),
      });

      saveToken(tokenResponse.idToken);
      if (isInternalHMSUser(tokenResponse.idToken))
        setRoute(Paths.WATCHLIST_INVENTORY);
    };
    getJWTToken();
  }, [instance, setRoute]);

  const getParamsFromUrl = (urlSearchParams, param) => {
    return new URLSearchParams(urlSearchParams).get(param);
  };

  useEffect(() => {
    parseHash(async (err, result) => {
      if (result == null) {
        const token = await getTokenForBrowser();
        if (!token) {
          setRoute(RouteConstants.LOGIN);
          return;
        } else {
          let redirectionRoute = RouteConstants.WATCHLIST_INVENTORY;
          if (props.location.search) {
            const route = getParamsFromUrl(props.location.search, 'redirect');
            if (route) redirectionRoute = route;
          }
          setRoute(redirectionRoute);
          return;
        }
      }
      if (err) {
        console.error('Error signing in', err);
        setRoute(RouteConstants.LOGIN);
        return;
      }
      verifyToken(result.idToken).then(async (valid) => {
        if (valid) {
          saveToken(result.idToken, result.accessToken);
          setRoute(RouteConstants.WATCHLIST_INVENTORY);
          return;
        } else {
          setRoute(RouteConstants.LOGIN);
          return;
        }
      });
    });
  }, [props.location.search, setRoute]);

  return (
    <div className='full-height'>
      <LoadingComponent tip='Logging you in...'></LoadingComponent>
    </div>
  );
};

export default withRouter(inject('rootStore')(observer(RedirectScreen)));
