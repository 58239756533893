/* eslint-disable array-callback-return */
import { Button, Col, Row, Select, Space } from 'antd';
import React, { Component } from 'react';
import { findIndex, isEmpty, isUndefined, sortBy, trim } from 'lodash';

class POFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: undefined,
      po: undefined,
      fin: undefined,
      vendor: undefined,
      container: undefined,
      holdType: undefined,
    };
  }

  resetFilters = () => {
    this.setState({
      location: undefined,
      po: undefined,
      fin: undefined,
      vendor: undefined,
      container: undefined,
      holdType: undefined,
    });
    this.props.onReset();
  };

  applyFilter = () => {
    let { location, po, fin, vendor, container, holdType } = this.state;
    this.props.onSubmit({
      location,
      po,
      fin,
      vendor,
      container,
      holdType,
    });
  };

  canFinBeDisplayed = (fin) => {
    const { fins } = this.props;
    const { location, po } = this.state;
    if (!isEmpty(location) && findIndex(fins[fin], { location }) === -1)
      return false;
    else if (!isEmpty(po) && findIndex(fins[fin], { po_number: po }) === -1)
      return false;
    return true;
  };

  canVendorBeDisplayed = (vendorName) => {
    const { vendors } = this.props;
    const { location, po, fin } = this.state;
    if (
      !isEmpty(location) &&
      findIndex(vendors[vendorName], { location }) === -1
    )
      return false;
    else if (
      !isEmpty(po) &&
      findIndex(vendors[vendorName], { po_number: po }) === -1
    )
      return false;
    else if (!isEmpty(fin) && findIndex(vendors[vendorName], { fin }) === -1)
      return false;
    return true;
  };

  canContainerBeDisplayed = (containerNo) => {
    const { containers } = this.props;
    const { location, po, fin, vendor } = this.state;
    if (
      !isEmpty(location) &&
      findIndex(containers[containerNo], { location }) === -1
    )
      return false;
    else if (
      !isEmpty(po) &&
      findIndex(containers[containerNo], { po_number: po }) === -1
    )
      return false;
    else if (
      !isEmpty(fin) &&
      findIndex(containers[containerNo], { fin }) === -1
    )
      return false;
    else if (
      !isEmpty(vendor) &&
      findIndex(containers[containerNo], { vendor }) === -1
    )
      return false;
    return true;
  };

  render() {
    const { locations, pos, fins, vendors, containers, holdTypes } = this.props;
    const { location, po, fin, vendor, container, holdType } = this.state;

    return (
      <Row>
        <Col xs={0} sm={24}>
          <Space>
            <Select
              showSearch
              value={location}
              style={{ width: 150 }}
              placeholder='Location'
              onChange={(value) => this.setState({ location: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {locations.sort().map(
                (l, index) =>
                  l && (
                    <Select.Option value={l} key={index}>
                      {l}
                    </Select.Option>
                  )
              )}
            </Select>
            <Select
              showSearch
              value={po}
              style={{ width: 150 }}
              placeholder='PO'
              onChange={(value) => this.setState({ po: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {Object.keys(pos)
                .sort()
                .map((po, index) => {
                  if (isEmpty(location) || pos[po].includes(location))
                    return (
                      <Select.Option value={po} key={index}>
                        {po}
                      </Select.Option>
                    );
                })}
            </Select>
            <Select
              showSearch
              value={fin}
              style={{ width: 150 }}
              placeholder='FIN'
              onChange={(value) => this.setState({ fin: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {Object.keys(fins)
                .sort()
                .map((fin, index) => {
                  if (this.canFinBeDisplayed(fin))
                    return (
                      <Select.Option value={fin} key={index}>
                        {fin}
                      </Select.Option>
                    );
                })}
            </Select>
            <Select
              showSearch
              value={vendor}
              style={{ width: 150 }}
              placeholder='Vendor'
              onChange={(value) => this.setState({ vendor: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {Object.keys(vendors)
                .sort()
                .map((vendor, index) => {
                  if (this.canVendorBeDisplayed(vendor))
                    return (
                      <Select.Option value={vendor} key={index}>
                        {vendor}
                      </Select.Option>
                    );
                })}
            </Select>
            <Select
              showSearch
              value={container}
              style={{ width: 150 }}
              placeholder='Container'
              onChange={(value) => this.setState({ container: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {Object.keys(containers)
                .sort()
                .map((container, index) => {
                  if (this.canContainerBeDisplayed(container))
                    return (
                      <Select.Option value={container} key={index}>
                        {container}
                      </Select.Option>
                    );
                })}
            </Select>

            {!isEmpty(holdTypes) && (
              <Select
                showSearch
                value={holdType}
                style={{ width: 150 }}
                placeholder='Select Hold Type'
                onChange={(value) => {
                  this.setState({ holdType: value });
                }}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(trim(input.toLowerCase())) >= 0
                }
              >
                {sortBy(holdTypes, 'name').map((holdType_, index) => {
                  return (
                    <Select.Option value={holdType_.id} key={index}>
                      {holdType_.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}

            <Button
              type='primary'
              disabled={
                isEmpty(location) &&
                isEmpty(po) &&
                isEmpty(fin) &&
                isEmpty(vendor) &&
                isEmpty(container) &&
                isUndefined(holdType)
              }
              onClick={this.applyFilter}
            >
              Apply Filter
            </Button>
          </Space>
          <Button
            type='link'
            danger
            disabled={
              isEmpty(location) &&
              isEmpty(po) &&
              isEmpty(fin) &&
              isEmpty(vendor) &&
              isEmpty(container) &&
              isUndefined(holdType)
            }
            onClick={this.resetFilters}
          >
            Reset
          </Button>
        </Col>
      </Row>
    );
  }
}

export default POFilterComponent;
