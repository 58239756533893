import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Switch,
  Tag,
  Typography,
  message,
} from 'antd';
import React, { Component } from 'react';
import {
  chain,
  difference,
  forEach,
  isEmpty,
  isEqual,
  isUndefined,
  upperCase,
} from 'lodash';

import StringConstants from '../../constants/StringConstants';
import { getInventoryStatusColor } from '../../utils/colorUtils';

class HoldUnholdModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryHolds: [],
    };
  }

  getInventoryHolds = () => {
    const { inventory, poModal } = this.props;
    if (poModal) {
      const inventoryHolds = chain(inventory.po_holds)
        .pickBy((a) => a.is_active)
        .map('hold_type')
        .map('name')
        .value();
      return inventoryHolds;
    } else {
      const inventoryHolds = chain(inventory.inventory_holds)
        .pickBy((a) => a.is_active)
        .map('hold_type')
        .map('name')
        .value();
      return inventoryHolds;
    }
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.holdFormRef
      .validateFields()
      .then((data) => {
        // this.props.onSubmit(this.props.inventory, data);
        const inventoryHolds = this.getInventoryHolds();
        if (isEqual(data.hold_types, inventoryHolds)) {
          const { inventory } = this.props;

          if (isEqual(data.watch, inventory.is_watched)) {
            message.warn('No changes made');
            return;
          } else {
            // Submit only to watch/unwatch inventory
            this.props.onWatchUpdate(this.props.inventory, data.watch);
            return;
          }
        }
        const addedHolds = difference(data.hold_types, inventoryHolds);
        const removedHolds = difference(inventoryHolds, data.hold_types);

        this.props.onSubmit(
          this.props.inventory,
          addedHolds,
          removedHolds,
          data.comment,
          data.watch
        );
      })
      .catch((err) => {
        console.log('*****', err);
      });
  };

  getCheckboxOptions() {
    const { inventory, permissions, holdTypes } = this.props;
    if (isEmpty(inventory)) return { checkboxOptions: {}, defaultValue: [] };
    let options = {};

    forEach(holdTypes, function (hold) {
      options[hold.name] = {
        disabled: false,
        checked: false,
      };
    });

    const inventoryHolds = this.getInventoryHolds();
    forEach(inventoryHolds, function (hold) {
      options[hold] = {
        disabled: true,
        checked: true,
      };
    });

    forEach(permissions, function (hold) {
      if (options[hold]) {
        options[hold].disabled = false;
      } else {
        options[hold] = {
          disabled: false,
          checked: false,
        };
      }
    });

    const orderedOptions = {};
    Object.keys(options)
      .sort()
      .forEach((key) => (orderedOptions[key] = options[key]));

    return { checkboxOptions: orderedOptions, defaultValue: inventoryHolds };
  }

  render() {
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const { inventory, visible, loading, permissions, poModal } = this.props;

    if (isEmpty(inventory)) return null;

    const { defaultValue, checkboxOptions } = this.getCheckboxOptions();

    if (!permissions)
      return <Skeleton loading={true} paragraph={{ rows: 5 }} />;

    return (
      <Modal
        visible={visible}
        title='Action on Item'
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={this.handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            danger
            loading={loading}
            onClick={this.handleOk}
            disabled={inventory.status === StringConstants.TEMP_ACTIVE_STATUS}
          >
            Submit changes
          </Button>,
        ]}
      >
        {!permissions || !inventory ? (
          <Skeleton loading={true} paragraph={{ rows: 5 }} />
        ) : (
          <>
            {inventory.status === StringConstants.TEMP_ACTIVE_STATUS && (
              <div style={{ marginBottom: 10 }}>
                <Typography.Text type='warning'>
                  Cannot action on a Lot that is temporarily active
                </Typography.Text>
              </div>
            )}
            <Descriptions bordered column={1} size='middle'>
              {poModal ? (
                <>
                  <Descriptions.Item label='PO Number'>
                    {inventory.po_number}
                  </Descriptions.Item>
                  <Descriptions.Item label='Number of FINs'>
                    {inventory.po_fins.length}
                  </Descriptions.Item>
                  <Descriptions.Item label='Container Number'>
                    {inventory.container_no}
                  </Descriptions.Item>
                  <Descriptions.Item label='Vendor'>
                    {inventory.buy_from_vendor_name}
                  </Descriptions.Item>
                </>
              ) : (
                <>
                  <Descriptions.Item label='Location'>
                    {inventory.location}
                  </Descriptions.Item>
                  <Descriptions.Item label='Lot Number'>
                    {inventory.lot_code}
                  </Descriptions.Item>
                  <Descriptions.Item label='FIN'>
                    {inventory.fin}
                  </Descriptions.Item>
                  <Descriptions.Item label='Quantity'>
                    {inventory.quantity}
                  </Descriptions.Item>
                </>
              )}

              <Descriptions.Item label='Status'>
                <Tag color={getInventoryStatusColor(inventory.status)}>
                  {upperCase(inventory.status)}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
            <div className='space-top'>
              <Form
                {...layout}
                ref={(ref) => (this.holdFormRef = ref)}
                initialValues={{
                  hold_types: defaultValue,
                  watch: isUndefined(inventory.is_watched)
                    ? true
                    : inventory.is_watched,
                }}
              >
                <Form.Item
                  name='watch'
                  label='Start Watching'
                  valuePropName='checked'
                  style={{ marginBottom: 10 }}
                >
                  <Switch checkedChildren='Yes' unCheckedChildren='No' />
                </Form.Item>
                <Form.Item name='hold_types' label='Hold Types'>
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row>
                      {Object.keys(checkboxOptions).map((hold, index) => {
                        const length = hold.length;
                        return (
                          <Col
                            xs={24}
                            lg={length > 20 ? 24 : length > 10 ? 16 : 8}
                            key={index}
                          >
                            <Checkbox
                              key={index}
                              value={hold}
                              disabled={checkboxOptions[hold].disabled}
                              checked={checkboxOptions[hold].checked}
                            >
                              {hold}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item name='comment' label='Comment'>
                  <Input.TextArea rows={2} />
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

export default HoldUnholdModal;
