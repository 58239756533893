import {
  Button,
  Col,
  Descriptions,
  Empty,
  PageHeader,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  CommentOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LinkOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import React, { Component } from 'react';
import { find, forEach, isEmpty, sortBy, upperCase } from 'lodash';
import {
  getInventoryStatusColor,
  hashCode,
  intToRGB,
} from '../../utils/colorUtils';
import { inject, observer } from 'mobx-react';

import HoldUnholdModal from './HoldUnholdModal';
import HttpConstants from '../../constants/HttpConstants';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import RouteConstants from '../../constants/RouteConstants';
import StringConstants from '../../constants/StringConstants';
import UrlConstants from '../../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../../api/ApiManager';
import moment from 'moment';

@inject('rootStore')
@observer
class PoDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      holdManagementModalVisible: false,
    };
  }

  handleViewHoldModalClick = () => {
    this.setState({ holdManagementModalVisible: true });
  };

  handlePutOnHoldCancel = () => {
    this.setState({
      modalLoading: false,
      holdManagementModalVisible: false,
    });
  };

  handlePutOnHoldSubmit = async (
    inventoryObj,
    addedHolds,
    removedHolds,
    comment,
    watch
  ) => {
    this.setState({ modalLoading: true });
    const params = {
      po_number: inventoryObj.po_number,
      id: inventoryObj.id,
      action: 'HOLD',
      holds: [],
      watch,
    };
    forEach(addedHolds, function (holdType) {
      const holdObj = {
        type: holdType,
        message: comment,
      };
      params.holds.push(holdObj);
    });

    const removeHoldParams = {
      po_number: inventoryObj.po_number,
      id: inventoryObj.id,
      action: 'UNHOLD',
      holds: [],
      watch,
    };
    forEach(removedHolds, function (holdType) {
      const holdObj = {
        type: holdType,
        message: comment,
      };
      removeHoldParams.holds.push(holdObj);
    });

    try {
      if (addedHolds.length > 0) {
        const response = await makeApiCallWithAuthentication(
          UrlConstants.ACTION_ON_PO.USECASE,
          HttpConstants.POST_METHOD,
          params
        );
        if (response.status === 200) {
          await this.updateRemovedHolds(removeHoldParams);
        } else {
          message.error('Could not add hold, aborting remove operation');
          this.setState({
            modalLoading: false,
          });
        }
      } else {
        await this.updateRemovedHolds(removeHoldParams);
      }
    } catch (err) {
      console.log('*******', err);
      message.error("Couldn't perform update, please try later");
      this.setState({
        modalLoading: false,
      });
    }
  };

  updateRemovedHolds = async (removeHoldParams) => {
    if (removeHoldParams.holds.length > 0) {
      const r_response = await makeApiCallWithAuthentication(
        UrlConstants.ACTION_ON_PO.USECASE,
        HttpConstants.POST_METHOD,
        removeHoldParams
      );
      if (r_response.status === 200) {
        message.success('Successfully updated PO !');
      } else {
        message.warn(
          'New holds added, removing existing holds failed. Try again'
        );
      }
    } else {
      message.success('Successfully updated PO !');
    }
    this.setState({
      modalLoading: false,
      holdManagementModalVisible: false,
    });

    this.props.refreshData &&
      this.props.refreshData(
        this.props.poDetails.po_number,
        this.props.poDetails.container_no
      );
  };

  //This is used while updating just watch flag from modal
  handleUpdateWatchStatus = async (poObject, flag) => {
    const { poDetails } = this.props;
    const payload = {
      type: StringConstants.WATCHERS.TYPE.PO,
      action: !flag
        ? StringConstants.WATCHERS.ACTION.STOP_WATCHING
        : StringConstants.WATCHERS.ACTION.START_WATCHING,
      identifier: poObject.id,
    };
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.WATCHER_ACTIVITY.USECASE,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          message.success(response.data.message);
          this.setState({
            holdManagementModalVisible: false,
            modalLoading: false,
          });
          this.props.toggleWatchFlag(poDetails.po_number, flag);
        } else throw response;
      })
      .catch((err) => {
        let errMessage = 'Error occured while performing the action';
        if (err && err.data && err.data.message) errMessage = err.data.message;
        message.error(errMessage);
        this.setState({
          holdManagementModalVisible: false,
          modalLoading: false,
        });
      });
  };

  updateWatchStatus = () => {
    const { poDetails } = this.props;
    const payload = {
      type: StringConstants.WATCHERS.TYPE.PO,
      action: poDetails.is_watched
        ? StringConstants.WATCHERS.ACTION.STOP_WATCHING
        : StringConstants.WATCHERS.ACTION.START_WATCHING,
      identifier: poDetails.id,
    };
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.WATCHER_ACTIVITY.USECASE,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          message.success(response.data.message);
          this.props.toggleWatchFlag(poDetails.po_number, true);
          this.setState({ loading: false });
        } else throw response;
      })
      .catch((err) => {
        message.error('Error occurred while performing the action');
        this.setState({ loading: false });
      });
  };

  getTeamsUrl = (userEmail, msgToSend) => {
    return (
      'https://teams.microsoft.com/l/chat/0/0?users=' +
      userEmail +
      '&message=' +
      encodeURIComponent(msgToSend)
    );
  };

  renderTeamsHyperlink = (username, userEmail, msgToSend) => {
    return (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={this.getTeamsUrl(userEmail, msgToSend)}
      >
        {username}
      </a>
    );
  };

  getTeamsGroupUrl = (holdType, msgToSend) => {
    const { holdTypesUsers } = this.props.rootStore.holdTypesUsersStore;

    let foundHoldType = find(holdTypesUsers, function (o) {
      return o.name === holdType;
    });

    if (foundHoldType)
      return (
        'https://teams.microsoft.com/l/chat/0/0?users=' +
        foundHoldType.users.map((elt) => elt.email).join(',') +
        '&topicName=' +
        '[' +
        holdType +
        '] - Hold Users' +
        '&message=' +
        encodeURIComponent(msgToSend)
      );
    else return '#';
  };

  renderTeamsGroupHyperlink = (holdType, msgToSend) => {
    return (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={this.getTeamsGroupUrl(holdType, msgToSend)}
        style={{ color: 'inherit' }}
      >
        {holdType}
      </a>
    );
  };

  renderTimeline() {
    const { poDetails } = this.props;
    return sortBy(poDetails.po_audits, 'createdAt').map((audit, index) => {
      let color = 'blue';
      let message = '';

      const url = window.location.origin + '/po/' + poDetails.id;
      let msgToSend =
        'Hi ! Regarding PO: ' + poDetails.po_number + ', in HMS (' + url + ')';
      switch (audit.action) {
        case 'AUTO_HOLD':
          color = 'red';
          message = (
            <span>
              <a
                href={`mailto:${audit.actioned_by_user_email}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {audit.actioned_by_username}
              </a>{' '}
              placed the <strong>PO</strong> on{' '}
              <Tag color={color}>
                {this.renderTeamsGroupHyperlink(audit.sub_type, msgToSend)}
              </Tag>
              hold
            </span>
          );
          break;
        case 'JSC_HOLD':
        case 'HOLD':
          color = 'red';
          message = (
            <span>
              {this.renderTeamsHyperlink(
                audit.actioned_by_username,
                audit.actioned_by_user_email,
                msgToSend
              )}{' '}
              placed the PO on{' '}
              <Tag color={color}>
                {this.renderTeamsGroupHyperlink(audit.sub_type, msgToSend)}
              </Tag>
              hold {audit.action === 'JSC_HOLD' ? 'as per JSC' : ''}
            </span>
          );
          break;
        case 'JSC_UNHOLD':
        case 'UNHOLD':
          color = 'green';
          message = (
            <span>
              {this.renderTeamsHyperlink(
                audit.actioned_by_username,
                audit.actioned_by_user_email,
                msgToSend
              )}{' '}
              removed the PO from{' '}
              <Tag color={color}>
                {this.renderTeamsGroupHyperlink(audit.sub_type, msgToSend)}
              </Tag>
              hold {audit.action === 'JSC_UNHOLD' ? 'as per JSC' : ''}
            </span>
          );
          break;
        default:
          color = 'blue';
          break;
      }

      return (
        <Timeline.Item
          key={index}
          label={moment(audit.createdAt).format('llll')}
          color={color}
        >
          <Space direction='vertical' size={1}>
            <Typography.Text>{message}</Typography.Text>
            {audit.message && (
              <Typography.Text type='secondary'>
                <CommentOutlined style={{ marginRight: 5 }} />
                {audit.message}
              </Typography.Text>
            )}
          </Space>
        </Timeline.Item>
      );
    });
  }

  getColumnsForFinTable = () => {
    const columns = [
      {
        title: 'FIN',
        dataIndex: 'fin',
        key: 'fin',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.fin.localeCompare(b.fin),
      },
      { title: 'Description', dataIndex: 'description', key: 'description' },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (quantity) => (
          <NumberFormat
            value={quantity}
            displayType={'text'}
            thousandSeparator={true}
          />
        ),
      },
    ];
    return columns;
  };

  getColumnsForInventoryTable = () => {
    const columns = [
      {
        title: 'FIN',
        dataIndex: 'fin',
        key: 'fin',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.fin.localeCompare(b.fin),
      },
      { title: 'Description', dataIndex: 'description', key: 'description' },
      {
        title: 'Lot Code',
        key: 'lot_code',
        render: ({ id, lot_code }) => {
          if (id)
            return (
              <Link to={RouteConstants.INVENTORY_DETAILS.replace(':lotno', id)}>
                {lot_code}
              </Link>
            );
          else return { lot_code };
        },
      },
      { title: 'Location', dataIndex: 'location', key: 'location' },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) =>
          status === StringConstants.UNAVAILABLE_STATUS ? (
            <Tooltip title='This Lot is no longer available in Business Central.'>
              <Tag color={getInventoryStatusColor(status)}>
                {upperCase(status)}
              </Tag>
            </Tooltip>
          ) : (
            <Tag color={getInventoryStatusColor(status)}>
              {upperCase(status)}
            </Tag>
          ),
      },
    ];
    return columns;
  };

  copyPoDetailsUrl = () => {
    try {
      let path = window.location.origin + RouteConstants.PO_DETAILS;
      path = path.replace(':id', this.props.poDetails.id);
      navigator.clipboard.writeText(path);
      message.success('Link copied to clipboard');
    } catch (err) {
      message.error('Cannot copy link');
    }
  };

  render() {
    const { poDetails, enableShare } = this.props;
    const { loading, modalLoading, holdManagementModalVisible } = this.state;

    if (isEmpty(poDetails)) return <Empty description='Could not find PO' />;
    return (
      <PageHeader
        ghost={false}
        onBack={this.props.goBack}
        title={`${poDetails.po_number}`}
        tags={
          enableShare && (
            <Tooltip title='Copy Shareable Link'>
              <Button
                icon={<ShareAltOutlined />}
                type='link'
                onClick={this.copyPoDetailsUrl}
              />
            </Tooltip>
          )
        }
        extra={[
          !poDetails.is_watched ? (
            <Button
              key='2'
              type='default'
              onClick={this.updateWatchStatus}
              loading={loading}
            >
              <EyeOutlined /> Start Watching
            </Button>
          ) : (
            <Button
              key='3'
              type='default'
              danger
              onClick={this.updateWatchStatus}
              loading={loading}
            >
              <EyeInvisibleOutlined /> Stop Watching
            </Button>
          ),
          <Button
            key='1'
            type='primary'
            onClick={this.handleViewHoldModalClick}
            disabled={loading}
          >
            Action on Item
          </Button>,
        ]}
      >
        <Descriptions
          size='small'
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}
          className='space-top-10'
        >
          <Descriptions.Item label='Location'>
            <Typography.Text strong>{poDetails.location}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label='PO Number'>
            <Typography.Text strong copyable>
              {poDetails.po_number}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label='Container Number'>
            <Typography.Text strong>
              <a
                href={`https://oceans.fishinco.fish/shipments/intransit/${poDetails.po_number}/${poDetails.container_no}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {poDetails.container_no}
              </a>
              <LinkOutlined style={{ marginLeft: 5 }} />
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item label='Expected Receipt Date'>
            <Typography.Text strong>
              {poDetails.expected_receipt_date &&
                moment(poDetails.expected_receipt_date).format('ll')}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label='Status'>
            {poDetails.status === StringConstants.MOVED_STATUS ? (
              <Tooltip title='This PO has been moved to Lots'>
                <Tag color={getInventoryStatusColor(poDetails.status)}>
                  {upperCase(poDetails.status)}
                </Tag>
              </Tooltip>
            ) : (
              <Tag color={getInventoryStatusColor(poDetails.status)}>
                {upperCase(poDetails.status)}
              </Tag>
            )}
          </Descriptions.Item>

          <Descriptions.Item label='Vendor'>
            <Typography.Text strong>
              {poDetails.buy_from_vendor_name}
            </Typography.Text>
          </Descriptions.Item>

          {!poDetails.store_on_peco_pallet && (
            <Descriptions.Item label='PECO Pallet' className='space-top-10'>
              <Typography.Text strong>Store on PECO Pallet</Typography.Text>
            </Descriptions.Item>
          )}

          {poDetails.status === StringConstants.ONHOLD_STATUS && (
            <Descriptions.Item
              label='Active Holds'
              span={3}
              className='space-top-10'
            >
              {sortBy(poDetails.po_holds, ['hold_type.name']).map(
                (hold, index) => {
                  return (
                    hold.is_active && (
                      <Tag
                        key={index}
                        color={intToRGB(hashCode(hold.hold_type.name))}
                      >
                        {hold.hold_type.name}
                      </Tag>
                    )
                  );
                }
              )}
            </Descriptions.Item>
          )}

          <Descriptions.Item label='Contract number'>
            <Typography.Text strong>{poDetails.po_reference}</Typography.Text>
          </Descriptions.Item>
        </Descriptions>

        <Table
          className='space-top'
          size='small'
          columns={
            isEmpty(poDetails.inventory)
              ? this.getColumnsForFinTable()
              : this.getColumnsForInventoryTable()
          }
          rowKey={(record) => record.line_no}
          dataSource={
            isEmpty(poDetails.inventory)
              ? poDetails.po_fins
              : poDetails.inventory
          }
          pagination={true}
        />

        <Tabs defaultActiveKey='1' className='space-top-10'>
          <Tabs.TabPane tab='Activity on PO' key='1'>
            {isEmpty(poDetails.po_audits) ? (
              <Empty description='No Audit history' />
            ) : (
              <Row gutter={16} className='space-top-10'>
                <Col xs={24} sm={12}>
                  <Timeline
                    mode='left'
                    className='space-top'
                    pending={
                      poDetails.status === StringConstants.MOVED_STATUS
                        ? false
                        : true
                    }
                    pendingDot={<span />}
                    reverse={true}
                  >
                    {this.renderTimeline()}
                  </Timeline>
                </Col>
              </Row>
            )}
          </Tabs.TabPane>
        </Tabs>
        <HoldUnholdModal
          inventory={poDetails}
          poModal={true}
          visible={holdManagementModalVisible}
          loading={modalLoading}
          onCancel={this.handlePutOnHoldCancel}
          onSubmit={this.handlePutOnHoldSubmit}
          onWatchUpdate={this.handleUpdateWatchStatus}
          holdTypes={this.props.holdTypes}
          permissions={this.props.rootStore.userStore.permissions}
        />
      </PageHeader>
    );
  }
}

export default PoDetailsView;
