import { Button, Descriptions, Form, Input, Modal, Switch } from 'antd';
import React, { Component } from 'react';

class UpdateFinModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLoading: false,
      permissions: [],
    };
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.holdFormRef
      .validateFields()
      .then((data) => {
        data.fin = this.props.selectedFin.fin;
        this.props.onSubmit(data);
      })
      .catch((err) => {
        console.log('*****', err);
      });
  };

  render() {
    const { loading, visible, selectedFin } = this.props;
    const { modalLoading } = this.state;
    const layout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
    };

    return (
      <Modal
        visible={visible}
        title='Update FIN Details'
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        confirmLoading={modalLoading}
        closeIcon={false}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={this.handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={this.handleOk}
          >
            Update FIN
          </Button>,
        ]}
      >
        <Descriptions bordered column={1} size='middle'>
          <Descriptions.Item label='FIN'>{selectedFin.fin}</Descriptions.Item>
          <Descriptions.Item label='Description'>
            {selectedFin.description}
          </Descriptions.Item>
        </Descriptions>
        <Form
          {...layout}
          ref={(ref) => (this.holdFormRef = ref)}
          initialValues={selectedFin}
          className='space-top-10'
        >
          <Form.Item
            name='blocked'
            label='Overall'
            valuePropName='checked'
            style={{ marginBottom: 10 }}
          >
            <Switch checkedChildren='Blocked' unCheckedChildren='Active' />
          </Form.Item>
          <Form.Item
            name='purchase_blocked'
            label='Purchases'
            valuePropName='checked'
            style={{ marginBottom: 10 }}
          >
            <Switch checkedChildren='Blocked' unCheckedChildren='Active' />
          </Form.Item>
          <Form.Item
            name='sales_blocked'
            label='Sales'
            valuePropName='checked'
            style={{ marginBottom: 10 }}
          >
            <Switch checkedChildren='Blocked' unCheckedChildren='Active' />
          </Form.Item>
          <Form.Item name='comment' label='Comment'>
            <Input.TextArea role={2} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default UpdateFinModal;
