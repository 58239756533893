import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';

import HttpConstants from '../../constants/HttpConstants';
import LoadingComponent from '../../app/components/Loading';
import RoleConstants from '../../constants/RoleConstants';
import UrlConstants from '../../api/UrlConstants';
import { isEmpty } from 'lodash';
import { makeApiCallWithAuthentication } from '../../api/ApiManager';

const uneditabledRoles = [
  RoleConstants.SUPER_USER,
  RoleConstants.ACCOUNTING,
  RoleConstants.TEMP_UNHOLD_REQUESTOR,
  RoleConstants.AUTO_HOLDS_MANAGER,
  RoleConstants.APPROVER,
];
class AddorEditRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLoading: false,
      permissions: [],
    };
  }

  componentDidMount() {
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_HOLD_TYPES.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ modalLoading: false, permissions: response.data });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ modalLoading: false });
      });
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.holdFormRef
      .validateFields()
      .then((data) => {
        this.props.onSubmit(data);
      })
      .catch((err) => {
        console.log('*****', err);
      });
  };

  render() {
    const { loading, visible, selectedData } = this.props;
    const { permissions, modalLoading } = this.state;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Modal
        visible={visible}
        title={isEmpty(selectedData) ? 'Add Role' : 'Update Role'}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        confirmLoading={modalLoading}
        closeIcon={false}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={this.handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={this.handleOk}
          >
            {isEmpty(selectedData) ? 'Add Role' : 'Update Role'}
          </Button>,
        ]}
      >
        {modalLoading ? (
          <LoadingComponent tip='Fetching roles' />
        ) : (
          <Form
            {...layout}
            ref={(ref) => (this.holdFormRef = ref)}
            initialValues={selectedData}
          >
            <Form.Item
              name='name'
              label='Role Name'
              rules={[
                {
                  required: true,
                  message: 'Please enter a name',
                },
              ]}
            >
              <Input disabled={uneditabledRoles.includes(selectedData.name)} />
            </Form.Item>

            <Form.Item
              name='description'
              label='Description'
              rules={[
                {
                  required: true,
                  message: 'Please enter a description',
                },
              ]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>

            <Form.Item
              name='holdIds'
              label='Hold Types'
              rules={[
                {
                  required: !uneditabledRoles.includes(selectedData.name),
                  message: 'Please choose atleast one hold type',
                },
              ]}
            >
              <Select mode='multiple'>
                {permissions.map((permission, index) => {
                  return (
                    <Select.Option value={permission.id} key={index}>
                      {permission.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default AddorEditRoles;
