import {
  addQueryParamsToApiUrl,
  generateUrl,
  updatePathParamsForApiUrl,
} from './UrlGenerator';

import HttpConstants from '../constants/HttpConstants';
import StringConstants from '../constants/StringConstants';
import axios from 'axios';
import cookie from 'react-cookies';

export function makeApiCall(usecase, httpMethod, data) {
  return makeApiCallInternal(usecase, httpMethod, false, data);
}

export function makeApiCallWithAuthentication(usecase, httpMethod, data) {
  return makeApiCallInternal(usecase, httpMethod, true, data);
}

function makeApiCallInternal(usecase, httpMethod, authenticatedCall, data) {
  let { url, params } = updatePathParamsForApiUrl(generateUrl(usecase), data);
  let headers = {
    headers: {},
  };

  if (authenticatedCall)
    headers = {
      headers: {
        Authorization:
          'Bearer ' + cookie.load(StringConstants.COOKIES.AUTH_TOKEN),
      },
    };

  switch (httpMethod) {
    case HttpConstants.GET_METHOD:
      if (params) {
        url = addQueryParamsToApiUrl(url, params);
      }
      return axios.get(url, headers).catch((err) => {
        return err.response;
      });
    case HttpConstants.POST_METHOD:
      return axios.post(url, params, headers).catch((err) => {
        return err.response;
      });
    case HttpConstants.PATCH_METHOD:
      return axios.patch(url, params, headers).catch((err) => {
        return err.response;
      });
    case HttpConstants.PUT_METHOD:
      return axios.put(url, params, headers).catch((err) => {
        return err.response;
      });
    case HttpConstants.DELETE_METHOD:
      if (params) {
        url = addQueryParamsToApiUrl(url, params);
      }
      return axios.delete(url, headers);
    default:
      throw new Error('No HTTP Method found');
  }
}
