import {
  Col,
  Descriptions,
  Row,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import React, { Component } from 'react';

import AddNewAutoHoldRule from './autoHoldRules/AddNewAHRuleModal';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import generateRuleStringForAutoHold from '../utils/autoHoldRuleUtils';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import moment from 'moment';
import { withRouter } from 'react-router';

class AutoHoldRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      addRuleModalVisible: false,
      rules: [],
      holdTypes: [],
      fins: [],
      countries: [],
      locations: [],
    };
  }

  componentDidMount() {
    this.getAutoHoldRules();
  }


  getAutoHoldRules = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_AUTO_HOLD_RULES.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ loading: false, rules: response.data });
        } else throw response;
      })
      .catch((err) => this.setState({ loading: false }));
  };

  getColumnsForTable = () => {
    const columns = [
      {
        key: 'id',
        title: 'Rule',
        render: (data) => generateRuleStringForAutoHold(data),
      },
      {
        key: 'user',
        dataIndex: 'user',
        title: 'Created By',
        render: (user) => <span>{user.name}</span>,
      },
      {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: 'Created At',
        render: (createdAt) => moment(createdAt).format('lll'),
        sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
        defaultSortOrder: 'descend',
      },
		{
		  key: 'notes',
		  dataIndex: 'notes',
		  title: 'Notes',
		  render: (notes) => (
		    <Tooltip title={notes}>
		      <span>
		        {notes && notes.length > 10
		          ? `${notes.substring(0, 10)}...`
		          : notes}
		      </span>
		    </Tooltip>
		  ),
		},

    ];
    return columns;
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'id',
        title: 'Rule',
        render: (data) => generateRuleStringForAutoHold(data),
        sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
        defaultSortOrder: 'descend',
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <Descriptions bordered>
        <Descriptions.Item label='Created By'>
          {record.user.name}
        </Descriptions.Item>
        <Descriptions.Item label='Created At'>
          {record.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label='Notes'>
          {record.notes}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  render() {
    const {
      loading,
      modalLoading,
      addRuleModalVisible,
      holdTypes,
      fins,
      countries,
      locations,
      rules,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Rules for auto holds</title>
          <meta name='description' content='Rules for Auto holds' />
        </Helmet>
        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Auto Hold Items
            </Typography.Text>
          </Col>
          <Col xs={0} md={24} className='space-top'>
            <Table
              columns={this.getColumnsForTable()}
              dataSource={rules}
              loading={loading}
              locale={{
                emptyText: 'No Rules Available',
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} rules`,
              }}
              rowKey={(row) => row.id}
              scroll={{ x: 'fit-content' }}
            />
          </Col>
          <Col xs={24} md={0} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={rules}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Rules Available',
              }}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} rules`,
              }}
            />
          </Col>
          <AddNewAutoHoldRule
            loading={modalLoading}
            allHolds={holdTypes}
            allFins={fins}
            allCountries={countries}
            allLocations={locations}
            visible={addRuleModalVisible}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(AutoHoldRules));
