export default {
  ADMIN_ADD_USER: {
    USECASE: 'ADMIN_ADD_USER',
    URL: '/api/user',
  },
  ADMIN_UPDATE_USER: {
    USECASE: 'ADMIN_UPDATE_USER',
    URL: '/api/user',
  },
  ADMIN_GET_ALL_USERS: {
    USECASE: 'ADMIN_GET_ALL_USERS',
    URL: '/api/user',
  },
  ADMIN_DELETE_USER: {
    USECASE: 'ADMIN_DELETE_USER',
    URL: '/api/user/$id',
  },
  GET_USER_DETAILS: {
    USECASE: 'GET_USER_DETAILS',
    URL: '/api/user/details',
  },

  ADMIN_ADD_ROLE: {
    USECASE: 'ADMIN_ADD_ROLE',
    URL: '/api/role',
  },
  ADMIN_UPDATE_ROLE: {
    USECASE: 'ADMIN_UPDATE_ROLE',
    URL: '/api/role',
  },
  ADMIN_GET_ALL_ROLES: {
    USECASE: 'ADMIN_GET_ALL_ROLES',
    URL: '/api/role',
  },
  GET_ROLE_DETAILS: {
    USECASE: 'GET_ROLE_DETAILS',
    URL: '/api/role/$id',
  },

  ADMIN_ADD_ROLE_TO_USER: {
    USECASE: 'ADMIN_ADD_ROLE_TO_USER',
    URL: '/api/user_role',
  },

  ADMIN_ADD_HOLD_TYPE: {
    USECASE: 'ADMIN_ADD_HOLD_TYPE',
    URL: '/api/hold_type',
  },
  GET_ALL_HOLD_TYPES: {
    USECASE: 'GET_ALL_HOLD_TYPES',
    URL: '/api/hold_type',
  },
  ADMIN_UPDATE_HOLD_TYPE: {
    USECASE: 'ADMIN_UPDATE_HOLD_TYPE',
    URL: '/api/hold_type',
  },

  ADMIN_ADD_ACTION_PERMISSION_FOR_ROLE: {
    USECASE: 'ADMIN_ADD_ACTION_PERMISSION_FOR_ROLE',
    URL: '/api/action_permission',
  },

  GET_ALL_INVENTORY_ACTIVE: {
    USECASE: 'GET_ALL_INVENTORY_ACTIVE',
    URL: '/api/inventory/all/details?status=ACTIVE',
  },
  GET_ALL_INVENTORY_ON_HOLD: {
    USECASE: 'GET_ALL_INVENTORY_ON_HOLD',
    URL: '/api/inventory/all/details?status=ON_HOLD',
  },
  GET_INVENTORY_DETAILS: {
    USECASE: 'GET_INVENTORY_DETAILS',
    URL: '/api/inventory/$id',
  },
  ACTION_ON_INVENTORY: {
    USECASE: 'ACTION_ON_INVENTORY',
    URL: '/api/inventory/action',
  },
  SEARCH_INVENTORY: {
    USECASE: 'SEARCH_INVENTORY',
    URL: '/api/inventory/search',
  },
  SEARCH_PO: {
    USECASE: 'SEARCH_PO',
    URL: '/api/purchase_order/search',
  },

  GET_ALL_LOCATIONS: {
    USECASE: 'GET_ALL_LOCATIONS',
    URL: '/api/location',
  },
  UPDATE_LOCATION_CONTACT_MAIL: {
    USECASE: 'UPDATE_LOCATION_CONTACT_MAIL',
    URL: '/api/location/warehouse-contact-email',
  },
  UPDATE_LOCATION_CONTAINER_REPORT_MAIL: {
    USECASE: 'UPDATE_LOCATION_CONTAINER_REPORT_MAIL',
    URL: '/api/location/container-reports-email',
  },
  GET_ALL_PO: {
    USECASE: 'GET_ALL_PO',
    URL: '/api/purchase_order/all/details',
  },
  GET_ALL_PO_ACTIVE: {
    USECASE: 'GET_ALL_PO_ACTIVE',
    URL: '/api/purchase_order/all/details?status=ACTIVE',
  },
  GET_ALL_PO_ONHOLD: {
    USECASE: 'GET_ALL_PO_ONHOLD',
    URL: '/api/purchase_order/all/details?status=ON_HOLD',
  },
  ACTION_ON_PO: {
    USECASE: 'ACTION_ON_PO',
    URL: '/api/purchase_order/action',
  },

  ANALYTICS: {
    USECASE: 'ANALYTICS',
    URL: '/api/report',
  },
  USERS_BY_HOLD_TYPE: {
    USECASE: 'USERS_BY_HOLD_TYPE',
    URL: '/api/hold_type/users',
  },

  GET_PO_DETAILS: {
    USECASE: 'GET_PO_DETAILS',
    URL: '/api/purchase_order/$id',
  },

  GET_ALL_AUTO_HOLD_RULES: {
    USECASE: 'GET_ALL_AUTO_HOLD_RULES',
    URL: '/api/auto_holds',
  },
  ADD_AUTO_HOLD_RULE: {
    USECASE: 'ADD_AUTO_HOLD_RULE',
    URL: '/api/auto_holds',
  },
  DELETE_AUTO_HOLD_RULE: {
    USECASE: 'DELETE_AUTO_HOLD_RULE',
    URL: '/api/auto_holds/$id',
  },
  
   UPDATE_NOTES_AUTO_HOLD_RULE: {
    USECASE: 'UPDATE_NOTES_AUTO_HOLD_RULE',
      URL: '/api/auto_holds/$id/$notes',
  },

  GET_HOLD_TYPES_AND_FINS: {
    USECASE: 'GET_HOLD_TYPES_AND_FINS',
    URL: '/api/auto_holds/holdtype-and-fin',
  },

  GET_WATCHED_INVENTORY: {
    USECASE: 'GET_WATCHED_INVENTORY',
    URL: '/api/user/watchlist/inventory',
  },
  GET_WATCHED_PO: {
    USECASE: 'GET_WATCHED_PO',
    URL: '/api/user/watchlist/po',
  },
  WATCHER_ACTIVITY: {
    USECASE: 'WATCHER_ACTIVITY',
    URL: '/api/watchers',
  },

  GET_ALL_FIN_DETAILS: {
    USECASE: 'GET_ALL_FIN_DETAILS',
    URL: '/api/auto_holds/fins',
  },
  UPDATE_FIN: {
    USECASE: 'UPDATE_FIN',
    URL: '/api/fin',
  },
  REFRESH_FINS: {
    USECASE: 'REFRESH_FINS',
    URL: '/api/nav/sync/fin',
  },

  GET_EXPIRY_REPORT: {
    USECASE: 'GET_EXPIRY_REPORT',
    URL: '/api/inventory/expiry',
  },

  TEMP_RELEASE_REQUEST: {
    USECASE: 'TEMP_RELEASE_REQUEST',
    URL: '/api/inventory/temp-release-request',
  },
  TEMP_RELEASE_REVOKE: {
    USECASE: 'TEMP_RELEASE_REVOKE',
    URL: '/api/inventory/temp-release-request/revoke',
  },
  TEMP_RELEASE_ACTION: {
    USECASE: 'TEMP_RELEASE_ACTION',
    URL: '/api/temp-release-request/action',
  },

  HOLDS_MISMATCH: {
    USECASE: 'HOLDS_MISMATCH',
    URL: '/api/report/lot-compare',
  },
  QUANTITY_MISMATCH: {
    USECASE: 'QUANTITY_MISMATCH',
    URL: '/api/report/quantity-mismatch',
  },

  // Transfer Orders
  GET_ALL_TO: {
    USECASE: 'GET_ALL_TO',
    URL: '/api/transfer-order',
  },
};
