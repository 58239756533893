import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import LoadingComponent from '../app/components/Loading';
import PoDetailsView from './shared/PoDetailsView';
import { Result } from 'antd';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class PoDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalLoading: false,
      holdManagementModalVisible: false,
      poId: null,
      poDetails: {},
      holdTypes: [],
    };
  }

  componentDidMount = () => {
    const poId = this.props.match.params.id;
    this.setState({ poId }, () => {
      this.fetchInventoryDetails();
    });
  };

  fetchInventoryDetails = () => {
    const { poId } = this.state;
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_PO_DETAILS.USECASE,
      HttpConstants.GET_METHOD,
      { $id: poId }
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            poDetails: response.data.po_detail,
            holdTypes: response.data.hold_type_list,
          });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ loading: false, poDetails: {} });
      });
  };

  toggleWatchFlag = () => {
    this.setState((prevState) => ({
      poDetails: {
        ...prevState.poDetails,
        is_watched: !prevState.poDetails.is_watched,
      },
    }));
  };

  render() {
    const { loading, poDetails, holdTypes } = this.state;
    if (loading) return <LoadingComponent tip='Loading PO Detail' />;
    if (poDetails)
      return (
        <div>
          <Helmet>
            <title>HMS - PO {poDetails.po_number} Details</title>
            <meta
              name='description'
              content={`PO - ${poDetails.po_number} - ${poDetails.description}`}
            />
          </Helmet>
          <PoDetailsView
            poDetails={poDetails}
            holdTypes={holdTypes}
            goBack={() => window.history.back()}
            refreshData={this.fetchInventoryDetails}
            toggleWatchFlag={this.toggleWatchFlag}
          />
        </div>
      );
    else
      return (
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the purchase order you are looking for does not exist.'
        />
      );
  }
}

export default SecureComponent(withRouter(PoDetail));
