import { Button, Descriptions, Form, Input, Modal, Tag } from 'antd';
import React, { Component } from 'react';
import { isEmpty, upperCase } from 'lodash';

import { getInventoryStatusColor } from '../../../utils/colorUtils';

class TempReleaseRequestModal extends Component {
  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.tempReleaseRequestForm
      .validateFields()
      .then((data) => {
        this.props.onSubmit(data.comment);
      })
      .catch((err) => {
        console.log('*****', err);
      });
  };

  render() {
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const { inventory, visible, loading } = this.props;

    if (isEmpty(inventory)) return null;

    return (
      <Modal
        visible={visible}
        title='Temporary Release Request'
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={this.handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            danger
            loading={loading}
            onClick={this.handleOk}
          >
            Submit Request
          </Button>,
        ]}
      >
        <Descriptions bordered column={1} size='middle'>
          <Descriptions.Item label='Location'>
            {inventory.location}
          </Descriptions.Item>
          <Descriptions.Item label='Lot Number'>
            {inventory.lot_code}
          </Descriptions.Item>
          <Descriptions.Item label='FIN'>{inventory.fin}</Descriptions.Item>
          <Descriptions.Item label='Quantity'>
            {inventory.quantity}
          </Descriptions.Item>
          <Descriptions.Item label='Status'>
            <Tag color={getInventoryStatusColor(inventory.status)}>
              {upperCase(inventory.status)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
        <div className='space-top'>
          <Form
            {...layout}
            ref={(ref) => (this.tempReleaseRequestForm = ref)}
            style={{ marginBottom: 10 }}
          >
            <Form.Item name='comment' label='Comment'>
              <Input.TextArea rows={2} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default TempReleaseRequestModal;
