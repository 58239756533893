import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';

import HttpConstants from '../../constants/HttpConstants';
import LoadingComponent from '../../app/components/Loading';
import UrlConstants from '../../api/UrlConstants';
import { isEmpty } from 'lodash';
import { makeApiCallWithAuthentication } from '../../api/ApiManager';

class AddorEditUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_loading: false,
      roles: [],
    };
  }

  componentDidMount() {
    this.setState({ modal_loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.ADMIN_GET_ALL_ROLES.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ roles: response.data, modal_loading: false });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ roles: [], modal_loading: false });
      });
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.holdFormRef
      .validateFields()
      .then((data) => {
        this.props.onSubmit(data);
      })
      .catch((err) => {
        console.log('*****', err);
      });
  };

  render() {
    const { loading, visible, selectedData } = this.props;
    const { roles, modal_loading } = this.state;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Modal
        visible={visible}
        title={isEmpty(selectedData) ? 'Add User' : 'Update User'}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        maskClosable={false}
        closable={false}
        confirmLoading={modal_loading}
        closeIcon={false}
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={this.handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={this.handleOk}
          >
            {isEmpty(selectedData) ? 'Add User' : 'Update User'}
          </Button>,
        ]}
      >
        {modal_loading ? (
          <LoadingComponent tip='Fetching roles' />
        ) : (
          <Form
            {...layout}
            ref={(ref) => (this.holdFormRef = ref)}
            initialValues={selectedData}
          >
            <Form.Item
              name='name'
              label='Name'
              rules={[
                {
                  required: true,
                  message: 'Please enter a name',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='email'
              label='E-mail'
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please enter a email',
                },
              ]}
            >
              <Input type='email' />
            </Form.Item>

            <Form.Item
              name='rolesId'
              label='Roles'
              rules={[
                {
                  required: true,
                  message: 'Please choose atleast one role',
                },
              ]}
            >
              <Select mode='multiple'>
                {roles.map((role, index) => {
                  return (
                    <Select.Option value={role.id} key={index}>
                      {role.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export default AddorEditUsers;
