import { Col, Descriptions, Table, Tag, Tooltip } from 'antd';
import { hashCode, intToRGB } from '../../../utils/colorUtils';
import { sortBy, upperCase } from 'lodash';

import { CodepenOutlined } from '@ant-design/icons';
import { Component } from 'react';
import NumberFormat from 'react-number-format';
import React from 'react';
import StringConstants from '../../../constants/StringConstants';
import moment from 'moment';

const expandedRowRender = ({ transfer_order_line_items }) => {
  const columns = [
    { title: 'Line no.', dataIndex: 'line_no', key: 'line_no' },
    { title: 'Lot', dataIndex: 'lot_code', key: 'lot_code' },
    {
      title: 'FIN',
      key: 'fin',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.fin.localeCompare(b.fin),
      render: ({ fin, store_on_peco_pallet }) => {
        return (
          <>
            <span>{fin}</span>
            {store_on_peco_pallet && (
              <Tooltip placement='topLeft' title='Store on PECO Pallet'>
                <CodepenOutlined style={{ marginLeft: 10 }} />
              </Tooltip>
            )}
          </>
        );
      },
    },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity) => (
        <NumberFormat
          value={quantity}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
    {
      title: 'Net Weight',
      dataIndex: 'net_weight',
      key: 'net_weight',
      render: (net_weight) => (
        <NumberFormat
          value={net_weight}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={transfer_order_line_items}
      pagination={false}
      rowKey={(record) => record.line_no}
    />
  );
};

class TOTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLoading: false,

      inventory: {},
    };
  }

  getColumnsForTable() {
    return [
      {
        key: 'to_number',
        dataIndex: 'to_number',
        title: 'Transfer Order Number',
        sorter: (a, b) => a.to_number.localeCompare(b.to_number),
        defaultSortOrder: 'ascend',
        width: 130,
        fixed: 'left',
      },
      {
        key: 'to_warehouse',
        dataIndex: 'to_warehouse',
        title: 'To Warehouse',
        sorter: (a, b) => a.to_warehouse.localeCompare(b.to_warehouse),
        width: 130,
        fixed: 'left',
      },
      {
        key: 'transfer_order_line_items',
        dataIndex: 'transfer_order_line_items',
        title: 'Line Items',
        sorter: (a, b) =>
          a.transfer_order_line_items.length -
          b.transfer_order_line_items.length,
        render: (transfer_order_line_items) => transfer_order_line_items.length,
        width: 120,
      },
      {
        key: 'transfer_from_country_code',
        dataIndex: 'transfer_from_country_code',
        title: 'Transfer from Country',
        sorter: (a, b) =>
          a.transfer_from_country_code.localeCompare(
            b.transfer_from_country_code
          ),
        width: 110,
      },
      {
        key: 'supplier',
        dataIndex: 'supplier',
        title: 'Supplier',
        sorter: (a, b) => a.supplier.localeCompare(b.supplier),
        ellipsis: {
          showTitle: false,
        },
        responsive: ['md'],
        width: 150,
      },
      {
        key: 'receipt_date',
        dataIndex: 'receipt_date',
        title: 'Arrival Date',
        sorter: (a, b) => {
          if (a.receipt_date && b.receipt_date)
            return moment(a.receipt_date).diff(moment(b.receipt_date), 'hours');
          else if (a.receipt_date) return 1;
          else if (b.receipt_date) return -1;
          else return 0;
        },
        render: (receipt_date) =>
          receipt_date && moment(receipt_date).format('ll'),
        width: 140,
      },
      {
        key: 'id',
        title: this.props.displayHolds ? 'Holds' : 'Status',
        width: this.props.displayHolds ? 0 : 120,
        render: ({ status, po_holds }) => {
          if (this.props.displayHolds) {
            return (
              <span>
                {sortBy(po_holds, 'hold_type.name').map((hold_, index) => {
                  if (hold_.hold_type && hold_.is_active) {
                    return (
                      <Tooltip title={hold_.message} key={index}>
                        <Tag
                          color={intToRGB(hashCode(hold_.hold_type.name))}
                          style={{ margin: 2 }}
                        >
                          {hold_.hold_type.name}
                        </Tag>
                      </Tooltip>
                    );
                  } else return null;
                })}
              </span>
            );
          } else {
            let color = 'red';
            if (status === StringConstants.ACTIVE_STATUS) {
              color = 'green';
            }
            return <Tag color={color}>{upperCase(status)}</Tag>;
          }
        },
      },
    ];
  }

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'to_number',
        dataIndex: 'to_number',
        title: 'Transfer Order Number',
        sorter: (a, b) => a.to_number.localeCompare(b.to_number),
        defaultSortOrder: 'ascend',
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <div className='text-center'>
        <Descriptions bordered>
          <Descriptions.Item label='To Warehouse'>
            {record.to_warehouse}
          </Descriptions.Item>
          <Descriptions.Item label='Line Items'>
            {record.transfer_order_line_items.length}
          </Descriptions.Item>

          <Descriptions.Item label='Transfer from Country'>
            {record.transfer_from_country_code}
          </Descriptions.Item>
          <Descriptions.Item label='Supplier'>
            {record.supplier}
          </Descriptions.Item>
          <Descriptions.Item label='Arrival Date'>
            {record.receipt_date && moment(record.receipt_date).format('ll')}
          </Descriptions.Item>
          <Descriptions.Item label='Status'>
            <Tag
              color={
                record.status === StringConstants.ACTIVE_STATUS
                  ? 'green'
                  : 'red'
              }
            >
              {upperCase(record.status)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  render() {
    const { data, loading, emptyText } = this.props;

    return (
      <div>
        <Col xs={0} md={24}>
          <Table
            scroll={{ x: 'fit-content' }}
            dataSource={data}
            loading={loading}
            columns={this.getColumnsForTable()}
            locale={{
              emptyText: emptyText ? emptyText : 'No Transfer Orders Available',
            }}
            rowKey={(record) => record.id}
            pagination={{
              defaultPageSize: 50,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            expandable={{ expandedRowRender }}
          />
        </Col>
        <Col xs={24} md={0}>
          <Table
            scroll={{ x: 'fit-content' }}
            dataSource={data}
            loading={loading}
            columns={this.getColumnsForTableSmallScreens()}
            locale={{
              emptyText: emptyText ? emptyText : 'No Transfer Orders Available',
            }}
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: this.renderRowForSmallScreens,
            }}
            pagination={{
              defaultPageSize: 50,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Col>
      </div>
    );
  }
}

export default TOTable;
