import React, { Component } from 'react';

import { Result } from 'antd';

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
      />
    );
  }
}

export default ErrorPage;
