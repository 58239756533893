/* eslint-disable no-loop-func */
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { clone, findIndex } from 'lodash';

import StringConstants from '../constants/StringConstants';
import moment from 'moment';

export const downloadExcel = (data, is_active_inventory, holds = []) => {
  var arrData = typeof data != 'object' ? JSON.parse(data) : data;

  var CSV = [];

  let fileName = is_active_inventory ? 'ActiveInventory' : 'Onhold';
  if (is_active_inventory) CSV = getDataForActiveInventoryForExcel(arrData);
  else {
    CSV = getDataForHoldInventoryForExcel(arrData, holds);
  }

  let currentDate = moment();

  fileName += ' - ' + moment(currentDate).format('MMM DD, YYYY');

  downloadExcelFile(fileName, CSV);

  // downloadCSVFile(fileName, CSV);
};

export const downloadExcelForPO = (data, is_active_inventory, holds = []) => {
  var arrData = typeof data != 'object' ? JSON.parse(data) : data;

  var CSV = [];

  let fileName = is_active_inventory ? 'PO-Active' : 'PO-Onhold';
  if (is_active_inventory) CSV = getDataForActivePOForExcel(arrData);
  else {
    CSV = getDataForHoldPOForExcel(arrData, holds);
  }

  let currentDate = moment();

  fileName += ' - ' + moment(currentDate).format('MMM DD, YYYY');

  downloadExcelFile(fileName, CSV);
};

export const downloadExcelForTO = (data, is_active_inventory, holds = []) => {
  var arrData = typeof data != 'object' ? JSON.parse(data) : data;

  var CSV = [];

  let fileName = is_active_inventory ? 'TO-Active' : 'TO-Onhold';
  if (is_active_inventory) CSV = getDataForActiveTOForExcel(arrData);
  else {
    CSV = getDataForHoldPOForExcel(arrData, holds);
  }

  let currentDate = moment();

  fileName += ' - ' + moment(currentDate).format('MMM DD, YYYY');

  downloadExcelFile(fileName, CSV);
};

export const downloadExcelForInboundContainers = (
  data,
  holds,
  location = 'ALL'
) => {
  var arrData = typeof data != 'object' ? JSON.parse(data) : data;

  var CSV = [];
  location = location ? location : 'ALL';
  let fileName = location + ' IB Container List';

  CSV = getExcelDataForInboundContainers(arrData, holds);

  let currentDate = moment();

  fileName += ' - ' + moment(currentDate).format('MMM DD, YYYY');

  downloadExcelFile(fileName, CSV);
};

export const downloadExcelForExpiringLots = (data, description = '') => {
  var arrData = typeof data != 'object' ? JSON.parse(data) : data;

  var CSV = [];

  let fileName = 'Expiry Report ' + description;
  CSV = getDataForExpiringLotsForExcel(arrData);

  let currentDate = moment();

  fileName += ' - ' + moment(currentDate).format('MMM DD, YYYY');

  downloadExcelFile(fileName, CSV);

  // downloadCSVFile(fileName, CSV);
};

export const downloadExcelForLotsMismatch = (data) => {
  var arrData = typeof data != 'object' ? JSON.parse(data) : data;

  var CSV = [];

  let fileName = 'Holds Mismatch Report ';
  CSV = getDataForLotsMismatch(arrData);

  let currentDate = moment();

  fileName += ' - ' + moment(currentDate).format('MMM DD, YYYY');

  downloadExcelFile(fileName, CSV);
};

export const downloadExcelForQuantityMismatch = (data) => {
  var arrData = typeof data != 'object' ? JSON.parse(data) : data;

  var CSV = [];

  let fileName = 'Quantity Mismatch Report ';
  CSV = getDataForQuantityMismatch(arrData);

  let currentDate = moment();

  fileName += ' - ' + moment(currentDate).format('MMM DD, YYYY');

  downloadExcelFile(fileName, CSV);
};

function downloadExcelFile(fileName, data) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(data);

  var range = XLSX.utils.decode_range(ws['!ref']);
  for (var r = range.s.r; r <= range.e.r; r++) {
    for (var c = range.s.c; c <= range.e.c; c++) {
      var cellName = XLSX.utils.encode_cell({ c: c, r: r });
      ws[cellName].z = '@';
    }
  }

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(excelData, fileName + fileExtension);
}

function getExcelDataForInboundContainers(arrData, holds = []) {
  var data = [];
  holds = holds.sort();

  for (let i = 0; i < arrData.length; i++) {
    var obj = {};
    obj['Scheduled to Received on Hold'] =
      arrData[i].status === StringConstants.ONHOLD_STATUS ? 'x' : '';
    obj['ETA'] = arrData[i].expected_receipt_date
      ? moment(arrData[i].expected_receipt_date).format('DD-MMM-YYYY')
      : '';
    obj['Container #'] = arrData[i].container_no.toString();
    obj['PO No.'] = arrData[i].po_number.toString();
    obj['To'] = arrData[i].location.toString();
    obj['FIN'] = arrData[i].fin.toString();
    obj['Item Description'] = arrData[i].description.toString();
    obj['Supplier/Plant'] = arrData[i].buy_from_vendor_name.toString();
    obj['Quantity'] = arrData[i].quantity.toString();
    obj['Type'] = arrData[i].product_group_code.toString();
    obj['From Country'] = arrData[i].buy_from_country_code.toString();
    obj['LBs to Rcv'] = arrData[i].net_weight.toString();
    obj['Load on to PECO Pallet'] = arrData[i].store_on_peco_pallet ? 'x' : '';
    holds.forEach((h) => {
      const index_ = findIndex(arrData[i]['po_holds'], function (i) {
        if (i.is_active === true && i.hold_type.name === h) return true;
        return false;
      });
      if (index_ >= 0) obj[h] = 'x';
      else obj[h] = '';
    });
    data.push(obj);
  }

  return data;
}

function getDataForActiveInventoryForExcel(arrData) {
  var data = [];

  for (let i = 0; i < arrData.length; i++) {
    var row = {};
    row['PO No.'] = arrData[i].po_number.toString();
    row['Location'] = arrData[i].location.toString();
    row['FIN'] = arrData[i].fin.toString();
    row['Lot number'] = arrData[i].lot_code.toString();
    row['Quantity'] = parseFloat(arrData[i].quantity);
    row['Description'] = arrData[i].description.toString();
    row['Expiry Date'] = arrData[i].expiration_date ? arrData[i].expiration_date.toString() : '';
    row['Audit CreatedAt'] = arrData[i].auditcreatedat ? arrData[i].auditcreatedat.toString() : '';
    row['Audit CreatedBy'] = arrData[i].auditcreatedby ? arrData[i].auditcreatedby.toString() : '';
    row['Audit Action'] = arrData[i].auditaction ? arrData[i].auditaction.toString() : '';
    row['Audit Comment'] = arrData[i].auditcomment ? arrData[i].auditcomment.toString() : ''
    data.push(row);
  }
  return data;
}

function getDataForExpiringLotsForExcel(arrData) {
  var data = [];

  for (let i = 0; i < arrData.length; i++) {
    var row = {};
    row['PO No.'] = arrData[i].po_number.toString();
    row['Location'] = arrData[i].location.toString();
    row['FIN'] = arrData[i].fin.toString();
    row['Lot number'] = arrData[i].lot_code.toString();
    row['Quantity'] = parseFloat(arrData[i].quantity);
    row['Description'] = arrData[i].description.toString();
    row['Net Weight'] = arrData[i].item_net_weight
      ? arrData[i].item_net_weight.toString()
      : '';
    row['Cost per unit'] = arrData[i].cost_per_unit
      ? arrData[i].cost_per_unit.toString()
      : '';
    row['Total Weight'] = arrData[i].total_weight
      ? arrData[i].total_weight.toString()
      : '';
    row['Total Cost'] = arrData[i].total_cost
      ? arrData[i].total_cost.toString()
      : '';
    row['Expiration Date'] = arrData[i].expiration_date
      ? moment(arrData[i].expiration_date).format('MM/DD/YYYY')
      : '';
    row['Status'] = arrData[i].status.toString();
    data.push(row);
  }
  return data;
}

function getDataForLotsMismatch(arrData) {
  var data = [];

  for (let i = 0; i < arrData.length; i++) {
    var row = {};

    row['PO No.'] = arrData[i].po_number.toString();
    row['Location'] = arrData[i].location.toString();
    row['FIN'] = arrData[i].fin.toString();
    row['Description'] = arrData[i].description.toString();
    row['Lot number'] = arrData[i].lot_code.toString();
    row['HMS Status'] = arrData[i].hms_blocked ? 'BLOCKED' : 'ACTIVE';
    row['NAV Status'] = arrData[i].nav_blocked ? 'BLOCKED' : 'ACTIVE';
    data.push(row);
  }
  return data;
}

function getDataForQuantityMismatch(arrData) {
  var data = [];

  for (let i = 0; i < arrData.length; i++) {
    var row = {};

    row['PO No.'] = arrData[i].po_number.toString();
    row['Location'] = arrData[i].location.toString();
    row['FIN'] = arrData[i].fin.toString();
    row['Description'] = arrData[i].description.toString();
    row['Lot number'] = arrData[i].lot_code.toString();
    row['HMS Status'] = arrData[i].hms_blocked ? 'BLOCKED' : 'ACTIVE';
    row['Quantity'] = arrData[i].quantity;
    data.push(row);
  }
  return data;
}

function getDataForHoldInventoryForExcel(arrData, holds = []) {
  var data = [];
  holds = holds.sort();

  for (let i = 0; i < arrData.length; i++) {
    var row = {};
    row['PO No.'] = arrData[i].po_number.toString();
    row['Location'] = arrData[i].location.toString();
    row['FIN'] = arrData[i].fin.toString();
    row['Lot number'] = arrData[i].lot_code.toString();
    row['Quantity'] = parseFloat(arrData[i].quantity);
    row['Description'] = arrData[i].description.toString();
    row['Expiry Date'] = arrData[i].expiration_date ? arrData[i].expiration_date.toString() : '';
    row['Onhold Since'] = arrData[i].onHoldSince.toString();
    row['Audit CreatedAt'] = arrData[i].auditcreatedat ? arrData[i].auditcreatedat.toString() : '';
    row['Audit CreatedBy'] = arrData[i].auditcreatedby ? arrData[i].auditcreatedby.toString() : '';
    row['Audit Action'] = arrData[i].auditaction ? arrData[i].auditaction.toString() : '';
    row['Audit Comment'] = arrData[i].auditcomment ? arrData[i].auditcomment.toString() : ''
    holds.map((h) => {
      const index_ = findIndex(arrData[i]['inventory_holds'], function (i) {
        if (i.is_active === true && i.hold_type.name === h) return true;
        return false;
      });
      if (index_ >= 0) row[h] = 'x';
      else row[h] = '';
      return row;
    });

    data.push(row);
  }
  return data;
}

function getDataForActivePOForExcel(arrData) {
  var data = [];

  for (let i = 0; i < arrData.length; i++) {
    var obj = {};
    obj['ETA'] = arrData[i].expected_receipt_date
      ? moment(arrData[i].expected_receipt_date).format('DD-MMM-YYYY')
      : '';
    obj['Container #'] = arrData[i].container_no.toString();
    obj['PO No.'] = arrData[i].po_number.toString();
    obj['To'] = arrData[i].location.toString();
    obj['FIN'] = null;
    obj['Item Description'] = null;
    obj['Supplier/Plant'] = arrData[i].buy_from_vendor_name.toString();
    obj['Quantity'] = null;
    obj['From Country'] = arrData[i].buy_from_country_code.toString();
    arrData[i].po_fins.forEach((fin) => {
      var row = clone(obj);
      row['FIN'] = fin.fin;
      row['Item Description'] = fin.description;
      row['Quantity'] = fin.quantity;
      data.push(row);
    });
  }
  return data;
}
function getDataForActiveTOForExcel(arrData) {
  var data = [];

  for (let i = 0; i < arrData.length; i++) {
    var obj = {};
    obj['ETA'] = arrData[i].receipt_date
      ? moment(arrData[i].receipt_date).format('DD-MMM-YYYY')
      : '';
    obj['TO No.'] = arrData[i].to_number.toString();
    obj['To Warehouse'] = arrData[i].to_warehouse.toString();
    obj['Line No.'] = null;
    obj['Lot Code'] = null;
    obj['FIN'] = null;
    obj['Item Description'] = null;
    obj['Quantity'] = null;
    obj['Net Weight'] = null;
    obj['Supplier'] = arrData[i].supplier.toString();
    obj['Transfer From Country'] =
      arrData[i].transfer_from_country_code.toString();
    arrData[i].transfer_order_line_items.forEach((line_item) => {
      let row = clone(obj);
      row['Line No.'] = line_item.line_no;
      row['Lot Code'] = line_item.lot_code;
      row['FIN'] = line_item.fin;
      row['Item Description'] = line_item.description;
      row['Quantity'] = line_item.quantity;
      row['Net Weight'] = line_item.net_weight;
      data.push(row);
    });
  }
  return data;
}

function getDataForHoldPOForExcel(arrData, holds = []) {
  var data = [];
  holds = holds.sort();

  for (let i = 0; i < arrData.length; i++) {
    var obj = {};
    obj['ETA'] = arrData[i].expected_receipt_date
      ? moment(arrData[i].expected_receipt_date).format('DD-MMM-YYYY')
      : '';
    obj['Container #'] = arrData[i].container_no.toString();
    obj['PO No.'] = arrData[i].po_number.toString();
    obj['To'] = arrData[i].location.toString();
    obj['FIN'] = null;
    obj['Item Description'] = null;
    obj['Supplier/Plant'] = arrData[i].buy_from_vendor_name.toString();
    obj['Quantity'] = null;
    obj['From Country'] = arrData[i].buy_from_country_code.toString();
    holds.forEach((h) => {
      const index_ = findIndex(arrData[i]['po_holds'], function (i) {
        if (i.is_active === true && i.hold_type.name === h) return true;
        return false;
      });
      if (index_ >= 0) obj[h] = 'x';
      else obj[h] = '';
    });
    arrData[i].po_fins.forEach((fin) => {
      var row = clone(obj);
      row['FIN'] = fin.fin;
      row['Item Description'] = fin.description;
      row['Quantity'] = fin.quantity;
      data.push(row);
    });
  }
  return data;
}

/*
 * Download CSV Functions

function downloadCSVFile(fileName, data) {
  var uri = 'data:text/csv;charset=utf-8,' + escape(data);

  var link = document.createElement('a');
  link.href = uri;

  link.style = 'visibility:hidden';
  link.download = fileName + '.csv';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function getDataForActiveInventory(arrData) {
  var CSV = '';
  var headerRow = 'Location,FIN,Lot number,Quantity,Description';
  CSV += headerRow + '\r\n';

  for (let i = 0; i < arrData.length; i++) {
    var row = '';

    for (let index in arrData[i]) {
      if (arrData[0][index] !== null && typeof arrData[i][index] === 'object') {
        for (let j in arrData[i][index]) {
          row += arrData[i][index][j] + ',';
        }
      } else row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);

    CSV += row + '\r\n';
  }
  return CSV;
}

function getDataForHoldInventory(arrData, holds = []) {
  var CSV = '';
  var headerRow = 'Location,FIN,Lot number,Quantity,Description';
  holds = holds.sort();
  if (holds.length > 0) {
    holds.map((h) => (headerRow += ',' + h));
  }
  CSV += headerRow + '\r\n';

  for (let i = 0; i < arrData.length; i++) {
    var row = '';

    for (let index in arrData[i]) {
      if (arrData[0][index] !== null && typeof arrData[i][index] === 'object') {
        let r = '';
        holds.map((h) => {
          const index_ = findIndex(arrData[i][index], function (i) {
            if (i.is_active === true && i.hold_type.name === h) return true;
            return false;
          });
          if (index_ >= 0) r += 'true,';
          else r += 'false,';
          return r;
        });
        row += r;
      } else row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);

    CSV += row + '\r\n';
  }
  return CSV;
}
*/
