import { Link, withRouter } from 'react-router-dom';
import { findIndex, forEach } from 'lodash';
import { getTokenForBrowser, isInternalHMSUser } from '../../utils/auth/auth';
import { inject, observer } from 'mobx-react';

import React from 'react';
import RouteConstants from '../../constants/RouteConstants';
import { isAuthenticatedUser } from '../../utils/AuthUtils';

export default (Page, accessToRoles = []) => {
  @inject('rootStore')
  @observer
  class SecureComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: null,
        isUserAuthorized: false,
        user: null,
      };
    }

    async componentDidMount() {
      const isUserLoggedIn = isAuthenticatedUser();
      const user = await getTokenForBrowser();
      // const isUserLoggedIn = !!getTokenForBrowser();
      this.setState({
        isLoggedIn: isUserLoggedIn,
        isAdmin: false,
        user,
      });

      if (this.props.rootStore.userStore.email == null) {
        let route = RouteConstants.REDIRECT;
        if (this.props.location && this.props.location.pathname)
          route += '?redirect=' + this.props.location.pathname;
        this.props.history.replace(route);
      } else {
        const roles = this.props.rootStore.userStore.roles;
        let isUserAuthorized = false;

        forEach(accessToRoles, (roleName) => {
          if (findIndex(roles, { name: roleName }) > -1)
            isUserAuthorized = true;
        });

        this.setState({ isUserAuthorized });
      }
    }

    render() {
      const { isUserAuthorized } = this.state;

      if (!this.state.isLoggedIn) {
        return (
          <div>
            <p>
              You're not logged in to the system. Try to{' '}
              <Link to={RouteConstants.LOGIN}> Login</Link>
            </p>
          </div>
        );
      }

      if (
        (accessToRoles.length > 0 && !isUserAuthorized) ||
        !isInternalHMSUser(this.state.user)
      ) {
        return (
          <div>
            <p>You're not authorised to view this page.</p>
          </div>
        );
      }

      return <Page {...this.state} {...this.props} />;
    }
  }

  return withRouter(SecureComponent);
};
