import auth0 from 'auth0-js';
import { deleteToken } from './auth';

const clientID = process.env.REACT_APP_AUTH0_CLIENTID;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;

function webAuth(clientID, domain) {
  return new auth0.WebAuth({
    clientID: clientID,
    domain: domain,
  });
}

function login(email, password, callback) {
  const options = {
    responseType: 'token id_token',
    // connection: process.env.REACT_APP_AUTH0_CONNECTION,
    // scope: 'email',
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  };

  return webAuth(clientID, domain).authorize(options, callback);
}

function parseHash(cb) {
  return webAuth(clientID, domain).parseHash(cb);
}

function logout(redirectUrl) {
  if (!redirectUrl) redirectUrl = process.env.REACT_APP_LOGOUT_RETURN_URL;
  deleteToken();
  return webAuth(clientID, domain).logout({ returnTo: redirectUrl });
}

// function changePassword(email, callback) {
//   return webAuth(clientID, domain).changePassword(
//     {
//       connection: process.env.REACT_APP_AUTH0_REALM,
//       email: email,
//     },
//     callback
//   );
// }

export { login, parseHash, logout };
