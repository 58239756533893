/* eslint-disable array-callback-return */
import { Button, Col, Row, Select, Space } from 'antd';
import React, { Component } from 'react';
import { findIndex, isEmpty, isUndefined, sortBy, trim } from 'lodash';

class TOFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: undefined,
      to: undefined,
      fin: undefined,
      vendor: undefined,
      holdType: undefined,
    };
  }

  resetFilters = () => {
    this.setState({
      location: undefined,
      to: undefined,
      fin: undefined,
      vendor: undefined,
      holdType: undefined,
    });
    this.props.onReset();
  };

  applyFilter = () => {
    let { location, to, fin, vendor, holdType } = this.state;
    this.props.onSubmit({
      location,
      to,
      fin,
      vendor,
      holdType,
    });
  };

  canFinBeDisplayed = (fin) => {
    const { fins } = this.props;
    const { location, to } = this.state;
    if (!isEmpty(location) && findIndex(fins[fin], { location }) === -1)
      return false;
    else if (!isEmpty(to) && findIndex(fins[fin], { po_number: to }) === -1)
      return false;
    return true;
  };

  canVendorBeDisplayed = (vendorName) => {
    const { vendors } = this.props;
    const { location, to, fin } = this.state;
    if (
      !isEmpty(location) &&
      findIndex(vendors[vendorName], { location }) === -1
    )
      return false;
    else if (
      !isEmpty(to) &&
      findIndex(vendors[vendorName], { po_number: to }) === -1
    )
      return false;
    else if (!isEmpty(fin) && findIndex(vendors[vendorName], { fin }) === -1)
      return false;
    return true;
  };

  render() {
    const { locations, tos, fins, holdTypes } = this.props;
    const { location, to, fin, holdType } = this.state;

    return (
      <Row>
        <Col xs={0} sm={24}>
          <Space>
            <Select
              showSearch
              value={location}
              style={{ width: 150 }}
              placeholder='To Warehouse'
              onChange={(value) => this.setState({ location: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {locations.sort().map(
                (l, index) =>
                  l && (
                    <Select.Option value={l} key={index}>
                      {l}
                    </Select.Option>
                  )
              )}
            </Select>
            <Select
              showSearch
              value={to}
              style={{ width: 150 }}
              placeholder='TO'
              onChange={(value) => this.setState({ to: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {Object.keys(tos)
                .sort()
                .map((to, index) => {
                  if (isEmpty(location) || tos[to].includes(location))
                    return (
                      <Select.Option value={to} key={index}>
                        {to}
                      </Select.Option>
                    );
                })}
            </Select>
            <Select
              showSearch
              value={fin}
              style={{ width: 150 }}
              placeholder='FIN'
              onChange={(value) => this.setState({ fin: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {Object.keys(fins)
                .sort()
                .map((fin, index) => {
                  if (this.canFinBeDisplayed(fin))
                    return (
                      <Select.Option value={fin} key={index}>
                        {fin}
                      </Select.Option>
                    );
                })}
            </Select>
            {/* <Select
              showSearch
              value={vendor}
              style={{ width: 150 }}
              placeholder='Supplier'
              onChange={(value) => this.setState({ vendor: value })}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(trim(input.toLowerCase())) >= 0
              }
            >
              {Object.keys(vendors)
                .sort()
                .map((vendor, index) => {
                  if (this.canVendorBeDisplayed(vendor))
                    return (
                      <Select.Option value={vendor} key={index}>
                        {vendor}
                      </Select.Option>
                    );
                })}
            </Select> */}

            {!isEmpty(holdTypes) && (
              <Select
                showSearch
                value={holdType}
                style={{ width: 150 }}
                placeholder='Select Hold Type'
                onChange={(value) => {
                  this.setState({ holdType: value });
                }}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(trim(input.toLowerCase())) >= 0
                }
              >
                {sortBy(holdTypes, 'name').map((holdType_, index) => {
                  return (
                    <Select.Option value={holdType_.id} key={index}>
                      {holdType_.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}

            <Button
              type='primary'
              disabled={
                isEmpty(location) &&
                isEmpty(to) &&
                isEmpty(fin) &&
                // isEmpty(vendor) &&
                isUndefined(holdType)
              }
              onClick={this.applyFilter}
            >
              Apply Filter
            </Button>
          </Space>
          <Button
            type='link'
            danger
            disabled={
              isEmpty(location) &&
              isEmpty(to) &&
              isEmpty(fin) &&
              // isEmpty(vendor) &&
              isUndefined(holdType)
            }
            onClick={this.resetFilters}
          >
            Reset
          </Button>
        </Col>
      </Row>
    );
  }
}

export default TOFilterComponent;
