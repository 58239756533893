import { Button, Modal, Typography, message } from 'antd';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import HttpConstants from '../constants/HttpConstants';
import LoadingComponent from '../app/components/Loading';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

@inject('rootStore')
@observer
class TempReleaseAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,

      message: null,

      loading: true,
    };
  }

  componentDidMount = () => {
    const data = {
      key: this.props.match.params.key,
    };

    if (data.key) {
      makeApiCallWithAuthentication(
        UrlConstants.TEMP_RELEASE_ACTION.USECASE,
        HttpConstants.GET_METHOD,
        data
      )
        .then((response) => {
          if (response.status === 200 && response.data.isSuccess) {
            message.success(response.data.message);

            this.setState({
              modalVisible: true,
              message: response.data.message,
            });
          } else throw response;
        })
        .catch((err) => {
          console.log(err);
          let errorMessage = 'Cannot cature action, please refresh the page';
          if (err.data && err.data.message) errorMessage = err.data.message;
          message.error(errorMessage);
          this.setState({ message: errorMessage });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ key: 'No Lot found to action' });
    }
  };

  render() {
    const { loading, modalVisible, message } = this.state;

    if (loading)
      return (
        <div className='full-height'>
          <LoadingComponent tip='Capturing your action...'></LoadingComponent>
        </div>
      );

    if (modalVisible)
      return (
        <Modal
          visible={true}
          title='Temporary Release Action Captured'
          maskClosable={false}
          closable={false}
          destroyOnClose={true}
          footer={[
            <Button
              key='submit'
              type='primary'
              loading={loading}
              onClick={() =>
                this.setState({ modalVisible: false, loading: false })
              }
            >
              Ok
            </Button>,
          ]}
        >
          <Typography.Text>{message}</Typography.Text>
        </Modal>
      );
    else return <Typography.Text>{message}</Typography.Text>;
  }
}

export default withRouter(TempReleaseAccess);
