import {
  Button,
  Col,
  Descriptions,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import React, { Component } from 'react';
import {
  filter,
  forEach,
  isEmpty,
  pick,
  sortBy,
  uniq,
  upperCase,
} from 'lodash';
import {
  getInventoryStatusColor,
  hashCode,
  intToRGB,
} from '../../utils/colorUtils';

import { DownloadOutlined } from '@ant-design/icons';
import LotFilterCommponent from '../../app/components/filter/LotFilter';
import NumberFormat from 'react-number-format';
import StringConstants from '../../constants/StringConstants';
import cookie from 'react-cookies';
import { downloadExcelForExpiringLots } from '../../utils/excelUtils';
import moment from 'moment';

class ExpiringLotsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoading: false,
      visibleData: [],
      inventoryLocations: [],
      itemNumbers: [],
      lotCodes: [],
      descriptions: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const expiryFilters = cookie.load(
      StringConstants.COOKIES.EXPIRY_REPORT_SCREEN
    );

    if (expiryFilters && Object.keys(expiryFilters).length > 1) {
      this.applyFilter(expiryFilters, false);
      this.filterComponent.prefillFilters(expiryFilters);
    } else {
      this.processDataForFilters(data);
      this.setState({ visibleData: data });
    }
  }

  processDataForFilters = (inventoryList) => {
    this.setState({ tableLoading: true });
    let locations = inventoryList.map((i) => i.location);
    locations = uniq(locations);

    const itemNumbers = {};
    inventoryList.map((i) => {
      if (!itemNumbers[i.fin]) {
        itemNumbers[i.fin] = [];
      }
      return uniq(itemNumbers[i.fin].push(i.location));
    });

    let lotCodes = [],
      descriptions = {};
    inventoryList.forEach((i) => {
      lotCodes.push({ lotCode: i.lot_code, fin: i.fin, location: i.location });

      if (!descriptions[i.description]) descriptions[i.description] = [];
      uniq(
        descriptions[i.description].push({
          lotCode: i.lot_code,
          fin: i.fin,
          location: i.location,
        })
      );
    });

    lotCodes = uniq(lotCodes);

    this.setState({
      inventoryLocations: locations,
      itemNumbers,
      lotCodes,
      descriptions,
      tableLoading: false,
    });
  };

  applyFilter = (filtersApplied, saveCookie = true) => {
    const { location, fin, lot_code, description } = filtersApplied;
    const { data, activeTabIndex } = this.props;
    this.setState({ loading: true });
    let filter_ = {};
    if (!isEmpty(location)) filter_.location = location;
    if (!isEmpty(fin)) filter_.fin = fin;
    if (!isEmpty(lot_code)) filter_.lot_code = lot_code;
    if (!isEmpty(description)) filter_.description = description;
    const list = filter(data, filter_);
    //Cookie doesnt have to be saved if the filters are prefetched from cookies on page load
    if (saveCookie)
      cookie.save(StringConstants.COOKIES.EXPIRY_REPORT_SCREEN, {
        tab: activeTabIndex,
        ...filtersApplied,
      });

    this.processDataForFilters(list);
    this.setState({ visibleData: list, loading: false });
  };

  resetFilter = () => {
    const { data, activeTabIndex } = this.props;
    this.processDataForFilters(data);
    this.setState({ visibleData: data });
    cookie.save(StringConstants.COOKIES.EXPIRY_REPORT_SCREEN, {
      tab: activeTabIndex,
    });
  };

  downloadData = () => {
    this.setState({ tableLoading: true });
    const { visibleData } = this.state;
    let data = [];
    forEach(visibleData, function (i) {
      data.push(
        pick(i, [
          'po_number',
          'location',
          'fin',
          'lot_code',
          'quantity',
          'description',
          'cost_per_unit',
          'item_net_weight',
          'total_cost',
          'total_weight',
          'status',
          'expiration_date',
        ])
      );
    });

    downloadExcelForExpiringLots(data, this.props.expirationText);
    this.setState({ tableLoading: false });
  };

  getColumnsForTable() {
    return [
      {
        key: 'location',
        dataIndex: 'location',
        title: 'Location',
        width: 160,
        sorter: (a, b) => a.location.localeCompare(b.location),
        fixed: 'left',
      },
      {
        key: 'fin',
        dataIndex: 'fin',
        title: 'FIN',
        width: 160,
        sorter: (a, b) => a.fin.localeCompare(b.fin),
        defaultSortOrder: 'ascend',
        fixed: 'left',
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
        ellipsis: {
          showTitle: false,
        },
        responsive: ['md'],
        render: (description) => (
          <Tooltip placement='topLeft' title={description}>
            {description}
          </Tooltip>
        ),
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        key: 'lot_code',
        dataIndex: 'lot_code',
        title: 'Lot Number',
        width: 160,
        sorter: (a, b) => a.lot_code.localeCompare(b.lot_code),
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: 'Quantity',
        width: 120,
        render: (quantity) => (
          <NumberFormat
            value={quantity}
            displayType={'text'}
            thousandSeparator={true}
          />
        ),
        sorter: (a, b) => a.quantity - b.quantity,
      },
      {
        key: 'total_weight',
        title: 'Total Weight',
        width: 120,
        render: ({ item_net_weight, total_weight }) => (
          <Tooltip title={`Item Net Weight : ${item_net_weight}`}>
            <NumberFormat
              value={total_weight}
              displayType={'text'}
              thousandSeparator={true}
            />
          </Tooltip>
        ),
        sorter: (a, b) => a.total_weight - b.total_weight,
      },
      {
        key: 'total_cost',
        title: 'Cost',
        width: 120,
        render: ({ cost_per_unit, total_cost }) => (
          <Tooltip title={`Cost/unit : ${cost_per_unit}`}>
            <NumberFormat
              prefix='$'
              value={total_cost}
              displayType={'text'}
              thousandSeparator={true}
            />
          </Tooltip>
        ),
        sorter: (a, b) => a.total_cost - b.total_cost,
      },
      {
        key: 'expiration_date',
        dataIndex: 'expiration_date',
        title: 'Expiry Date',
        width: 150,
        render: (expiration_date) =>
          expiration_date && moment(expiration_date).format('ll'),
        sorter: (a, b) =>
          moment(a.expiration_date).diff(moment(b.expiration_date)),
      },
      {
        key: 'id',
        title: 'Status/Holds',
        render: (record) => {
          if (
            record.status === StringConstants.ACTIVE_STATUS ||
            record.status === StringConstants.TEMP_ACTIVE_STATUS
          ) {
            return (
              <Tag color={getInventoryStatusColor(record.status)}>
                {upperCase(record.status)}
              </Tag>
            );
          } else {
            return sortBy(record.inventory_holds, 'hold_type.name').map(
              (hold_, index) => {
                if (hold_.hold_type && hold_.is_active) {
                  return (
                    <Tooltip title={hold_.message} key={index}>
                      <Tag
                        color={intToRGB(hashCode(hold_.hold_type.name))}
                        style={{ margin: 2 }}
                      >
                        {hold_.hold_type.name}
                      </Tag>
                    </Tooltip>
                  );
                } else return null;
              }
            );
          }
        },
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
      {
        key: 'id',
        title: '',
        render: (data) => {
          return (
            <Space>
              <Button
                type='dashed'
                onClick={() => this.props.handleViewDetails(data.id)}
              >
                View Details
              </Button>
            </Space>
          );
        },
      },
    ];
  }

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'lot_code',
        dataIndex: 'lot_code',
        title: 'Lot Number',
        sorter: (a, b) => a.lot_code.localeCompare(b.lot_code),
      },
      {
        key: 'expiration_date',
        dataIndex: 'expiration_date',
        title: 'Expiry Date',
        render: (expiration_date) => moment(expiration_date).format('ll'),
        sorter: (a, b) => moment(a.expiration_table).diff(moment(b.lot_code)),
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <div className='text-center'>
        <Descriptions bordered>
          <Descriptions.Item label='Location'>
            {record.location}
          </Descriptions.Item>
          <Descriptions.Item label='FIN'>{record.fin}</Descriptions.Item>

          <Descriptions.Item label='Description'>
            {record.description}
          </Descriptions.Item>
          <Descriptions.Item label='Quantity'>
            <NumberFormat
              value={record.quantity}
              displayType={'text'}
              thousandSeparator={true}
            />
          </Descriptions.Item>
          <Descriptions.Item label='Total Weight'>
            <Tooltip title={`Item Net Weight : ${record.item_net_weight}`}>
              <NumberFormat
                value={record.total_weight}
                displayType={'text'}
                thousandSeparator={true}
              />
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label='Cost'>
            <Tooltip title={`Cost/unit : ${record.cost_per_unit}`}>
              <NumberFormat
                prefix='$'
                value={record.total_cost}
                displayType={'text'}
                thousandSeparator={true}
              />
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label='Status'>
            <Tag color={getInventoryStatusColor(record.status)}>
              {upperCase(record.status)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
        <Button
          type='dashed'
          onClick={() => this.props.handleViewDetails(record.id)}
          style={{ marginTop: 5 }}
        >
          View Details
        </Button>
      </div>
    );
  };

  render() {
    const { emptyText, loading } = this.props;
    const {
      tableLoading,
      visibleData,
      inventoryLocations,
      itemNumbers,
      lotCodes,
      descriptions,
    } = this.state;

    return (
      <div className='space-top-10'>
        <Row className='space-top' gutter={[8, 8]}>
          <Col xs={24} md={23}>
            <LotFilterCommponent
              locations={inventoryLocations}
              itemNumbers={itemNumbers}
              lotCodes={lotCodes}
              descriptions={descriptions}
              onSubmit={this.applyFilter}
              onReset={this.resetFilter}
              ref={(ref) => (this.filterComponent = ref)}
            />
          </Col>
          <Col xs={0} sm={24} md={1} style={{ textAlign: 'end' }}>
            <Tooltip title='Export as Excel'>
              <Button
                shape='circle'
                onClick={this.downloadData}
                loading={tableLoading}
                disabled={loading || isEmpty(visibleData)}
              >
                <DownloadOutlined />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Row className='space-top'>
          <Col xs={0} md={24}>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={visibleData}
              loading={loading || tableLoading}
              columns={this.getColumnsForTable()}
              locale={{
                emptyText: emptyText ? emptyText : 'No Inventory Available',
              }}
              rowKey={(record) => record.id}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Col>
          <Col xs={24} md={0}>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={visibleData}
              loading={loading || tableLoading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: emptyText ? emptyText : 'No Inventory Available',
              }}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ExpiringLotsTable;
