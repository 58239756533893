import { Button, Form, Input, Modal, Switch } from 'antd';
import React, { Component } from 'react';

import { isEmpty } from 'lodash';

class AddorEditHoldTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleOk = () => {
    this.holdFormRef
      .validateFields()
      .then((data) => {
        this.props.onSubmit(data);
      })
      .catch((err) => {
        console.log('*****', err);
      });
  };

  render() {
    const { loading, visible, selectedData } = this.props;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    console.log(selectedData);
    return (
      <Modal
        visible={visible}
        title={isEmpty(selectedData) ? 'Add Hold Type' : 'Update Hold Type'}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={this.handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={this.handleOk}
          >
            {isEmpty(selectedData) ? 'Add Hold Type' : 'Update Hold Type'}
          </Button>,
        ]}
      >
        <Form
          {...layout}
          ref={(ref) => (this.holdFormRef = ref)}
          initialValues={selectedData}
        >
          <Form.Item
            name='name'
            label='Hold Type'
            rules={[
              {
                required: true,
                message: 'Please enter a name',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='warehouse_holdtype'
            label='Warehouse Hold Type'
            rules={[
              {
                required: true,
                message: 'Please enter a warehouse hold type',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='description'
            label='Description'
            rules={[
              {
                required: true,
                message: 'Please enter a description',
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name='not_planned'
            label='Not Planned For'
            valuePropName='checked'
            rules={[
              {
                required: true,
                message: 'Please Select a value',
              },
            ]}
          >
            <Switch checkedChildren='Yes' unCheckedChildren='No' />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default AddorEditHoldTypes;
