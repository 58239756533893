export const msalConfig = {
  auth: {
    clientId: '14eb7025-0c72-4828-abbd-ebc9ed7f234a',
    authority:
      'https://login.microsoftonline.com/dd57e4e2-8a6c-4d67-9745-c2c76aba2b8f',
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
