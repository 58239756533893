import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import React, { Component } from 'react';

import AddNewAutoHoldRule from './autoHoldRules/AddNewAHRuleModal';
import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import RoleConstants from '../constants/RoleConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import generateRuleStringForAutoHold from '../utils/autoHoldRuleUtils';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import moment from 'moment';
import { withRouter } from 'react-router';

class AutoHoldRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      addRuleModalVisible: false,
      rules: [],
      holdTypes: [],
      fins: [],
      countries: [],
      locations: [],
    };
  }

  componentDidMount() {
    this.getAutoHoldRules();
    this.getDropdownValues();
  }

  getDropdownValues = () => {
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_HOLD_TYPES_AND_FINS.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            modalLoading: false,
            holdTypes: response.data.hold_types,
            fins: response.data.fins,
            countries: response.data.country_list,
            locations: response.data.location_codes,
          });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ modalLoading: false });
      });
  };

  getAutoHoldRules = () => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.GET_ALL_AUTO_HOLD_RULES.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ loading: false, rules: response.data });
        } else throw response;
      })
      .catch((err) => this.setState({ loading: false }));
  };

  handleCancel = () => {
    this.setState({
      modalLoading: false,
      addRuleModalVisible: false,
    });
  };

  handleSubmit = (data) => {
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.ADD_AUTO_HOLD_RULE.USECASE,
      HttpConstants.POST_METHOD,
      data
    )
      .then((response) => {
        if (response.status === 200) {
          message.success('New Auto hold rule added');
          this.setState({ modalLoading: false, addRuleModalVisible: false });
          this.getAutoHoldRules();
        } else throw response;
      })
      .catch((error) => {
        let errorMessage = 'Could not add new rule';
        if (error.data && error.data.message) {
          errorMessage = error.data.message;
        }

        message.error(errorMessage);
        this.setState({ modalLoading: false });
      });
  };
  
  renderEditNotesContent = (editingNotes) => (
	  <Form
	    ref={this.formRef}
	    initialValues={{ notes: editingNotes }}
	    onValuesChange={(changedValues) =>
	      this.handleUpdatedNotes(changedValues.notes)
	    }
	  >
	    <Form.Item
	      name="notes"
	      rules={[{ required: true, message: "Please enter notes!" }]}
	      style={{ marginLeft: "-30px", marginTop: "20px" }}
	    >
	      <Input.TextArea rows={4} />
	    </Form.Item>
	  </Form>
	);

	handleUpdatedNotes = (updatedNotes) => {
	  this.setState({ editingNotes: updatedNotes });
	};
	
	editRuleNotes = (ruleId, editingNotes) => {
	  console.log(ruleId + editingNotes);
	  Modal.confirm({
	    title: "Edit Notes",
	    icon: <EditOutlined />,
	    content: this.renderEditNotesContent(editingNotes),
	    okText: "Save",
	    okButtonProps: { type: "primary" },
	    cancelText: "Cancel",
	    onOk: () => this.submitNotes(ruleId, editingNotes),
	  });
	};

	submitNotes = (ruleId) => {
	  const { editingNotes } = this.state;
	  this.setState({ loading: true });
	  makeApiCallWithAuthentication(
	    UrlConstants.UPDATE_NOTES_AUTO_HOLD_RULE.USECASE,
	    HttpConstants.PUT_METHOD,
	    {
	      $id: ruleId,
	      $notes: editingNotes,
	    }
	  )
	    .then((response) => {
	      if (response.status === 200) {
	        message.success("Notes updated successfully");
	        this.getAutoHoldRules();
	        this.setState({ loading: false });
	      } else {
	        throw response;
	      }
	    })
	    .catch(() => {
	      message.error("Could not update notes.");
	      this.setState({ loading: false });
	    });
	};
  
  confirmRuleDeletion = (ruleId) => {
    Modal.confirm({
      title: 'Confirm Rule Deletion',
      icon: <WarningOutlined />,
      content: (
        <div>
          <Typography.Text type='secondary'>
            Are you sure you want to delete the rule ?
          </Typography.Text>
        </div>
      ),
      okText: 'Yes, Delete',
      okButtonProps: { danger: true },
      cancelText: 'Cancel',
      onOk: () => this.deleteRule(ruleId),
    });
  };

  deleteRule = (ruleId) => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.DELETE_AUTO_HOLD_RULE.USECASE,
      HttpConstants.DELETE_METHOD,
      {
        $id: ruleId,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          message.success('Rule deleted successfully ');
          this.getAutoHoldRules();
          this.setState({ loading: false });
        } else {
          throw response;
        }
      })
      .catch(() => {
        message.error('Could not delete rule');
        this.setState({ loading: false });
      });
  };

  getColumnsForTable = () => {
    const columns = [
      {
        key: 'id',
        title: 'Rule',
        render: (data) => generateRuleStringForAutoHold(data),
      },
      {
        key: 'user',
        dataIndex: 'user',
        title: 'Created By',
        render: (user) => <span>{user.name}</span>,
      },
      {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: 'Created At',
        render: (createdAt) => moment(createdAt).format('lll'),
        sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
        defaultSortOrder: 'descend',
      },
		{
		  key: 'notes',
		  dataIndex: 'notes',
		  title: 'Notes',
		  render: (text, record) => (
		    <Space>
		      <Tooltip title={record.notes}>
		        <span>
		          {record.notes && record.notes.length > 10
		            ? `${record.notes.substring(0, 10)}...`
		            : record.notes}
		        </span>
		      </Tooltip>
		      <Tooltip title='Edit Notes'>
		        <Button
		          shape='circle'
		          type='primary'
		          className='anticon anticon-edit'
		          onClick={() => this.editRuleNotes(record.id, record.notes)}
		        >
		          <EditOutlined />
		        </Button>
		      </Tooltip>
		    </Space>
		  ),
		},

      {
        key: 'id',
        dataIndex: 'id',
        render: (id) => (
          <Tooltip title='Delete Rule'>
            <Button
              shape='circle'
              type='primary'
              danger
              onClick={() => this.confirmRuleDeletion(id)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        ),
      },
    ];
    return columns;
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'id',
        title: 'Rule',
        render: (data) => generateRuleStringForAutoHold(data),
        sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
        defaultSortOrder: 'descend',
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <Descriptions bordered>
        <Descriptions.Item label='Created By'>
          {record.user.name}
        </Descriptions.Item>
        <Descriptions.Item label='Created At'>
          {record.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label='Notes'>
          {record.notes}
        </Descriptions.Item>
        <Descriptions.Item label='Delete Rule'>
          <Button
            type='primary'
            shape='circle'
            danger
            onClick={() => this.confirmRuleDeletion(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </Descriptions.Item>
      </Descriptions>
    );
  };

  render() {
    const {
      loading,
      modalLoading,
      addRuleModalVisible,
      holdTypes,
      fins,
      countries,
      locations,
      rules,
      editingNotes
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Rules for auto holds</title>
          <meta name='description' content='Rules for Auto holds' />
        </Helmet>
        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Auto Hold Items
            </Typography.Text>
          </Col>
          <Col xs={24} className='space-top-10' style={{ textAlign: 'right' }}>
            <Button
              type='primary'
              onClick={() => this.setState({ addRuleModalVisible: true })}
              disabled={loading || modalLoading}
            >
              <PlusSquareOutlined />
              Add new rule
            </Button>
          </Col>
          <Col xs={0} md={24} className='space-top'>
            <Table
              columns={this.getColumnsForTable()}
              dataSource={rules}
              loading={loading}
              locale={{
                emptyText: 'No Rules Available',
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} rules`,
              }}
              rowKey={(row) => row.id}
              scroll={{ x: 'fit-content' }}
            />
          </Col>
          <Col xs={24} md={0} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={rules}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Rules Available',
              }}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} rules`,
              }}
            />
          </Col>
          <AddNewAutoHoldRule
            loading={modalLoading}
            allHolds={holdTypes}
            allFins={fins}
            allCountries={countries}
            allLocations={locations}
            visible={addRuleModalVisible}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(AutoHoldRules), [
  RoleConstants.SUPER_USER,
  RoleConstants.AUTO_HOLDS_MANAGER,
]);
