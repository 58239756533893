import StringConstants from '../constants/StringConstants';

export function hashCode(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 4) - hash - 2);
    //hash = str.charCodeAt(i) + ((hash << 1) + hash + 2);
  }
  return hash;
}

export function intToRGB(hash) {
  // var c = (i & 0x001fffff).toString(16).toUpperCase();

  // const hex = '00000'.substring(0, 6 - c.length) + c;
  let hex = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    hex += ('00' + value.toString(16)).substr(-2);
  }
  return hex;
}

export function getInventoryStatusColor(status) {
  switch (status) {
    case StringConstants.ACTIVE_STATUS:
      return 'green';
    case StringConstants.TEMP_ACTIVE_STATUS:
      return 'orange';
    default:
      return 'red';
  }
}
