import HoldTypesUsersStore from './HoldTypesUsersStore';
import UserStore from './UserStore';

class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.holdTypesUsersStore = new HoldTypesUsersStore(this);
  }
}
const rootStore = new RootStore();

export default rootStore;
