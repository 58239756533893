import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Radio,
  Row,
  Tabs,
  message,
} from 'antd';
import React, { Component } from 'react';
import { capitalize, findIndex, isEmpty, trim } from 'lodash';

import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import InventoryDetailsView from './shared/InventoryDetailsView';
import LoadingComponent from '../app/components/Loading';
import PoDetailsView from './shared/PoDetailsView';
import SecureComponent from '../app/components/SecureComponent';
import StringConstants from '../constants/StringConstants';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      locations: [],
      inventoryDetails: [],
      holdTypes: [],
      empty: false,
      selectedAttribute: StringConstants.SEARCH_BY_LOT,
      inventoryDetailsType: StringConstants.SEARCH_BY_LOT,
    };
  }

  resetFilters = () => {
    this.searchForm.resetFields();
    this.setState({ inventoryDetails: [], empty: false });
  };

  performSearch = (data) => {
    const { selectedAttribute } = this.state;
    const value = this.searchForm.getFieldValue('searchNumber');
    let usecase = UrlConstants.SEARCH_PO.USECASE;
    if (selectedAttribute === StringConstants.SEARCH_BY_LOT) {
      data = { lot_code: trim(value) };
      usecase = UrlConstants.SEARCH_INVENTORY.USECASE;
    } else data = { key: selectedAttribute, value };

    this.setState({
      inventoryDetails: [],
      inventoryDetailsType: selectedAttribute,
    });
    this.fetchSearchResults(usecase, data);
  };

  fetchSearchResults = (usecase, data) => {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(usecase, HttpConstants.GET_METHOD, data)
      .then((response) => {
        if (response.status === 200) {
          let inventoryDetails = response.data.inventory_list;
          if (!inventoryDetails) inventoryDetails = response.data.po_list;
          const holdTypes = response.data.hold_type_list;
          this.setState({
            inventoryDetails,
            holdTypes,
            loading: false,
            empty: false,
          });
        } else throw response;
      })
      .catch((err) => {
        message.error('No ' + this.state.inventoryDetailsType + ' Found');
        this.setState({ loading: false, inventoryDetails: [], empty: true });
      });
  };

  refreshPO = (poNumber, containerNumber) => {
    const { inventoryDetailsType } = this.state;
    let value = poNumber;
    if (inventoryDetailsType === StringConstants.SEARCH_BY_CONTAINER)
      value = containerNumber;
    const data = {
      key: inventoryDetailsType,
      value: value,
    };
    this.fetchSearchResults(UrlConstants.SEARCH_PO.USECASE, data);
  };

  refreshInventory = (lotNumber) => {
    const data = { lot_code: lotNumber };
    this.fetchSearchResults(UrlConstants.SEARCH_INVENTORY.USECASE, data);
  };

  updateSelectedAttribute = (event) => {
    this.setState({ selectedAttribute: event.target.value });
  };

  toggleWatchFlag = (code, isPo) => {
    let condition = { lot_code: code };
    if (isPo) condition = { po_number: code };

    const index = findIndex(this.state.inventoryDetails, condition);
    if (index > -1) {
      let inventoryDetails = [...this.state.inventoryDetails];
      let inv = { ...inventoryDetails[index] };
      inventoryDetails[index] = { ...inv, is_watched: !inv.is_watched };
      this.setState({ inventoryDetails });
    }
  };

  render() {
    const {
      loading,
      inventoryDetails,
      holdTypes,
      empty,
      selectedAttribute,
      inventoryDetailsType,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>HMS - Search Lots/POs</title>
          <meta name='description' content='Search Lots and Purchase Orders' />
        </Helmet>

        <Row>
          <Col xs={24}>
            <Card bordered={false}>
              <Row gutter={[8, 16]}>
                <Col xs={24} className='text-center'>
                  <Radio.Group
                    defaultValue={selectedAttribute}
                    onChange={(event) => this.updateSelectedAttribute(event)}
                    value={selectedAttribute}
                  >
                    <Radio value={StringConstants.SEARCH_BY_LOT}>
                      Lot Number
                    </Radio>
                    <Radio value={StringConstants.SEARCH_BY_PO}>
                      PO Number
                    </Radio>
                    <Radio value={StringConstants.SEARCH_BY_CONTAINER}>
                      Container
                    </Radio>
                    <Radio value={StringConstants.SEARCH_BY_PO_REFERENCE}>
                      Contract number
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col xs={24}>
                  <Form
                    style={{ justifyContent: 'center' }}
                    name='horizontal_login'
                    layout='inline'
                    onFinish={this.performSearch}
                    ref={(ref) => (this.searchForm = ref)}
                  >
                    <Form.Item
                      name='searchNumber'
                      style={{ marginBottom: 5, marginRight: 5 }}
                      rules={[
                        {
                          required: true,
                          message: `Please enter a ${selectedAttribute} code`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${capitalize(selectedAttribute)} Number`}
                        disabled={loading}
                      />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 5, marginRight: 5 }}>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                      >
                        Search
                      </Button>
                      <Button
                        type='link'
                        danger
                        disabled={isEmpty(inventoryDetails)}
                        onClick={this.resetFilters}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Divider />
              {loading ? (
                <LoadingComponent tip='Loading...' />
              ) : empty ? (
                <Empty description='No Data Available' />
              ) : (
                inventoryDetails.length > 0 && (
                  <Tabs>
                    {inventoryDetailsType === StringConstants.SEARCH_BY_LOT
                      ? inventoryDetails.map((inventory, index) => {
                          return (
                            <Tabs.TabPane tab={inventory.lot_code} key={index}>
                              <InventoryDetailsView
                                inventoryDetails={inventory}
                                holdTypes={holdTypes}
                                refreshData={this.refreshInventory}
                                toggleWatchFlag={this.toggleWatchFlag}
                                enableShare={true}
                              />
                            </Tabs.TabPane>
                          );
                        })
                      : inventoryDetails.map((po, index) => {
                          return (
                            <Tabs.TabPane tab={po.po_number} key={index}>
                              <PoDetailsView
                                poDetails={po}
                                holdTypes={holdTypes}
                                refreshData={this.refreshPO}
                                toggleWatchFlag={this.toggleWatchFlag}
                                enableShare={true}
                              />
                            </Tabs.TabPane>
                          );
                        })}
                  </Tabs>
                )
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(Search));
