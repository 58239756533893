import './index.css';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { Helmet } from 'react-helmet';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'mobx-react';
import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { msalConfig } from './utils/auth/auth0config';
import rootStore from './app/datastore';

const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
  <Provider rootStore={rootStore}>
    <MsalProvider instance={msalInstance}>
      <Helmet>
        <title>Holds Management System | Fishinco</title>
        <meta
          name='description'
          content='Hold/Unhold Lots and Purchase Orders'
        />
      </Helmet>

      <App />
    </MsalProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
