import {
  Button,
  Col,
  Descriptions,
  Empty,
  PageHeader,
  Row,
  Space,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  KeyOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import React, { Component } from 'react';
import { find, forEach, isEmpty, sortBy, upperCase } from 'lodash';
import {
  getInventoryStatusColor,
  hashCode,
  intToRGB,
} from '../../utils/colorUtils';
import { inject, observer } from 'mobx-react';

import HoldUnholdModal from './HoldUnholdModal';
import HttpConstants from '../../constants/HttpConstants';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import RoleConstants from '../../constants/RoleConstants';
import RouteConstants from '../../constants/RouteConstants';
import StringConstants from '../../constants/StringConstants';
import TempReleaseRequestModal from './inventoryDetailsView/TempReleaseRequestModal';
import UrlConstants from '../../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../../api/ApiManager';
import moment from 'moment';

const actionAllowedStates = [
  StringConstants.ACTIVE_STATUS,
  StringConstants.ONHOLD_STATUS,
];

@inject('rootStore')
@observer
class InventoryDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      holdManagementModalVisible: false,
      tempReleaseModalVisible: false,
    };
  }

  handlePutOnHoldCancel = () => {
    this.setState({
      modalLoading: false,
      holdManagementModalVisible: false,
      inventory: {},
    });
  };

  //This is used while updating just watch flag from modal
  handleUpdateWatchStatus = async (inventoryObj, flag) => {
    const { inventoryDetails } = this.props;

    const payload = {
      type: StringConstants.WATCHERS.TYPE.LOT,
      action: !flag
        ? StringConstants.WATCHERS.ACTION.STOP_WATCHING
        : StringConstants.WATCHERS.ACTION.START_WATCHING,
      identifier: inventoryDetails.id,
    };
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.WATCHER_ACTIVITY.USECASE,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          message.success(response.data.message);
          this.props.toggleWatchFlag(inventoryDetails.lot_code, false);
          this.setState({
            holdManagementModalVisible: false,
            modalLoading: false,
          });
        } else throw response;
      })
      .catch((err) => {
        let errMessage = 'Error occured while performing the action';
        if (err && err.data && err.data.message) errMessage = err.data.message;
        message.error(errMessage);
        this.setState({
          holdManagementModalVisible: false,
          modalLoading: false,
        });
      });
  };

  handlePutOnHoldSubmit = async (
    inventoryObj,
    addedHolds,
    removedHolds,
    comment,
    watch
  ) => {
    this.setState({ modalLoading: true });
    const params = {
      lot_code: inventoryObj.lot_code,
      po: inventoryObj.po_number,
      location: inventoryObj.location,
      fin: inventoryObj.fin,
      id: inventoryObj.id,
      action: 'HOLD',
      holds: [],
      watch,
    };
    forEach(addedHolds, function (holdType) {
      const holdObj = {
        type: holdType,
        message: comment,
      };
      params.holds.push(holdObj);
    });

    const removeHoldParams = {
      lot_code: inventoryObj.lot_code,
      po: inventoryObj.po_number,
      location: inventoryObj.location,
      fin: inventoryObj.fin,
      id: inventoryObj.id,
      action: 'UNHOLD',
      holds: [],
      watch,
    };
    forEach(removedHolds, function (holdType) {
      const holdObj = {
        type: holdType,
        message: comment,
      };
      removeHoldParams.holds.push(holdObj);
    });

    try {
      if (addedHolds.length > 0) {
        const response = await makeApiCallWithAuthentication(
          UrlConstants.ACTION_ON_INVENTORY.USECASE,
          HttpConstants.POST_METHOD,
          params
        );
        if (response.status === 200) {
          await this.updateRemovedHolds(removeHoldParams);
        } else {
          message.error('Could not add hold, aborting remove operation');
          this.setState({
            modalLoading: false,
          });
        }
      } else {
        await this.updateRemovedHolds(removeHoldParams);
      }
    } catch (err) {
      console.log('*******', err);
      message.error("Couldn't perform update, please try later");
      this.setState({
        modalLoading: false,
      });
    }
  };

  handleViewHoldModalClick = () => {
    this.setState({ holdManagementModalVisible: true });
  };

  updateRemovedHolds = async (removeHoldParams) => {
    if (removeHoldParams.holds.length > 0) {
      const r_response = await makeApiCallWithAuthentication(
        UrlConstants.ACTION_ON_INVENTORY.USECASE,
        HttpConstants.POST_METHOD,
        removeHoldParams
      );
      if (r_response.status === 200) {
        message.success('Successfully updated Lot !');
      } else {
        message.warn(
          'New holds added, removing existing holds failed. Try again'
        );
      }
    } else {
      message.success('Successfully updated Lot !');
    }
    this.setState({
      modalLoading: false,
      holdManagementModalVisible: false,
    });

    this.props.refreshData &&
      this.props.refreshData(this.props.inventoryDetails.lot_code);
  };

  getTeamsUrl = (userEmail, msgToSend) => {
    return (
      'https://teams.microsoft.com/l/chat/0/0?users=' +
      userEmail +
      '&message=' +
      encodeURIComponent(msgToSend)
    );
  };

  renderTeamsHyperlink = (username, userEmail, msgToSend) => {
    return (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={this.getTeamsUrl(userEmail, msgToSend)}
      >
        {username}
      </a>
    );
  };

  getTeamsGroupUrl = (holdType, msgToSend) => {
    const { holdTypesUsers } = this.props.rootStore.holdTypesUsersStore;

    let foundHoldType = find(holdTypesUsers, function (o) {
      return o.name === holdType;
    });

    if (foundHoldType)
      return (
        'https://teams.microsoft.com/l/chat/0/0?users=' +
        foundHoldType.users.map((elt) => elt.email).join(',') +
        '&topicName=' +
        '[' +
        holdType +
        '] - Hold Users' +
        '&message=' +
        encodeURIComponent(msgToSend)
      );
    else return '#';
  };

  renderTeamsGroupHyperlink = (holdType, msgToSend) => {
    return (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={this.getTeamsGroupUrl(holdType, msgToSend)}
        style={{ color: 'inherit' }}
      >
        {holdType}
      </a>
    );
  };

  renderTimeline(audits, isPoAudit = false) {
    const { inventoryDetails } = this.props;
    return sortBy(audits, 'createdAt').map((audit, index) => {
      let color = 'blue';
      let message = '';
      let customIcon = '';

      const url = window.location.origin + '/inventory/' + inventoryDetails.id;
      let msgToSend = isPoAudit
        ? 'Hi ! Regarding PO: ' +
          inventoryDetails.po_number +
          ', in HMS (' +
          url +
          ')'
        : 'Hi ! Regarding FIN: ' +
          inventoryDetails.fin +
          ', Lot: ' +
          inventoryDetails.lot_code +
          ' in HMS (' +
          url +
          ')';

      switch (audit.action) {
        case 'JSC_HOLD':
        case 'HOLD':
          color = 'red';
          message = (
            <span>
              {this.renderTeamsHyperlink(
                audit.actioned_by_username,
                audit.actioned_by_user_email,
                msgToSend
              )}{' '}
              placed the {isPoAudit ? 'PO' : 'item'} on{' '}
              <Tag color={color}>
                {this.renderTeamsGroupHyperlink(audit.sub_type, msgToSend)}
              </Tag>
              hold
            </span>
          );
          break;
        case 'JSC_UNHOLD':
        case 'UNHOLD':
          color = 'green';
          message = (
            <span>
              {this.renderTeamsHyperlink(
                audit.actioned_by_username,
                audit.actioned_by_user_email,
                msgToSend
              )}{' '}
              removed the {isPoAudit ? 'PO' : 'item'} from{' '}
              <Tag color={color}>
                {this.renderTeamsGroupHyperlink(audit.sub_type, msgToSend)}
              </Tag>
              hold
            </span>
          );
          break;
        case 'PO_HOLD':
          color = 'red';
          message = (
            <ul>
              <li>
                <span>
                  {this.renderTeamsHyperlink(
                    audit.actioned_by_username,
                    audit.actioned_by_user_email,
                    msgToSend
                  )}{' '}
                  placed the <strong>PO</strong> on{' '}
                  <Tag color={color}>
                    {this.renderTeamsGroupHyperlink(audit.sub_type, msgToSend)}
                  </Tag>
                  hold
                </span>
              </li>
              <li>
                Holds Management System placed the lot on hold on{' '}
                {moment(audit.updatedAt).format('llll')}
              </li>
            </ul>
          );
          break;
        case 'AUTO_HOLD':
          color = 'red';
          message = (
            <span>
              <a
                href={`mailto:${audit.actioned_by_user_email}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {audit.actioned_by_username}
              </a>{' '}
              placed the <strong>PO</strong> on{' '}
              <Tag color={color}>
                {this.renderTeamsGroupHyperlink(audit.sub_type, msgToSend)}
              </Tag>
              hold
            </span>
          );
          break;
        case 'WAREHOUSE_EDI':
          if (audit.sub_type === 'SUCCESS') {
            color = 'blue';
            message = <span>EDI update sent to warehouse successfully.</span>;
          } else {
            color = 'orange';
            message = <span>EDI update to warehouse failed.</span>;
          }
          break;
        case 'EMAIL':
          if (audit.sub_type === 'SUCCESS') {
            color = 'blue';
            message = (
              <span>
                Sent notification to warehouse @{' '}
                <a href={'mailto:' + audit.message}>{audit.message}</a>
              </span>
            );
          } else {
            color = 'orange';
            message = (
              <span>
                Warehouse email address isn’t updated. Contact administrator to
                ensure warehouses are notified.
              </span>
            );
          }
          break;
        case 'NAV_UPDATE':
          if (audit.sub_type === 'SUCCESS') {
            color = 'blue';
            message = <span>NAV updated successfully.</span>;
          } else {
            color = 'orange';
            message = (
              <span>
                Update into NAV failed. Contact administrator for additional
                information.
              </span>
            );
          }
          break;
        case 'NAV_TEMP_RELEASE_REQUEST':
          message = (
            <span>
              {this.renderTeamsHyperlink(
                audit.actioned_by_username,
                audit.actioned_by_user_email,
                msgToSend
              )}{' '}
            </span>
          );
          if (audit.sub_type === 'NEW') {
            customIcon = <KeyOutlined />;
            color = 'blue';
            message = (
              <span>
                {this.renderTeamsHyperlink(
                  audit.actioned_by_username,
                  audit.actioned_by_user_email,
                  msgToSend
                )}{' '}
                requested for temporary unhold
              </span>
            );
          } else if (audit.sub_type === 'APPROVED') {
            customIcon = <CheckCircleOutlined />;
            message = (
              <span>
                {this.renderTeamsHyperlink(
                  audit.actioned_by_username,
                  audit.actioned_by_user_email,
                  msgToSend
                )}{' '}
                approved the request for temporary unhold
              </span>
            );
            color = 'green';
          } else if (audit.sub_type === 'REJECTED') {
            customIcon = <CloseCircleOutlined />;
            message = (
              <span>
                {this.renderTeamsHyperlink(
                  audit.actioned_by_username,
                  audit.actioned_by_user_email,
                  msgToSend
                )}{' '}
                rejected the request for temporary unhold
              </span>
            );
            color = 'red';
          } else if (audit.sub_type === 'EXPIRED') {
            customIcon = <FieldTimeOutlined />;
            color = 'orange';
            message = 'Request expired';
          } else if (audit.sub_type === 'PROCESSED') {
            color = 'red';
            message = (
              <span>
                {this.renderTeamsHyperlink(
                  audit.actioned_by_username,
                  audit.actioned_by_user_email,
                  msgToSend
                )}{' '}
                revoked temporary Access for the Lot
              </span>
            );
          }
          break;
        default:
          color = 'blue';
          break;
      }

      return (
        <Timeline.Item
          key={index}
          label={moment(audit.createdAt).format('llll')}
          color={color}
          dot={customIcon}
        >
          <Space direction='vertical' size={1}>
            <Typography.Text>{message}</Typography.Text>
            {this.showMessageForAudit(audit) && audit.message && (
              <Typography.Text type='secondary'>
                <CommentOutlined style={{ marginRight: 5 }} />
                {audit.message}
              </Typography.Text>
            )}
          </Space>
        </Timeline.Item>
      );
    });
  }

  showMessageForAudit = (audit) => {
    if (audit.action === 'EMAIL' || audit.action === 'NAV_UPDATE') return false;
    if (audit.action === 'NAV_TEMP_RELEASE_REQUEST' && audit.sub_type !== 'NEW')
      return false;
    return true;
  };

  updateWatchStatus = () => {
    const { inventoryDetails } = this.props;

    const payload = {
      type: StringConstants.WATCHERS.TYPE.LOT,
      action: inventoryDetails.is_watched
        ? StringConstants.WATCHERS.ACTION.STOP_WATCHING
        : StringConstants.WATCHERS.ACTION.START_WATCHING,
      identifier: inventoryDetails.id,
    };
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.WATCHER_ACTIVITY.USECASE,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          message.success(response.data.message);
          this.props.toggleWatchFlag(inventoryDetails.lot_code, false);
          this.setState({ loading: false });
        } else throw response;
      })
      .catch((err) => {
        message.error('Error occurred while performing the action');
        this.setState({ loading: false });
      });
  };

  requestTempUnhold = () => {
    this.setState({ tempReleaseModalVisible: true });
  };

  handleTempRequestModalCancel = () => {
    this.setState({ tempReleaseModalVisible: false });
  };

  submitTempUnholdRequest = (comments) => {
    const { inventoryDetails } = this.props;

    const payload = {
      comments,
      lot_code: inventoryDetails.lot_code,
      id: inventoryDetails.id,
    };
    this.setState({ modalLoading: true });
    makeApiCallWithAuthentication(
      UrlConstants.TEMP_RELEASE_REQUEST.USECASE,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          this.setState({ tempReleaseModalVisible: false });
          message.success(
            'Request for temporary unhold submitted successfully'
          );
          this.props.refreshData &&
            this.props.refreshData(this.props.inventoryDetails.lot_code);
        } else throw response;
      })
      .catch((err) => {
        message.error('Error occurred while submitting request');
      })
      .finally(() => {
        this.setState({ modalLoading: false });
      });
  };

  submitTempUnholdRevoke = () => {
    const { inventoryDetails } = this.props;

    const payload = {
      lot_code: inventoryDetails.lot_code,
      id: inventoryDetails.id,
    };
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.TEMP_RELEASE_REVOKE.USECASE,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          message.success('Request for temporary unhold revoked successfully');
          this.props.refreshData &&
            this.props.refreshData(this.props.inventoryDetails.lot_code);
        } else throw response;
      })
      .catch((err) => {
        message.error('Error occurred while submitting request');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  copyInventoryUrl = () => {
    try {
      let path = window.location.origin + RouteConstants.INVENTORY_DETAILS;
      path = path.replace(':lotno', this.props.inventoryDetails.id);
      navigator.clipboard.writeText(path);
      message.success('Link copied to clipboard');
    } catch (err) {
      message.error('Cannot copy link');
    }
  };

  render() {
    const { inventoryDetails, enableShare } = this.props;
    const {
      loading,
      modalLoading,
      holdManagementModalVisible,
      tempReleaseModalVisible,
    } = this.state;

    let color = 'black';
    if (inventoryDetails.expiration_date) {
      let remainingDays = moment(inventoryDetails.expiration_date).diff(
        moment(),
        'days'
      );
      if (remainingDays <= 0) color = 'red';
      else if (remainingDays > 0 && remainingDays < 180) {
        color = 'orange';
      }
    }

    if (isEmpty(inventoryDetails))
      return <Empty description='Could not find inventory' />;
    return (
      <PageHeader
        ghost={false}
        onBack={this.props.goBack}
        title={`FIN : ${inventoryDetails.fin}`}
        subTitle={
          inventoryDetails.status === StringConstants.TEMP_ACTIVE_STATUS &&
          'Cannot action on a Lot that is temporarily active'
        }
        tags={
          enableShare && (
            <Tooltip title='Copy Shareable Link'>
              <Button
                icon={<ShareAltOutlined />}
                type='link'
                onClick={this.copyInventoryUrl}
              />
            </Tooltip>
          )
        }
        extra={[
          !inventoryDetails.is_watched ? (
            <Button
              key='2'
              type='default'
              onClick={this.updateWatchStatus}
              loading={loading}
            >
              <EyeOutlined /> Start Watching
            </Button>
          ) : (
            <Button
              key='3'
              type='default'
              danger
              onClick={this.updateWatchStatus}
              loading={loading}
            >
              <EyeInvisibleOutlined /> Stop Watching
            </Button>
          ),
          (this.props.rootStore.userStore.isUserPartOfRole(
            RoleConstants.ACCOUNTING
          ) ||
            this.props.rootStore.userStore.isUserPartOfRole(
              RoleConstants.TEMP_UNHOLD_REQUESTOR
            )) &&
            inventoryDetails.status === StringConstants.ONHOLD_STATUS && (
              <Button
                key='temp-release-account'
                type='primary'
                onClick={this.requestTempUnhold}
                disabled={loading}
              >
                Request Temp. Unhold
              </Button>
            ),
          inventoryDetails.status === StringConstants.TEMP_ACTIVE_STATUS &&
            this.props.rootStore.userStore.isUserPartOfRole(
              RoleConstants.APPROVER
            ) && (
              <Button
                key='temp-release-revoke'
                type='primary'
                danger
                onClick={this.submitTempUnholdRevoke}
                disabled={loading}
              >
                Revoke Temp. Unhold
              </Button>
            ),
          actionAllowedStates.includes(inventoryDetails.status) && (
            <Button
              key='action'
              type='primary'
              onClick={this.handleViewHoldModalClick}
              disabled={loading}
            >
              Action on Item
            </Button>
          ),
        ]}
      >
        <Descriptions
          size='small'
          column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}
          className='space-top-10'
        >
          <Descriptions.Item label='Location'>
            <Typography.Text strong>
              {inventoryDetails.location}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label='Lot Number'>
            <Typography.Text strong copyable>
              {inventoryDetails.lot_code}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label='FIN'>
            <Typography.Text strong>{inventoryDetails.fin}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label='Quantity'>
            <Typography.Text strong>
              <NumberFormat
                value={inventoryDetails.quantity}
                displayType={'text'}
                thousandSeparator={true}
              />
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label='Status'>
            {inventoryDetails.status === StringConstants.UNAVAILABLE_STATUS ? (
              <Tooltip title='This Lot is no longer available in Business Central.'>
                <Tag color={getInventoryStatusColor(inventoryDetails.status)}>
                  {upperCase(inventoryDetails.status)}
                </Tag>
              </Tooltip>
            ) : (
              <Tag color={getInventoryStatusColor(inventoryDetails.status)}>
                {upperCase(inventoryDetails.status)}
              </Tag>
            )}
          </Descriptions.Item>
          <Descriptions.Item label='PO Number' className='space-top-10'>
            <Typography.Text strong>
              {isEmpty(inventoryDetails.purchase_order) ? (
                inventoryDetails.po_number
              ) : (
                <Link
                  to={RouteConstants.PO_DETAILS.replace(
                    ':id',
                    inventoryDetails.purchase_order.id
                  )}
                >
                  {inventoryDetails.po_number}
                </Link>
              )}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label='Expiry Date' className='space-top-10'>
            <Typography.Text strong style={{ color }}>
              {inventoryDetails.expiration_date
                ? moment(inventoryDetails.expiration_date).format('ll')
                : 'No expiry set'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item
            label='Description'
            className='space-top-10'
            span={inventoryDetails.store_on_peco_pallet ? 1 : 2}
          >
            <Typography.Text strong>
              {inventoryDetails.description}
            </Typography.Text>
          </Descriptions.Item>
          {inventoryDetails.store_on_peco_pallet && (
            <Descriptions.Item label='PECO Pallet' className='space-top-10'>
              <Typography.Text strong>Store on PECO Pallet</Typography.Text>
            </Descriptions.Item>
          )}
          <Descriptions.Item label='Contract Number' className='space-top-10'>
            <Typography.Text strong>
              {isEmpty(inventoryDetails.purchase_order)
                ? ''
                : inventoryDetails.purchase_order.po_reference}
            </Typography.Text>
          </Descriptions.Item>
          {inventoryDetails.status === StringConstants.ONHOLD_STATUS && (
            <Descriptions.Item
              label='Active Holds'
              span={3}
              className='space-top-10'
            >
              {sortBy(inventoryDetails.inventory_holds, ['hold_type.name']).map(
                (hold, index) => {
                  return (
                    hold.is_active && (
                      <Tag
                        key={index}
                        color={intToRGB(hashCode(hold.hold_type.name))}
                      >
                        {hold.hold_type.name}
                      </Tag>
                    )
                  );
                }
              )}
            </Descriptions.Item>
          )}
        </Descriptions>

        <Tabs defaultActiveKey='1' className='space-top'>
          <Tabs.TabPane tab='Activity on Lot' key='1'>
            {isEmpty(inventoryDetails.audits) ? (
              <Empty description='No Audit history' />
            ) : (
              <Row gutter={16} className='space-top-10'>
                <Col xs={24} sm={12}>
                  <Timeline
                    mode='left'
                    className='space-top'
                    pending={true}
                    pendingDot={<span />}
                    reverse={true}
                  >
                    {this.renderTimeline(inventoryDetails.audits)}
                  </Timeline>
                </Col>
              </Row>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab='Activity on PO' key='2'>
            {isEmpty(inventoryDetails.purchase_order?.po_audits) ? (
              <Empty description='No Audit history' />
            ) : (
              <Row gutter={16} className='space-top-10'>
                <Col xs={24} sm={12}>
                  <Timeline
                    mode='left'
                    className='space-top'
                    pending={
                      inventoryDetails.status ===
                      StringConstants.UNAVAILABLE_STATUS
                        ? false
                        : true
                    }
                    pendingDot={<span />}
                    reverse={true}
                  >
                    {this.renderTimeline(
                      inventoryDetails.purchase_order.po_audits,
                      true
                    )}
                  </Timeline>
                </Col>
              </Row>
            )}
          </Tabs.TabPane>
        </Tabs>

        <HoldUnholdModal
          inventory={inventoryDetails}
          holdTypes={this.props.holdTypes}
          visible={holdManagementModalVisible}
          loading={modalLoading}
          onCancel={this.handlePutOnHoldCancel}
          onSubmit={this.handlePutOnHoldSubmit}
          onWatchUpdate={this.handleUpdateWatchStatus}
          permissions={this.props.rootStore.userStore.permissions}
        />
        <TempReleaseRequestModal
          inventory={inventoryDetails}
          visible={tempReleaseModalVisible}
          loading={modalLoading}
          onCancel={this.handleTempRequestModalCancel}
          onSubmit={this.submitTempUnholdRequest}
        />
      </PageHeader>
    );
  }
}

export default InventoryDetailsView;
