export default {
  HOME: '/',
  LOGIN: '/',
  REDIRECT: '/redirect',
  ON_HOLD_INVENTORY: '/holds',
  ACTIVE_INVENTORY: '/inventory',
  SEARCH_INVENTORY: '/search',
  INVENTORY_DETAILS: '/inventory/:lotno',
  PO_DETAILS: '/purchase-order/:id',
  ADMIN_AUTO_HOLDS: '/admin-auto-holds',
  ADMIN_AUTO_HOLDS_FOR_ALL: '/admin-auto-holds-for-all',
  ADMIN_HOLD_TYPES: '/admin-holdtypes',
  ADMIN_LOCATIONS: '/admin-locations',
  ADMIN_ROLES: '/admin-roles',
  ADMIN_USERS: '/admin-users',
  ACTIVE_PO: '/purchase-orders/active',
  ONHOLD_PO: '/purchase-orders/holds',
  DASHBOARD: '/dashboard',
  INBOUND_CONTAINERS: '/inbound-containers',
  EXPIRING_LOTS: '/expiration-report',
  HOLD_MISMATCH_LOTS: '/holds-mismatch',
  QUANTITY_MISMATCH_LOTS: '/quantity-mismatch',
  USERS_PER_HOLD: '/teams',
  WATCHLIST_INVENTORY: '/watchlist/inventory',
  WATCHLIST_PO: '/watchlist/po',
  FIN_LIST: '/fins',
  TEMP_RELEASE_REQUEST: '/temp-release-request/:key',
  MAINTENANCE_PAGE: '/maintenance',
  ACTIVE_TO: '/transfer-orders/active',
  REDIRECT_PAGE: '/redirect',
};
