import { Col, Descriptions, Row, Table, Tag, Tooltip, Typography } from 'antd';
import React, { Component } from 'react';
import { hashCode, intToRGB } from '../utils/colorUtils';
import { find, sortBy, uniqBy } from 'lodash';

import { Helmet } from 'react-helmet';
import HttpConstants from '../constants/HttpConstants';
import SecureComponent from '../app/components/SecureComponent';
import UrlConstants from '../api/UrlConstants';
import { makeApiCallWithAuthentication } from '../api/ApiManager';
import { withRouter } from 'react-router';

class UsersPerHoldType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    makeApiCallWithAuthentication(
      UrlConstants.USERS_BY_HOLD_TYPE.USECASE,
      HttpConstants.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            loading: false,
            data: response.data,
          });
        } else throw response;
      })
      .catch((err) => {
        this.setState({ data: [], loading: false });
      });
  }

  getColumnsForTable = () => {
    return [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Hold Type',
        render: (name) => (
          <Tag color={intToRGB(hashCode(name))}>
            {this.renderTeamsGroupHyperlink(name)}
          </Tag>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
      },
      {
        key: 'users',
        dataIndex: 'users',
        title: 'Users',
        render: (users) =>
          sortBy(uniqBy(users, 'email'), 'name').map((user, index) => {
            if (
              ![
                'ramprasath@fishin-india.com',
                'ashok.vr@fishin-india.com',
                'holds@fishinco.com',
              ].includes(user.email)
            )
              return (
                <span key={index}>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={
                      'https://teams.microsoft.com/l/chat/0/0?users=' +
                      user.email
                    }
                  >
                    {user.name}
                  </a>
                  {index !== users.length - 1 && ', '}
                </span>
              );
            return null;
          }),
      },
    ];
  };

  getColumnsForTableSmallScreens = () => {
    const columns = [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Hold Type',
        render: (name) => (
          <Tooltip placement='topLeft' title={name}>
            <Tag color={intToRGB(hashCode(name))}>
              {this.renderTeamsGroupHyperlink(name)}
            </Tag>
          </Tooltip>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        ellipsis: {
          showTitle: false,
        },
      },
    ];
    return columns;
  };

  renderRowForSmallScreens = (record) => {
    return (
      <Descriptions bordered>
        <Descriptions.Item>{record.description}</Descriptions.Item>
        <Descriptions.Item>
          {sortBy(uniqBy(record.users, 'email'), 'name').map((user, index) => {
            if (
              ![
                'ramprasath@fishin-india.com',
                'ashok.vr@fishin-india.com',
                'holds@fishinco.com',
              ].includes(user.email)
            )
              return (
                <span key={index}>
                  {user.name}

                  {index !== record.users.length - 1 && ', '}
                </span>
              );
            return null;
          })}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  getTeamsGroupUrl = (holdType) => {
    const { holdTypesUsers } = this.props.rootStore.holdTypesUsersStore;

    let foundHoldType = find(holdTypesUsers, function (o) {
      return o.name === holdType;
    });

    if (foundHoldType)
      return (
        'https://teams.microsoft.com/l/chat/0/0?users=' +
        foundHoldType.users.map((elt) => elt.email).join(',') +
        '&topicName=' +
        '[' +
        holdType +
        '] - Hold Users'
      );
    else return '#';
  };

  renderTeamsGroupHyperlink = (holdType) => {
    return (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={this.getTeamsGroupUrl(holdType)}
        style={{ color: 'inherit' }}
      >
        {holdType}
      </a>
    );
  };

  render() {
    const { loading, data } = this.state;
    const columns = this.getColumnsForTable();
    return (
      <div>
        <Helmet>
          <title>HMS - Users per hold type</title>
          <meta
            name='description'
            content='Users with access to each hold type'
          />
        </Helmet>
        <Row className='space-top-10'>
          <Col xs={24} className='text-center'>
            <Typography.Text strong style={{ fontSize: 18 }}>
              Users with access to each Hold type
            </Typography.Text>
          </Col>
          <Col xs={0} md={24} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              columns={columns}
              dataSource={data}
              loading={loading}
              locale={{
                emptyText: 'No Users Found',
              }}
              rowKey={(record) => record.name}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Col>
          <Col xs={24} md={0} className='space-top'>
            <Table
              scroll={{ x: 'fit-content' }}
              dataSource={data}
              loading={loading}
              columns={this.getColumnsForTableSmallScreens()}
              locale={{
                emptyText: 'No Users Available',
              }}
              rowKey={(record) => record.name}
              expandable={{
                expandedRowRender: this.renderRowForSmallScreens,
              }}
              pagination={{
                defaultPageSize: 50,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SecureComponent(withRouter(UsersPerHoldType));
